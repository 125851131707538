(function () {
	angular.module('Plania').controller('HousingController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ngTableParams', '$modal', 'ListService', '$state', controller]);

    function controller($scope, repository, $stateParams, translationService, ngTableParams, $modal, listService, $state) {
		$scope.isUpdate = $scope.navigation.current.name === 'housing.edit';
        $scope.model = $stateParams.model || { Guid: $stateParams.guid };

        $scope.allWorkOrdersSelected = false;
        $scope.selectedWorkOrders = [];

		$scope.activateAutocomplete = false;
		$scope.viewMode = $scope.isUpdate ? true : false;
        $scope.reloadControlList = false;
        $scope.reloadDeviation = false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Area, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Area, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Area);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Area);
			}
		};

		$scope.areaXEntityColumns = [
			{ Position: 1, Title: translationService.translate('web-areaXEntity-id', 'Id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-areaXEntity-description', 'Beskrivelse'), Property: 'Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-areaXEntity-quantity', 'Antall'), Property: 'Quantity', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-areaXEntity-rentalPricePerMonth', 'Leie pr. måned'), Property: 'RentalPricePerMonth', PropertyType: 'double' },
		];

		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};

		//workOrderFilters
		var workOrderFilters = [
			{ value: 'active', name: translationService.translate('web-workOrder-filterType-active', 'Aktive') },
			{ value: 'history', name: translationService.translate('web-workOrder-filterType-history', 'Historikk') },
		];

		$scope.selectedWorkOrderFilter = _.find(workOrderFilters, { value: 'active' }).name;

		$scope.setWorkOrderFilter = function (filterType) {
			$scope.selectedWorkOrderFilter = _.find(workOrderFilters, { value: filterType }).name;

			var existingStageFilterIndex = _.findIndex($scope.workOrderTable.filter().PropertyFilter, function (o) { return o.Property === 'Stage'; });

			if (existingStageFilterIndex > -1) {
				$scope.workOrderTable.filter().PropertyFilter.splice(existingStageFilterIndex, 1);
			}

			var propertyFilter = $scope.workOrderTable.filter().PropertyFilter;
			var filter = {};
			switch (filterType) {
				case 'active':
					filter = { Property: 'Stage', Operator: '=', Value: '1' };
					if (propertyFilter)
						propertyFilter.push(filter);
					else
						$scope.workOrderTable.filter().PropertyFilter = [filter];
					break;
				case 'history':
					filter = { Property: 'Stage', Operator: '=', Value: '2' };
					if (propertyFilter)
						propertyFilter.push(filter);
					else
						$scope.workOrderTable.filter().PropertyFilter = [filter];
					break;
			}
		};

		$scope.createWorkOrder = function () {
			repository.persistedData.setPersistedData('workOrder.create', {
				guidEstate: $scope.model.Estate ? $scope.model.Estate.Guid : null,
				estate: $scope.model.Estate ? $scope.model.Estate.Caption : null,
				guidBuilding: $scope.model.GuidBuilding,
				building: $scope.model.Building ? $scope.model.Building.Caption : null,
				guidArea: $scope.model.Guid,
				area: $scope.model.Id + ' - ' + $scope.model.Description,
				fromArea: true
			});
			$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
		};

		var getCardTables = function () {
			$scope.areaXEntityTable = new ngTableParams(
				{
					page: 1,
					count: 10,
					sorting: { 'Id': 'asc' },
					filter: { PropertyFilter: [{ Property: "GuidArea", Operator: "=", value: $stateParams.guid }] }
				},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						var columns = [];
						$scope.areaXEntityColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						repository.GetPaginated(repository.apiData.areaXEntity.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
							.then(function (result) {
								$scope.areaXEntityTable.settings().total = result.TotalCount;
								$scope.areaXEntityTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});

			listService.getWebListViews(null, repository.apiData.workOrder.prefix, false, null, {}).then(function (result) {
				$scope.workOrderListOptions = result.listOptions;
				$scope.workOrderListSetup = result.selectedListSetup;

				if (!$scope.workOrderTable) {
					$scope.workOrderTable = new ngTableParams(
						{ page: 1, count: 10, sorting: { 'Id': 'desc' }, filter: { ActivityStatus: 'Undefined', PropertyFilter: [{ Property: 'GuidArea', Operator: '=', Value: $stateParams.guid }, { Property: 'Stage', Operator: '=', Value: "1" }] } },
						{
							total: 0,
							filterDelay: 10,
							counts: [10, 20, 50],
							getData: function ($defer, params) {
								var columns = [];
								$scope.workOrderListSetup.Columns.forEach(function (col) {
									columns.push(col.Property);
								});

								columns.push('CompletionRate');

								repository.GetPaginated(repository.apiData.workOrder.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
									function (result) {
										$scope.workOrderTable.settings().total = result.TotalCount;
										$scope.workOrderTable.settings().filterDelay = 500;



										$defer.resolve(result.List);
									},
									function (error) {
										repository.growl(error, 'danger');
									});
							}
						});
				}
			});


		};

		var setProperties = function () {


			$scope.showDynamicProperties = $scope.model.DynamicProperty && $scope.model.DynamicProperty.Groups.length > 0;

			if ($scope.model.Building) {
				$scope.building = $scope.getEntityCaption($scope.model.Building);
			}

			if ($scope.model.AreaType) {
				$scope.areaType = $scope.getEntityCaption($scope.model.AreaType);
			}
		};

		repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix.ContractItem).then(function (result) {
			$scope.contractItemFieldRules = result;
			$scope.reloadContractItem = true;
		});

		var columns = [
			'Building.Guid',
			'Building.PostalCode',
			'Building.Id',
			'Building.Description',
			'Building.PostalArea',
			'Building.Address',
            'Building.County',
            'Building.Estate.Id',
            'Building.Estate.Description',
            'Building.Estate.Guid',
			'CostCenter.Id', 'CostCenter.Description',
			'ContractItem.EffectiveStartDate',
			'ContractItem.UnitPrice',
			'ContractItem.EffectiveEndDate',
			'ContractItem.Contract.EndDate',
			'ContractItem.Contract.ScheduledEndDate',
			'ContractItem.Contract.Customer.Guid',
			'ContractItem.Contract.Customer.Description',
			'ContractItem.Contract.Customer.Telephone',
			'AreaCategory.Description',
			'AreaType.Id',
			'AreaType.Description'];

		if ($scope.isUpdate) {
			repository.getMainModel(repository.apiData.area.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;
					if (!$scope.model.IsHousing) {
						// move to correct edit page, and use location "replace" to avoid having this page in browser history
						$state.go('area.edit', { guid: $stateParams.guid, model: $scope.model }, { location: 'replace' });
						return;
					}

					$scope.fieldRules = response.FieldRules;
					updateAccess();
					setProperties();

					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
					$scope.reload = true;
                    $scope.reloadControlList = true;
                    $scope.reloadDeviation = true;
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);

			getCardTables();
		} else {
			var getCategoryPrefill = function (guid) {
				var guidCategory = guid || $scope.model.GuidAreaCategory;
				if (!guidCategory) return;
				repository.getSingle(repository.apiData.areaCategory.url, guidCategory).then(function (result) {
					$scope.model.DynamicProperty = result.Data.DynamicProperty;
					$scope.showDynamicProperties = $scope.model.DynamicProperty && $scope.model.DynamicProperty.Groups.length > 0;
				}, function (error) {
					repository.growl(error, 'danger');
				});
			};

			var building = repository.commonService.getFilterData().selectedBuilding;
			if (building.Guid) {
				$scope.modelDto.building = building.Name;
				$scope.model.GuidBuilding = building.Guid;
			}

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'AreaCategory':
								$scope.model.GuidAreaCategory = prefill.Value.Guid;
								$scope.model.AreaCategory = { Guid: prefill.Value.Guid, Description: prefill.Value.Label, Caption: prefill.Value.Label };
								break;
						}
					} else if (prefill.Value === '@CurrentBuilding') {
						if (building.Guid) {
							$scope.modelDto.building = building.Name;
							$scope.model.GuidBuilding = building.Guid;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}

			$scope.model.RentalStatus = 'Vacant';
			$scope.model.IsHousing = true;

			if ($stateParams.guidEntityToCopy) {
				repository.getSingle(repository.apiData.area.url, $stateParams.guidEntityToCopy, JSON.stringify(columns)).then(function (result) {
					delete result.Data.Guid;
					delete result.Data.Id;

					$scope.model = result.Data;
					$scope.fieldRules = result.FieldRules;
					$scope.reload = true;

					setProperties();
				});
			} else {
				getCategoryPrefill();

				repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix.Area).then(function (result) {
					$scope.fieldRules = result;
					$scope.reload = true;
					$scope.activateAutocomplete = true;
				});
			}
		}


		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				$scope.model.Address = "";
				return;
			}

			$scope.model.GuidBuilding = building.Guid;

			if (building.Address) {
				$scope.model.Address = building.Address;
			}
		};

		$scope.onAreaCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidAreaCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidAreaCategory = category.Guid;
		};

		$scope.update = function (destination) {
			if (!$scope.model.RentalArea)
				$scope.model.RentalArea = "0";

			$scope.model.RentalArea = $scope.model.RentalArea.replace(',', '.');

			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-housing-success', 'Bolig har blitt oppdatert.') : translationService.translate('create-housing-success', 'Bolig har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.area.url, $scope.model).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.area.url, $scope.model).then(success, error);
			}

		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-housing-message', "Boligen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-housing-button-confirm', 'Ja, fjern Bolig'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.area.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-housing-success', 'Boligen ble fjernet!'), result, "success");
						$scope.goBack('housing.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		//AreaXEntity
		$scope.addAreaXEntity = function () {
			$modal.open({
				templateUrl: 'app/housing/views/areaXEntityModal.html',
				controller: 'AreaXEntityModalController',
				resolve: {
					params: function () {
						return {
							guidArea: $scope.model.Guid
						};
					}
				}
			}).result.then(function () {
				$scope.areaXEntityTable.reload();
			}, function () {
				//cancel
			});
		};

		$scope.editAreaXEntity = function (areaXEntity) {
			$modal.open({
				templateUrl: 'app/housing/views/areaXEntityModal.html',
				controller: 'AreaXEntityModalController',
				resolve: {
					params: function () {
						return {
							guidArea: $scope.model.Guid,
							guidAreaXEntity: areaXEntity.Guid
						};
					}
				}
			}).result.then(function () {
				$scope.areaXEntityTable.reload();
			}, function () {
				//cancel
			});
		};

		$scope.removeAreaXEntity = function (areaXEntity) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-areaXEntity-message', "Objektet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-areaXEntity-button-confirm', 'Ja, fjern objektet!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.areaXEntity.url, areaXEntity.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-areaXEntity-success', 'Objektet ble fjernet!'), result, "success");
						$scope.areaXEntityTable.reload();
					});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
        });

        $scope.selectAllRows = function (table, selectedItems, allRowsSelected) {
            if ($scope[allRowsSelected]) {
                table.data.forEach(function (row) {
                    var index = _.findIndex(selectedItems, function (o) { return o === row.Guid; });
                    selectedItems.splice(index, 1);
                });

                $scope[allRowsSelected] = false;
            } else {
                table.data.forEach(function (row) {
                    if (!_.contains(selectedItems, row.Guid)) {
                        selectedItems.push(row.Guid);
                    }
                });

                $scope[allRowsSelected] = true;
            }
        };

        $scope.selectItem = function (guid, selectedItems) {
            if (!$scope.itemIsSelected(guid, selectedItems)) {
                selectedItems.push(guid);
            } else {
                for (var i = 0; i < selectedItems.length; i++) {
                    if (selectedItems[i] === guid) {
                        selectedItems.splice(i, 1);
                        return;
                    }
                }
            }
        };

        $scope.itemIsSelected = function (guid, selectedItems) {
            return _.find(selectedItems, function (o) { return o === guid; });
        };

	}
})();
