(function () {
    angular.module('Plania').controller('TechnicalRequestController', ['$scope', 'Repository', 'EnumService', 'RegisteredFieldService', 'Constants', controller]);

    function controller($scope, repository, enumService, registeredFieldService, constants) {
		$scope.request = {};
        $scope.autoCompleteModel = {};
        $scope.selectedRequest = {};

		$scope.mandatoryFieldRules = [];

		var registeredFieldsPromise = registeredFieldService.getRegisteredField(repository.commonService.prefix.Request);
		registeredFieldsPromise.then(function (result) {
			$scope.mandatoryFieldRules = _.filter(result, { Mandatory: true });
		});

		$scope.isMandatoryOrPrefill = function(domainFieldName){
			if (!domainFieldName) return false;


			if ($scope.request[domainFieldName]) return true;

			return _.some($scope.mandatoryFieldRules, function (rule){
				return rule.DomainFieldName === domainFieldName;
			});
		};

		var setUserDataOnRequest = function (request) {
			registeredFieldsPromise.then(function (){
				var userData = repository.authService.getUserData();
				request.EmailAddress = userData.email;
				request.Telephone = userData.phone;
				request.ContactPerson = userData.guidPerson ? userData.personFirstName + ' ' + userData.personLastName : userData.realName;

				if (userData.guidDepartment) {
					request.GuidDepartment = userData.guidDepartment;
					request.Department = {
						Guid: userData.guidDepartment,
						Name: userData.userDepartment,
						Caption: userData.userDepartment
					};
					$scope.department = request.Department.Caption;
				}
			});
        };
        setUserDataOnRequest($scope.request);

		var requestColumns = ['Description', 'ExplanatoryText', 'DueDate', 'Priority.Id','Priority.Description', 'ActivityCategory.Id', 'ActivityCategory.Description', 'ResourceGroup.Id','ResourceGroup.Description', 'Person1.FirstName', 'Person1.LastName'];
		repository.GetPaginated(repository.apiData.request.url, 0, 50, { 'Description': 'asc' }, { PropertyFilter: [{ Property: 'IsTemplate', Operator: '=', Value: 'true' }] }, null, JSON.stringify(requestColumns)).then(function (result) {
			$scope.requests = result.List;
		});

		$scope.activateAutocomplete = true;

		$scope.onPrioritySelect = function (priority) {
			if (priority) {
                $scope.request.GuidPriority = priority.Guid;
				if (priority.HasDeadline) {
					$scope.request.DueDate = enumService.getTimeUnitDate(new Date(), priority.DeadlinePeriodUnit, priority.DeadlinePeriodNumber);
					$scope.restrictDueDate = true;
				} else {
					$scope.restrictDueDate = false;
				}
			}
		};

		$scope.calendars = {
			dueDate: false
        };

		$scope.open = function ($event, calendar) {
			$scope.calendars[calendar] = !$scope.calendars[calendar];
			$event.preventDefault();
			$event.stopPropagation();
		};

        $scope.selectRequest = function(request) {
            $scope.request = angular.copy(request);
            $scope.restrictDueDate = false;

			$scope.autoCompleteModel.responsible = request.Person1 ? request.Person1.Caption : null;

			$scope.autoCompleteModel.activityCategory = request.ActivityCategory ? request.ActivityCategory.Caption : null;

			$scope.autoCompleteModel.priority = request.Priority ? request.Priority.Caption : null;

			$scope.autoCompleteModel.resourceGroup = request.ResourceGroup ? request.ResourceGroup.Caption : null;

            $scope.selectedRequest = request;
            setUserDataOnRequest($scope.request);
        };

		$scope.sendRequest = function () {
			$scope.request.RegisteredDate = new Date();
			$scope.request.Status = constants.requestStatus.new;

			$scope.request.GuidBuilding = $scope.model.GuidBuilding;
			$scope.request.GuidArea = $scope.model.Guid;

			delete $scope.request.Guid;
			delete $scope.request.GuidDataOwner;
			delete $scope.request.GuidUserCreatedBy;
			delete $scope.request.GuidUserUpdatedBy;

			$scope.request.RegisteredDate = new Date($scope.request.RegisteredDate).toISOString();
			if ($scope.request.StartDate)
				$scope.request.StartDate = new Date($scope.request.StartDate).toISOString();
			if ($scope.request.DueDate)
				$scope.request.DueDate = new Date($scope.request.DueDate).toISOString();

			repository.createSingleDictionary(repository.apiData.request.url, $scope.request).then(function (result) {
				repository.growl('Meldingen ble sendt', 'success');
                $scope.request = {};
                $scope.selectedRequest = {};
				$scope.autoCompleteModel.responsible = '';
				$scope.autoCompleteModel.activityCategory = '';
				$scope.autoCompleteModel.priority = '';

				$scope.ok(result);
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};
	}
})();
