(function () {
	angular.module('Plania').service('authService', ['$http', '$rootScope', 'config', 'CommonService', 'ModuleService', '$localStorage', 'SignalR', '$q', '$window', service]);

	function service($http, $rootScope, config, commonService, moduleService, $localStorage, signalR, $q, $window) {
		var repository = {};
		var userData = {};

		function resetUserData() {
			userData.isAuthenticated = false;
			userData.username = '';
			userData.accessToDesktop = false;
			userData.canSeeWebMainMenu = false;
			userData.isSystemAdministrator = false;
			userData.canRunSystemFunction = false;
			userData.canOverridePermissions = false;
			userData.isExternalUser = false;
			userData.GuidWebMenu = '';
			userData.guidImage = '';
			userData.MenuContext = '';
			userData.MenuParameters = '';
			userData.phone = '';
			userData.cellPhone = '';
			userData.email = '';
			userData.realName = '';
			userData.guidUser = '';
			userData.guidPerson = '';
			userData.guidCustomer = '';
			userData.personEmployeeNr = '';
			userData.personFirstName = '';
			userData.personLastName = '';
			userData.guidPersonResourceGroup = '';
			userData.defaultSelection = '';
			userData.defaultSelectionDescription = '';
			userData.defaultDataOwner = '';
			userData.userResourceGroup = '';
			userData.guidResourceGroup = '';
			userData.userAccount = '';
			userData.guidAccount = '';
			userData.userDepartment = '';
			userData.guidDepartment = '';
			userData.userSupplier = '';
			userData.guidSupplier = '';
			userData.guidPersonDepartment = '';
			userData.language = '';
			userData.useTwoFactor = '';

			if ($rootScope.events) {
				$rootScope.$broadcast($rootScope.events.userInfoChangedEvent);
			}
		}

		resetUserData();

		var authenticationSuccess = function (data) {
			delete $localStorage.userData;
			delete $localStorage.planiaToken;

			$localStorage.planiaToken = {
				access: 'Bearer ' + data.access_token,
				refresh: data.refresh_token,
				expiration: new Date(data['.expires'])
			};
			userData.isAuthenticated = true;
			userData.username = data.userName;

			userData.accessToDesktop = data.AccessToDesktop === "1" ? true : false;
			userData.isSystemAdministrator = data.SystemAdministrator === "1" ? true : false;
			userData.isUserAdministrator = data.UserAdministrator === "1" ? true : false;
			userData.canRunSystemFunction = data.CanRunSystemFunction === "1" ? true : false;
			userData.canChangeRequestStatus = data.CanChangeRequestStatus === "1" ? true : false;
			userData.canChangeDocumentArchiveStatus = data.CanChangeDocumentArchiveStatus === "1" ? true : false;
			userData.canChangeDataAcquisitionStatus = data.CanChangeDataAcquisitionStatus === "1" ? true : false;
			userData.canSeeWebMainMenu = data.CanSeeWebMainMenu === "1" ? true : false;
			userData.canOverridePermissions = data.CanOverridePermissions === "1" ? true : false;
			userData.isExternalUser = data.IsExternalUser === "1" ? true : false;
			userData.useTwoFactor = data.UseTwoFactor === "1" ? true : false;
			
			userData.GuidWebMenu = data.GuidWebMenu;
			userData.userSupplier = data.userSupplier;
			userData.guidSupplier = data.guidSupplier;
			userData.guidPersonDepartment = data.guidPersonDepartment;
			userData.guidImage = data.guidImage;
			userData.MenuContext = data.MenuContext;
			userData.MenuParameters = data.MenuParameters;
			userData.email = data.email;
			userData.phone = data.phone;
			userData.cellPhone = data.cellPhone;
			userData.realName = data.realName;
			userData.guidUser = data.UserID;
			userData.guidPerson = data.guidPerson;
			userData.guidCustomer = data.guidCustomer;
			userData.personEmployeeNr = data.personEmployeeNr;
			userData.personFirstName = data.personFirstName;
			userData.personLastName = data.personLastName;
			userData.guidPersonResourceGroup = data.guidPersonResourceGroup;
			userData.defaultSelection = data.selectedSelectionGuid;
			userData.defaultSelectionDescription = data.selectedSelectionDescription;
			userData.defaultDataOwner = data.selectedDataOwnerGuid;
			userData.userResourceGroup = data.userResourceGroup;
			userData.guidResourceGroup = data.guidResourceGroup;
			userData.userAccount = data.userAccount;
			userData.guidAccount = data.guidAccount;
			userData.userDepartment = data.userDepartment;
			userData.guidDepartment = data.guidDepartment;
			userData.language = data.language;

			if (data.additionalProperties)
				userData.additionalProperties = JSON.parse(data.additionalProperties);

			$localStorage.userData = userData;

			if ($rootScope.events) {
				$rootScope.$broadcast($rootScope.events.userInfoChangedEvent);
			}

			// Update fingerprint, and start signalR just in case it has been disconnected.
			signalR.setQs({
				fingerprint: config.fingerprint,
				guidUser: userData.guidUser
			});

			signalR.start();
		};

		function stopSignalR() {
			signalR.setQs({});
			signalR.stop();
		}

		repository.getPlaniaToken = function () {
			var token = angular.fromJson($localStorage.planiaToken);
			if (token) {
				token.expiration = new Date(token.expiration);
				return token;
			}
			return { access: 'None', refresh: 'none', expiration: new Date() };
		};

		repository.getUserData = function () {
			if (!userData.isAuthenticated) {
				var storageUserData = angular.fromJson($localStorage.userData);

				if (storageUserData) {
					userData = storageUserData;
					repository.refreshAuthentication();
				}
			}
			return userData;
		};

		repository.deleteLocalData = function () {
			delete $localStorage.userData;
			delete $localStorage.planiaToken;
			delete $localStorage.userAccess;
			delete $localStorage.filterData;
			delete $localStorage.webMenus;
			commonService.clearFilterData();
			delete $http.defaults.headers.common.Authorization;
			resetUserData();
		};

		repository.removeAuthentication = function () {
			var deferred = $q.defer();
			var postData =
			{
				method: 'POST',
				url: config.baseUrl + 'Logout?clientId=' + config.clientId
			};
			stopSignalR();

			repository.refreshAuthentication().then(function () {
				$http(postData).success(function (data) {
					repository.deleteLocalData();
					deferred.resolve();
				}).error(function (error) {
					repository.deleteLocalData();
					deferred.resolve();
				});
			});


			return deferred.promise;
		};

		var refreshingToken = false;
		var refreshTries = 0;

		var refreshAuthentication = function (token, callback) {
			var deferred = $q.defer();
			var postData = {
				method: 'POST',
				url: encodeURI(config.baseUrl + 'Token'),
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
				},
				data: 'grant_type=refresh_token&refresh_token=' + token.refresh +
					'&client_id=' + config.clientId + '&client_secret=' + config.clientSecret + '&fingerprint=' + config.fingerprint
			};

			$http(postData).success(function (data) {
				authenticationSuccess(data);
				if (callback && typeof callback === "function") {
					callback(data);
				}
				refreshingToken = false;
				refreshTries = 0;
				deferred.resolve(data);
			}).catch(function (data) {

				if (refreshTries <= 3 && data.data && data.data.error !== "invalid_grant") {
					refreshTries++;
					refreshingToken = false;
					repository.refreshAuthentication();
					return;
				}

				data.userData = userData;
				data.accessToken = token;

				delete $localStorage.userData;
				delete $localStorage.planiaToken;
				delete $localStorage.userAccess;
				delete $localStorage.filterData;
				delete $http.defaults.headers.common.Authorization;
				resetUserData();
				stopSignalR();
				deferred.reject('Invalid');
				window.location.reload();
			});
			return deferred.promise;
		};
		var refreshingDefer;

		repository.forceRefreshAuthentication = function () {
			var token = repository.getPlaniaToken();
			refreshAuthentication(token);
		};

		repository.refreshAuthentication = function () {
			var deferred = $q.defer();
			if (!refreshingToken) {
				refreshingDefer = deferred;
				var token = repository.getPlaniaToken();
				if (token.expiration - new Date() < (1000 * 61) && token.access !== "None") {
					refreshingToken = true;
					refreshAuthentication(token).then(function (result) {
						deferred.resolve('retry');
					}).catch(function (error) {
						deferred.resolve('invalid');
					});
				} else {
					deferred.resolve('not expired');
				}
			} else {
				return refreshingDefer.promise;
			}
			return deferred.promise;
		};

		var setModules = function (dataOwner) {
			var getData = {
				method: 'GET',
				url: config.baseUrlApi + 'PlaniaSystem?GetModules=true',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
				}
			};

			$http(getData).success(function (result) {
				moduleService.resetModules();
				result = result.replace(/"/g, '');
				moduleService.setModules(result);
			});
		};

		repository.authenticateSSO = function () {
			var defered = $q.defer();
			$http.post(encodeURI(config.baseUrl + 'windowslogin?clientId=' + config.clientId), null, { withCredentials: true }).then(function (result) {
				var refreshToken = result.data.refresh_token;
				if (refreshToken) {
					refreshAuthentication({ refresh: refreshToken }, authenticationSuccess).then(function (data) {
						repository.deleteLocalData();
						authenticationSuccess(data);
						commonService.setDataOwner({ Guid: data.selectedDataOwnerGuid, Description: "Klient" });
						commonService.setSelectedSelection({ Guid: data.selectedSelectionGuid, Description: userData.defaultSelectionDescription });
						setModules(data.selectedDataOwnerGuid);
						defered.resolve({ success: true });
						defered.resolve({ success: true });
					});
				} else {
					defered.resolve({ success: false });
				}
			}).catch(function (error) {
				defered.reject('Could not authenticate using SSO');
			});

			return defered.promise;
		};

		repository.authenticateFederationRefresh = function (refreshToken) {
			var defered = $q.defer();
			refreshAuthentication({ refresh: refreshToken })
				.then(function (data) {
					repository.deleteLocalData();
					authenticationSuccess(data);
					commonService.setDataOwner({ Guid: data.selectedDataOwnerGuid, Description: "Klient" });
					commonService.setSelectedSelection({ Guid: data.selectedSelectionGuid, Description: userData.defaultSelectionDescription });
					setModules(data.selectedDataOwnerGuid);
					defered.resolve({ success: true });
				}).catch(function (error) {
					defered.reject(error);
				});
			return defered.promise;
		};

		repository.authenticateFederation = function () {
			var defered = $q.defer();
			$http.post(encodeURI(config.baseUrl + 'federationLogin?clientId=' + config.clientId), null, { withCredentials: true })
				.then(function (result) {
					if (result.data.Redirect) {
						$window.location.href = result.data.Redirect;
					}
				})
				.catch(function () {
				});
			return defered.promise;
		};

		repository.authenticateSaml = function () {
			$window.location.href = config.baseUrl + 'saml2/signIn?clientId=' + config.clientId;
		};

		repository.authenticate = function (username, password, logOutOtherLocation, twoFactor, twoFactorRemembered, successCallback, errorCallback) {
			var postData = {
				method: 'POST',
				url: encodeURI(config.baseUrl + 'Token'),
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
				},
				data: 'grant_type=password&username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password) +
					'&client_id=' + config.clientId + '&client_secret=' + config.clientSecret +
					'&fingerprint=' + config.fingerprint + '&force_logout=' + logOutOtherLocation
			};

			if (twoFactor) {
				postData.data += '&two_factor_code=' + twoFactor.code + '&two_factor_identifier=' + twoFactor.identifier;
				if (twoFactor.newSecret) {
					postData.data += '&two_factor_remember_secret=' + twoFactor.newSecret;
				}
			} else if (twoFactorRemembered) {
				postData.data += '&two_factor_remember_secret=' + twoFactorRemembered.secret + '&two_factor_identifier=' + twoFactorRemembered.identifier;
			}

			$http(postData).success(function (data) {
				repository.deleteLocalData();
				authenticationSuccess(data);
				commonService.setDataOwner({ Guid: data.selectedDataOwnerGuid, Description: "Klient" });
				commonService.setSelectedSelection({ Guid: data.selectedSelectionGuid, Description: userData.defaultSelectionDescription });
				setModules(data.selectedDataOwnerGuid);

				if (typeof successCallback === 'function') {
					successCallback(data);
				}
			}).error(function (data) {
				stopSignalR();
				if (typeof errorCallback === 'function') {
					if (data.error === "already_logged_in") {
						errorCallback({
							status: 'confirm_logout',
							message: "Brukeren er allerede logget inn et annet sted, klikk bekreft utlogging annet sted for å logge inn."
						});
					}
					else if (data.error && data.error.indexOf('two_factor') !== -1) {
						errorCallback({
							status: data.error,
							message: data.error_description
						});
					}
					else if (data.error_description) {
						errorCallback(
							{
								status: 'error',
								message: data.error_description
							});
					} else {
						errorCallback({
							status: 'error',
							message: 'Kunne ikke opprette forbindelse til webserver / Unable to contact api server; please try again later'
						});
					}
				}
			});
		};

		repository.forgotPassword = function (email) {
			var deferred = $q.defer();
			var postConfig = {
				method: 'POST',
				url: encodeURI(config.baseUrlApi + 'paswordrecovery/'),
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
				},
				data: '=' + email
			};
			$http(postConfig).success(function (message) {
				deferred.resolve(message);
			})
				.error(function (message) { deferred.reject(message); });

			return deferred.promise;
		};

		var permission = {};
		permission.None = 0;
		permission.Insert = 1;
		permission.Edit = 2;
		permission.Delete = 4;
		permission.Print = 8;
		permission.Open = 32;
		permission.Link = 64;
		permission.Deactivate = 128;
		permission.Approve = 256;
		permission.Last = 256;
		permission.Modify = permission.Insert | permission.Edit | permission.Delete;
		permission.All = permission.Insert | permission.Edit | permission.Delete | permission.Print | permission.Open | permission.Deactivate | permission.Approve;

		var accessKey = function (entity, parentEntity) {
			if (parentEntity && parentEntity !== "Undefined" && parentEntity !== "NullEntity")
				return (entity + parentEntity + commonService.getFilterData().selectedDataOwner.Guid).toLowerCase();

			return (entity + commonService.getFilterData().selectedDataOwner.Guid).toLowerCase();
		};

		repository.hasEditAccess = function (entity, checkOtherDataOwner, parentEntity) {
			try {
				var userAccess = repository.getUserAccess();
				var entityAccess = userAccess[accessKey(entity, parentEntity)];
				if (entityAccess) {
					if (checkOtherDataOwner) {
						return entityAccess.accessOtherDataOwner.CanUpdate;
					} else {
						return entityAccess.access.CanUpdate || entityAccess.accessOtherDataOwner.CanUpdate;
					}
				}
				return false;
			} catch (e) {
				return false;
			}
		};

		repository.hasDeleteAccess = function (entity, checkOtherDataOwner, parentEntity) {
			try {
				var userAccess = repository.getUserAccess();
				var entityAccess = userAccess[accessKey(entity, parentEntity)];
				if (entityAccess) {
					if (checkOtherDataOwner) {
						return entityAccess.accessOtherDataOwner.CanDelete;
					} else {
						return entityAccess.access.CanDelete || entityAccess.accessOtherDataOwner.CanDelete;
					}
				}
				return false;
			} catch (e) {
				return false;
			}
		};

		repository.hasCreateAccess = function (entity, parentEntity) {
			try {
				var userAccess = repository.getUserAccess();
				var entityAccess = userAccess[accessKey(entity, parentEntity)];
				if (entityAccess)
					return entityAccess.access.CanCreate || entityAccess.accessOtherDataOwner.CanCreate;
				return false;
			} catch (e) {
				return false;
			}
		};

		repository.hasReadAccess = function (entity, checkOtherDataOwner, parentEntity) {
			try {
				var userAccess = repository.getUserAccess();
				var entityAccess = userAccess[accessKey(entity, parentEntity)];
				if (entityAccess) {
					if (checkOtherDataOwner) {
						return entityAccess.accessOtherDataOwner.CanRead;
					} else {
						return entityAccess.access.CanRead || entityAccess.accessOtherDataOwner.CanRead;
					}
				}
				return false;
			} catch (e) {
				return false;
			}
		};

        repository.updateUserAccess = function (accessData) {
			if (accessData && accessData.EntityName) {
				if (accessData.EntityName === 'UserGroup')
					accessData.EntityName = 'User';
				var userAccess = repository.getUserAccess();
				userAccess[accessKey(accessData.EntityName, accessData.ParentEntityName)] = { access: accessData.Access || {}, accessOtherDataOwner: accessData.AccessOtherDataOwner || {}, prefix: accessData.EntityName, ParentPreFix: accessData.ParentEntityName };
				$localStorage.userAccess = userAccess;
			}
		};

		repository.getUserAccess = function () {
			var access = angular.fromJson($localStorage.userAccess);

			if (access) {
				return access;
			}
			return {};
		};

		return repository;
	}
})
	();
