(function () {
	angular.module('Plania').controller('ControlListController', ['$scope', 'Repository', '$stateParams', '$modal', 'TranslationService', '$location', 'SignalR', '$timeout', controller]);
	function controller($scope, repository, $stateParams, $modal, translationService, $location, signalR, $timeout) {
		$scope.isUpdate = $scope.navigation.current.name === 'controlList.edit';
		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = false;
		$scope.reloadRevisionTable = false;
		$scope.selectedFormItem = null;
		$scope.copyOfSelectedItem = {};
		$scope.conditionColumns = [];
		$scope.previewControlListXEntityObject = null;

		var availableHashLocations = ["#info", "#connections", "#history", "#answers", "#setup"];
		var updateLocation = function () {
			var hash = window.location.hash;
			if (hash && availableHashLocations.includes(hash)) {
				$('#controlListTabs a[data-target="' + hash + '"]').tab('show');
				$scope.currentTab = hash.substr(1);
			}
			else {
				$('#controlListTabs a[data-target="#info"]').tab('show');
				$scope.currentTab = 'info';
			}
		};

		$scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {
			updateLocation();
		});
		$scope.setTab = function (tab) {
			$location.hash(tab).replace();
		};

		if ($scope.isUpdate && $stateParams.tab) {
			$scope.setTab($stateParams.tab);
			updateLocation();
		} else {
			updateLocation();
		}

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.ControlList, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.ControlList, checkOtherDO);

				if ($scope.model.IsCurrentVersion === false) {
					$scope.restrictEdit = true;
					$scope.restrictDelete = true;
				}
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.ControlList);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.ControlList);
			}
		};

		updateAccess();

		var columns = [
			"ReferenceTypeNotExecuted.Id", "ReferenceTypeNotExecuted.Description"
		];
		var getEntity = function () {
			// for either update (with existing GUID) or insert (with default null GUID), fetch the whole entity to get proper FieldRules, which do not only depend on the entity type, but also on the current entity properties
			// furthermore, some fields may be dynamically initialized for new entities, what may be difficult to maintain in front end code
			// (there is not performance loss compared to the old solution, since a new object already needed a request to fetch field rules anyway)
			return repository.getMainModel(repository.apiData.controlList.url, ($scope.isUpdate ? $stateParams.guid : "00000000-0000-0000-0000-000000000000"), JSON.stringify(columns) + ($stateParams.guidEntityToCopy ? "&GuidCopyFrom=" + $stateParams.guidEntityToCopy : ""));
		};

		getEntity().then(function (response) {
			var controlList = response.Data;
			$scope.model = controlList;

			$scope.reload = true;
			if ($scope.isUpdate) {
				if ($scope.model.ControlListItems) {
					$scope.model.ControlListItems.forEach(function (item) {
						if (item.Data)
							item.Data = JSON.parse(item.Data);
					});
				} else
					$scope.model.ControlListItems = [];

				if ($scope.model.ControlListRules) {
					$scope.model.ControlListRules.forEach(function (item) {
						if (item.Conditions)
							item.Conditions = JSON.parse(item.Conditions);
						if (item.Parameters)
							item.Parameters = JSON.parse(item.Parameters);
					});
				} else
					$scope.model.ControlListRules = [];

				if ($scope.model.Conditions) $scope.conditionColumns = JSON.parse($scope.model.Conditions);
				$scope.reloadRevisionTable = true;
			}
			$scope.model.canSetNotExecuted = !!$scope.model.GuidReferenceTypeNotExecuted;

			updateAccess();

			$timeout(function () { $scope.activateAutocomplete = true; }, 250);
		},	function (error) {
				repository.growl(error, 'danger');
		});

		$scope.update = function (destination) {
			if ($scope.model.IsCurrentVersion === false) return;

			// continue with main control list saving process only if no error during saving of current item
			$scope.saveCurrentItem(function () {
				var modelCopy = angular.copy($scope.model);

				if ($scope.conditionColumns && $scope.conditionColumns.length > 0) modelCopy.Conditions = angular.toJson($scope.conditionColumns);
				else modelCopy.Conditions = undefined;


				delete modelCopy.ControlListItems;
				delete modelCopy.ControlListRules;

				var success = function (result) {
					repository.growl($scope.isUpdate ? translationService.translate('update-controlList-success', 'Kontrollisten ble oppdatert') : translationService.translate('create-controlList-success', 'Kontrollisten ble opprettet'), 'success');
					repository.commonService.setLastRegisterGuid(destination, result.Guid);
					$scope.updateModelWithCurrentVersion(true);

					var hash = window.location.hash;
					if (hash) hash = hash.substr(1);
					$scope.goBack(destination, { guid: result.Guid, menuGuid: $scope.navigation.params.menuGuid, tab: hash });
				};

				var error = function (error) {
					$scope.updateModelWithCurrentVersion(false);
					repository.growl(error, 'danger');
				};

				if ($scope.isUpdate) {
					$scope.showNewVersionGenerationWarning();
					repository.updateSingleDictionary(repository.apiData.controlList.url, modelCopy).then(success).catch(error);
				}
				else {
					repository.createSingleDictionary(repository.apiData.controlList.url, modelCopy).then(success).catch(error);
				}
			});
		};

		$scope.delete = function () {
			if ($scope.model.IsCurrentVersion === false) return;

			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-controlList-message', "Kontrollisten vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-controlList-button-confirm', 'Ja, fjern kontrollisten'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {

				repository.deleteSingle(repository.apiData.controlList.url, $scope.model.Guid).then(function (result) {
					swal(translationService.translate('web-swal-controlList-success', 'Kontrollisten ble fjernet!'), result, "success");
					$scope.goBack('controlList.list', { menuGuid: $scope.navigation.params.menuGuid });
				}, function (error) {

					var swalMessage = error.Message;
					if (error.IsDeactivateable) {
						swalMessage += '<br/><br/>Vil du deaktivere kontrollisten?';
					}
					swal({
						title: "Kunne ikke slette kontrollisten!",
						text: swalMessage,
						type: "error",
						html: true,
						showCancelButton: true,
						confirmButtonText: error.IsDeactivateable ? "Deaktiver" : "Ok",
						cancelButtonText: "Avbryt",
						closeOnConfirm: true,
						closeOnCancel: true
					}, function (isConfirm) {
						window.onkeydown = null;
						window.onfocus = null;
						if (isConfirm) {
							$scope.model.IsDeactivated = true;
							$scope.model.Name = '#' + $scope.model.Name;
							$scope.update('controlList.list');
						}
					});
				});
			});
		};

		$scope.openPreview = function (usePreviousEntity) {
			function openPreview() {
				var tempControlListItemAnswers = [];
				$scope.model.ControlListItems.forEach(function (item) {
					tempControlListItemAnswers.push({
						Guid: planiaUtils.randomString(16),
						User: {
							RealName: $scope.userInfo.realName
						},
						UpdatedDate: new Date().toISOString(),
						GuidControlListItem: item.Guid,
						ControlListItem: item,
						LinkedDeviations: [],
						LinkedRequests: []
					});
				});

				var workOrder = $scope.previewControlListXEntityObject.WorkOrder;
				if (!workOrder)
					workOrder = { Caption: "Forhåndsvisning" };

				$modal.open({
					templateUrl: 'app/controlList/views/controlListCompletionModal.html',
					size: 'lg',
					controller: 'ControlListCompletionModalController',
					resolve: {
						params: function () {
							return {
								controlList: $scope.model,
								controlListXEntity: $scope.previewControlListXEntityObject,
								controlListRules: $scope.model.ControlListRules,
								controlListItemAnswers: tempControlListItemAnswers,
								workOrder: workOrder,
								workOrderCaption: workOrder.Caption,
								isPreview: true
							};
						}
					}
				});
			}


			if (usePreviousEntity && $scope.previewControlListXEntityObject) {
				openPreview();
			} else {
				$modal.open({
					templateUrl: 'app/controlList/views/controlListPreviewEntitySelectionModal.html',
					size: 'md',
					controller: 'ControlListPreviewEntitySelectionModalController',
					resolve: {
						params: function () {
							return {
								controlList: $scope.model
							};
						}
					}
				}).result.then(function (result) {
					$scope.previewControlListXEntityObject = {
						GuidControlList: $scope.model.Guid,
						Status: "New"
					};

					if (result.workOrder) {
						$scope.previewControlListXEntityObject.GuidWorkOrder = result.workOrder.Guid;
						$scope.previewControlListXEntityObject.WorkOrder = result.workOrder;
					}

					if (result.equipment) {
						$scope.previewControlListXEntityObject.GuidEquipment = result.equipment.Guid;
						$scope.previewControlListXEntityObject.Equipment = result.equipment;
					}

					if (result.area) {
						$scope.previewControlListXEntityObject.GuidArea = result.area.Guid;
						$scope.previewControlListXEntityObject.Area = result.area;
					}

					openPreview();
				}, function () {
					//cancel
				});
			}
		};

		$scope.saveCurrentItem = function (thenFunction) {
			if ($scope.model.IsCurrentVersion === false) return;

			if ($scope.selectedFormItem && !angular.equals($scope.selectedFormItem, $scope.copyOfSelectedItem)) {
				var objectToSave = angular.copy($scope.selectedFormItem);
				if (objectToSave.Data) objectToSave.Data = JSON.stringify(objectToSave.Data);

				if (objectToSave.ControlListLogItem && objectToSave.ControlListLogItem.Id) {
					objectToSave.ControlListLogItem.Id = objectToSave.ControlListLogItem.Id.trim();
				}
				$scope.showNewVersionGenerationWarning();

				repository.updateSingleDictionary(repository.apiData.controlListItem.url, objectToSave).then(function (result) {

					//Saving ControlListItem can create a new version of the controlList if it's in use. if we're on current version and a new version is created on server side update version
					$scope.updateModelWithCurrentVersion(true, result);
					if (thenFunction)
						thenFunction();
				}).catch(function (error) {
					$scope.updateModelWithCurrentVersion(false);
					repository.growl(error, 'danger');
				});
			}
			else if (thenFunction)
				thenFunction();
		};

		var newVersionSwal = false;
		$scope.showNewVersionGenerationWarning = function () {
			if ($scope.model.RequireNewVersion) {
				swal({
					title: translationService.translate('web-swal-controlList-newVersion-header',
						'Genererer ny revisjon'),
					text: translationService.translate('web-swal-controlList-newVersion-message',
						"Vennligst vent med flere oppdateringer frem til denne advarselen forsvinner!"),
					type: "warning",
					buttons: false,
				});
				newVersionSwal = true;
			}
		};

		$scope.updateModelWithCurrentVersion = function (updateVersionNumber, result) {
			if (updateVersionNumber && result === undefined) {
				result = { ControlList: angular.copy($scope.model) };
				result.ControlList.Version += 1;
			}

			if (result && result.ControlList && result.ControlList.Version !== $scope.model.Version) {
				if ($scope.model.IsCurrentVersion && result.ControlList.IsCurrentVersion) {
					$scope.model.Version = result.ControlList.Version;
					$scope.model.IsChangesSlow = false;
					$scope.model.RequireNewVersion = false;
				}
			}

			if (newVersionSwal) {
				setTimeout(function () {
					swal.close();
				}, 2000);
				newVersionSwal = false;
			}
		};

		$scope.copyControlList = function () {
			if ($scope.model.IsCurrentVersion === false) return;

			$modal.open({
				templateUrl: 'app/controlList/views/controlListCopyModal.html',
				controller: 'ControlListCopyController',
				resolve: {
					params: function () {
						return {
							model: angular.copy($scope.model)
						};
					}
				}
			}).result.then(function (result) {
				result.GuidCopyFrom = $scope.model.Guid;
				repository.createSingleDictionary(repository.apiData.controlList.url, result).then(function (response) {
					$scope.navigation.go('controlList.edit', { guid: response.Guid });
				}, function (error) {
					repository.growl(error, 'danger');
				});
			});
		};

		signalR.on('ControlListRequireNewVersion' + $stateParams.guid, function () {
			$scope.model.RequireNewVersion = true;
		});

		$scope.$on('$destroy', function () {
			signalR.off('ControlListRequireNewVersion' + $stateParams.guid);
		});

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
