(function () {
	angular.module('Plania').controller('PersonController', ['$scope', 'Repository', '$stateParams', '$filter', 'ngTableParams', '$modal', 'authService', 'TranslationService', 'ListService', controller]);

	function controller($scope, repository, $stateParams, $filter, ngTableParams, $modal, authService, translationService, listService) {
		$scope.update = $scope.navigation.current.name === 'person.edit';
		$scope.isBuildingCollapsed = true;
		$scope.restrictEdit = !authService.hasEditAccess('person');
		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = false;
		$scope.viewMode = $scope.update ? true : false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};

		var updateAccess = function () {
			if ($scope.update) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !authService.hasEditAccess(repository.commonService.prefix.Person, checkOtherDo);
				$scope.restrictDelete = !authService.hasDeleteAccess(repository.commonService.prefix.Person, checkOtherDo);
			} else {
				$scope.restrictEdit = !authService.hasCreateAccess(repository.commonService.prefix.Person);
			}
		};

		updateAccess();

		$scope.personBuildingTableColumns = [
			{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-buildingcategory-description'), Property: 'Building.BuildingCategory.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuildingCategory', AutocompleteService: 'buildingCategory' },
			{ Position: 4, Title: translationService.translate('web-building-address'), Property: 'Building.Address', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-building-postalcode'), Property: 'Building.PostalCode', PropertyType: 'string' },
			{ Position: 6, Title: translationService.translate('web-building-postalarea'), Property: 'Building.PostalArea', PropertyType: 'string' },
			{ Position: 7, Title: translationService.translate('web-costcenter-id'), Property: 'Building.CostCenter.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidCostCenter', AutocompleteService: 'costCenter' },
		];

		var getCardTables = function () {
			$scope.buildingXPersonTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'Building.Id': "asc" },
				filter: { PropertyFilter: [{ Property: "GuidPerson", Operator: "=", Value: $scope.model.Guid }] }
			}, {
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					getData: function ($defer, params) {
						var columns = [];
						$scope.personBuildingTableColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						repository.GetPaginated(repository.apiData.buildingPerson.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
							.then(function (result) {
								$scope.buildingXPersonTable.settings().total = result.TotalCount;
								$scope.buildingXPersonTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');

							});
					}
				});
		};

		var setProperties = function () {
			var person = $scope.model;

			if (person.Department)
				$scope.Department = $scope.getEntityCaption(person.Department);

			if (person.Organization)
				$scope.Organization = $scope.getEntityCaption(person.Organization);

			if (person.PersonRole)
				$scope.PersonRole = $scope.getEntityCaption(person.PersonRole);

			if (person.ResourceGroup)
				$scope.ResourceGroup = $scope.getEntityCaption(person.ResourceGroup);

			if (person.Area) {
				$scope.Area = $scope.getEntityCaption(person.Area);
			}
		};

		if ($scope.update) {
			var columns = ["Department.Id", "Organization.Id", "Organization.Description", "PersonRole.Id", "ResourceGroup.Id", 'Area.Id', 'Area.Description'];
			repository.getMainModel(repository.apiData.person.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					var person = response.Data;
					$scope.model = person;
					$scope.enableWebNavigation = repository.enableWebNavigation;
					updateAccess();

					setProperties();
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});
			getCardTables();
		} else {

			$scope.activateAutocomplete = true;
			$scope.model.Hours = 40;

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						$scope[prefill.Property] = prefill.Value.Label;
						$scope.model['Guid' + prefill.Property] = prefill.Value.Guid;
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}
		}

		$scope.addBuilding = function () {
			$modal.open({
				templateUrl: 'app/buildingPerson/views/createBuildingPerson.html',
				controller: 'BuildingPersonController',
				resolve: {
					params: function () {
						return {
							GuidPerson: $scope.model.Guid,
							GuidPersonRole: $scope.model.GuidPersonRole,
							personRole: $scope.PersonRole,
							personCaption: $scope.model.FirstName + ' ' + $scope.model.LastName,
							selectedEntityGuids: _.map($scope.buildingXPersonTable.data, function (row) {
								return { GuidBuilding: row.GuidBuilding, GuidPerson: row.GuidPerson, GuidPersonRole: row.GuidPersonRole };
							}),
							isModal: true
						};
					}
				}
			}).result.then(function () {
				$scope.buildingXPersonTable.reload();
				$scope.isBuildingCollapsed = false;
			},
				function () {
					//cancel
					$scope.isBuildingCollapsed = false;
				});
		};

		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};

		$scope.updatePerson = function (destination) {
			var success = function (result) {
				repository.growl($scope.update ? "Person har blitt oppdatert" : 'Person har blitt lagret', 'success');
				var guid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.update)
				repository.updateSingleDictionary(repository.apiData.person.url, $scope.model).then(success).catch(error);
			else
				repository.createSingleDictionary(repository.apiData.person.url, $scope.model).then(success).catch(error);

		};

		$scope.removeBuilding = function (item) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-person-building-message', 'Bygningen vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-person-building-button-confirm', 'Ja, fjern bygningen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.buildingPerson.url, item.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-person-building-success', 'Bygningen ble fjernet!'), result, "success");
						$scope.buildingXPersonTable.reload();
					});
			});
		};

		$scope.removePerson = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-person-message', 'Personen vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-person-button-confirm', 'Ja, fjern personen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.person.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-person-success', 'Personen ble fjernet!'), result, "success");
						$scope.goBack('person.list', { menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {
						swal({
							title: "Kunne ikke slette personen!",
							text: error.Message + "<br/><br/> Vil du deaktivere personen?",
							type: "error",
							html: true,
							showCancelButton: true,
							confirmButtonText: "Deaktiver",
							cancelButtonText: "Avbryt",
							closeOnConfirm: true,
							closeOnCancel: true
						}, function (isConfirm) {
							window.onkeydown = null;
							window.onfocus = null;
							if (isConfirm) {
								$scope.model.EmployeeNr = '#' + $scope.model.EmployeeNr;
								$scope.model.IsDeactivated = true;
								$scope.updatePerson('person.list');
							}
						});
					});
			});
		};

		$scope.openMail = function () {
			window.location.href = 'mailto:' + $scope.model.Email;
		};


		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
