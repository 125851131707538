(function () {
	angular.module('Plania').controller('SelectAttachmentModalController', ['$scope', '$modalInstance', 'params', 'Repository', controller]);

	function controller($scope, $modalInstance, params, repository) {
		$scope.selectedDocuments = [];
		$scope.documents = params.attachments;
		$scope.connectedEntityDocuments = [];

		$scope.documents.forEach(function (document) {
			if (_.contains(params.selectedAttachmentGuids, document.Guid)) {
				document.isAlreadySelected = true;
				$scope.selectedDocuments.push(document);
			} else {
				document.isAlreadySelected = false;
			}
		});

		if (params.connectedEntity && params.connectedEntityGuid) {
			repository.GetPaginated(repository.apiData.documentXEntity.url, 0, 100, { 'Document.Id': 'desc' }, { PropertyFilter: [{ Property: 'Guid' + params.connectedEntity, Operator: '=', Value: params.connectedEntityGuid }] }, 0, JSON.stringify(columns)).then(function (response) {
				$scope.connectedEntityDocuments = response.List;
			});
		}

		$scope.isSelected = function (guid) {
			return _.find($scope.selectedDocuments, { Guid: guid });
		};

		$scope.selectDocument = function (document) {
			if (document.isAlreadySelected) return;

			if (!_.find($scope.selectedDocuments, { Guid: document.Guid }))
				$scope.selectedDocuments.push(document);
			else {
				for (var i = 0; i < $scope.selectedDocuments.length; i++) {
					var doc = $scope.selectedDocuments[i];
					if (doc.Guid === document.Guid) {
						$scope.selectedDocuments.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.ok = function () {
			$modalInstance.close($scope.selectedDocuments);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss();
		};
	}
})();
