(function () {
	angular.module('Plania').controller('MyProfileController', ['$scope', '$rootScope', "Repository", 'config', '$modal', '$rootScope', '$localStorage', controller]);

	function controller($scope, rootScope, repository, config, $modal, $rootScope, $localStorage) {
		$scope.activateAutocomplete = false;
		$scope.imageApiUrl = config.baseUrlApi + repository.apiData.image.url;
		$scope.profileModel = {};
		$scope.profileModel.GuidResourceGroup = '';

		$scope.useTwoFactorAuthentication = !!$localStorage.generalOptions.TwoFactorAuthenticationOption;
		$scope.showError = {
			resourceGroup: false
		};

		repository.getSingle(repository.apiData.user.url, rootScope.userInfo.guidUser).then(
			function (result) {
				$scope.user = result.Data;
				$scope.useTwoFactorAuthentication = $scope.user.IsTwoFactorAuthenticationEnabled;
				repository.getSingle(repository.apiData.person.url, $scope.user.GuidPerson, JSON.stringify(["ResourceGroup.Id"]))
					.then(
						function (result) {
							$scope.person = result.Data;
							if ($scope.person.ResourceGroup) {
								$scope.selectedResourceGroup = $scope.person.ResourceGroup.Id;
							}
							setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
						});
			}
		);

		$scope.autoCompleteFilter = function (filterName) {
			switch (filterName) {
				case "resourceGroup":
					return {
						PropertyFilter: [{ Property: 'DoesCleaningTasks', Operator: '=', Value: true }]
					};
			}
		};

		$scope.setResourceGroup = function (resourceGroup) {
			if (!resourceGroup) {
				$scope.showError.resourceGroup = true;
				return;
			}
			$scope.showError.resourceGroup = false;
			$scope.person.GuidResourceGroup = resourceGroup.Guid;
			$scope.profileModel.GuidResourceGroup = resourceGroup.Guid;
			$scope.selectedResourceGroup = resourceGroup.Id + " ";
			repository.createSingle(repository.apiData.user.urlChangeTeam, $scope.profileModel)
				.then(function (result) {
					repository.growl("Oppdatert brukers team", "success");
				}, repository.showError);
		};

		$scope.uploadImage = function (images) {
			if (images.length < 1) return;
			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/imageUploadModal.html',
				controller: 'ImageUploadModalController',
				backdrop: 'static',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							image: images[0],
							targetSize: 300,
							areaType: 'square'
						};
					}
				}
			});

			modalInstance.result.then(function (croppedImage) {
				var files = [];
				handleFileUpload(croppedImage);
				//Read Base64 string and add to file object
				function handleFileUpload(file) {
					var reader = new FileReader();
					reader.onload = function (e) {
						var newFile = {
							ImageSrc: e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length),
							Name: file.name
						};
						files.push(newFile);
						onLoadEndHandler();
					};
					reader.readAsDataURL(file);
				}

				function onLoadEndHandler(image) {
					repository.createMultiple(repository.apiData.image.url, files).then(function (imageResult) {
						var newGuidImage = imageResult[0].Guid;
						var changeUserImageDto = {
							GuidImage: newGuidImage,
							GuidUser: rootScope.userInfo.guidUser
						};

						repository.createSingle(repository.apiData.user.urlChangeImage, changeUserImageDto).then(function (result) {

							$scope.user.GuidImage = newGuidImage;
							$rootScope.userInfo.guidImage = newGuidImage;
							$localStorage.userData.guidImage = newGuidImage;
							repository.growl('Bilde oppdatert', 'success');
						}, function (error) {
							repository.growl(error, 'danger');
						});
					}, function (error) {
						repository.growl(error, 'danger');
					});
				}
			});
		};
	}
})();
