(function () {

	var app = angular.module('Plania');

	app.directive('controlLists', function () {
		return {
			restrict: 'E',
			scope: {
				isCollapsed: '=isCollapsed',
				reload: '=reload',
				reloadDeviations: '=reloadDeviations',
				controlLists: '=controlLists',
				disableActions: '=disableActions',
				parentEntity: '=parentEntity',
				parentModel: '=parentModel',
				guid: '=guid',
				restrictEdit: '=restrictEdit'
			},
			controller: ['$scope', '$modal', 'ngTableParams', 'Repository', '$rootScope', 'TranslationService', '$localStorage', 'SignalR', '$timeout', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/controlList/views/controlListDirective.html'
		};
	});

	function controller($scope, $modal, ngTableParams, repository, $rootScope, translationService, $localStorage, signalR, $timeout) {
		$scope.controlListsIsLoading = false;
		$scope.addingControlListSignalR = false;
		$scope.search = {};
		$scope.isSVV = $localStorage.generalOptions.CustomerId === '10088';

		$scope.accessCreateControlListXEntity = function () {
			return $rootScope.hasEditAccess('ControlListXEntity') && $rootScope.hasReadAccess('ControlList') && ($scope.parentEntity === 'WorkOrder' || $scope.parentEntity === 'PeriodicTask');
		};

		$scope.hasReadAccess = $rootScope.hasReadAccess('ControlListXEntity');

		$scope.$watch('reload', function (newValue, oldValue) {

			if ($scope.reload && $scope.reload === true) {
				var propertyFilter = $scope.controlListTable.filter().PropertyFilter;
				if (propertyFilter) {
					$scope.controlListTable.filter().PropertyFilter.push({ Property: 'Guid' + $scope.parentEntity, Operator: '=', Value: $scope.guid });
				} else {
					$scope.controlListTable.filter().PropertyFilter = [{ Property: 'Guid' + $scope.parentEntity, Operator: '=', Value: $scope.guid }];
				}

				if ($scope.parentEntity === 'Area' || $scope.parentEntity === 'Equipment') {
					$scope.controlListTable.filter().PropertyFilter.push({ Property: 'GuidWorkOrder', Operator: '<>', Value: null });
				}
				$scope.controlListTable.filter().ExcludeSelectionFilter = true;
				$scope.controlListTable.reload();

				$scope.reload = false;
			}
		});

		$scope.columns = [
			//{ Position: 1, Title: translationService.translate('web-estate-id'), Property: 'Estate.Id', PropertyType: 'string' },
			//{ Position: 2, Title: translationService.translate('web-estate-description'), Property: 'Estate.Description', PropertyType: 'string' },
			//{ Position: 3, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'string' },
			//{ Position: 4, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-area-id'), Property: 'Area.Id', PropertyType: 'string' },
			{ Position: 6, Title: translationService.translate('web-area-description'), Property: 'Area.Description', PropertyType: 'string' },
			{ Position: 7, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'string' },
			{ Position: 8, Title: translationService.translate('web-equipment-description'), Property: 'Equipment.Description', PropertyType: 'string' },
			{ Position: 9, Title: translationService.translate('web-controllist-ismandatory'), Property: 'ControlList.IsMandatory', PropertyType: 'bool' },
			{ Position: 10, Title: translationService.translate('web-controllist-id'), Property: 'ControlList.Id', PropertyType: 'string' },
			{ Position: 10, Title: translationService.translate('web-controllist-description'), Property: 'ControlList.Description', PropertyType: 'string' },
			{ Position: 10, Title: translationService.translate('web-controllist-name'), Property: 'ControlList.Name', PropertyType: 'string' },
			{ Position: 10, Title: translationService.translate('web-controllist-icon'), Property: 'ControlList.Icon', PropertyType: 'string' },
			{ Position: 10, Title: translationService.translate('web-controllist-Color'), Property: 'ControlList.Color', PropertyType: 'string' },
		];

		//Returns the entity connected to the the controlList that is the lowest in the hierarchy
		$scope.getLowestEntityCaption = function (controlList) {
			if (controlList.Equipment) {
				var extraText = "";
				if ($scope.isSVV && controlList.Equipment && controlList.Equipment.Text20)
					extraText = " - " + controlList.Equipment.Text20;
				return $rootScope.getEntityCaption(controlList.Equipment) + extraText;
			}
			if (controlList.Area) {
				if ($localStorage.generalOptions.IsBBY) {
					return $rootScope.getEntityCaption(controlList.Area) + " - " + controlList.Area.Text01;
				}
				return $rootScope.getEntityCaption(controlList.Area);
			}
			if (controlList.Building) return $rootScope.getEntityCaption(controlList.Building);
			if (controlList.Estate) return $rootScope.getEntityCaption(controlList.Estate);
		};

		$scope.getEntityIcon = function (controlList) {
			if (controlList.Equipment) return 'zmdi-settings c-bluegray';
			if (controlList.Area) return 'zmdi-layers c-lightblue';
			if (controlList.Building) return 'zmdi-balance c-brown';
			if (controlList.Estate) return 'zmdi-city c-teal';
		};

		var sorting = {
			'ClosedDate': 'asc',
			'Area.Id': 'asc',
			'Equipment.Id': 'asc',
			'CreationDate': 'desc'
		};

		$scope.controlListTable = new ngTableParams({
			page: 1,
			count: 5,
			sorting: sorting
		}, {
				total: 0,
				counts: [5, 10, 20],
				filterDelay: 50,
				paginationMaxBlocks: 6,
				getData: function ($defer, params) {
					if (!$scope.controlListTable.filter().PropertyFilter || !_.find($scope.controlListTable.filter().PropertyFilter, function (filter) { return filter.Property.indexOf('Guid') !== -1; })) return;

					$scope.controlListsIsLoading = true;

					var columns = [];
					$scope.columns.forEach(function (col) {
						columns.push(col.Property);
					});

					columns.push('ControlList.Guid');
					columns.push('ControlList.UseImage');
					columns.push('CreationDate');
					columns.push('Status');
					columns.push('ClosedDate');
					columns.push('NotExecutedComment');
					columns.push('ReferenceDataNotExecuted.Id');
					columns.push('ReferenceDataNotExecuted.Description');
					columns.push('ControlList.IsCurrentVersion');
					columns.push('ControlList.GuidReferenceTypeNotExecuted');
					columns.push('User.RealName');
					columns.push('User1.RealName');
					columns.push('WorkOrder.Id', 'WorkOrder.Description');
					columns.push('Equipment.Building.Id', 'Equipment.Building.Description', 'Equipment.Building.Guid', 'Equipment.Manufacturer', 'Equipment.Location');
					columns.push('Equipment.Building.Estate.Id', 'Equipment.Building.Estate.Description', 'Equipment.Building.Estate.Guid');
					columns.push('Area.IsHousing');
					columns.push('Area.Building.Id', 'Area.Building.Description', 'Area.Building.Guid');
					columns.push('Area.Building.Estate.Id', 'Area.Building.Estate.Description', 'Area.Building.Estate.Guid');

					if ($localStorage.generalOptions.IsBBY) {
						columns.push('Area.Text01', 'Area.AreaCategory.Description');
					}

					if ($scope.isSVV)
						columns.push('Equipment.Text20');

					if ($scope.parentEntity === 'Area' || $scope.parentEntity === 'Equipment') columns.push('WorkOrder.EndDate', 'WorkOrder.Stage');

					repository.GetPaginated(repository.apiData.controlListXEntity.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns))
						.then(function (result) {
							$scope.controlListTable.settings().total = result.TotalCount;
							$scope.controlListTable.settings().filterDelay = 500;
							$scope.controlListsIsLoading = false;

							if (!$scope.controlLists)
								$scope.controlLists = [];

							if ($scope.controlLists) {
								while ($scope.controlLists.length > 0)
									$scope.controlLists.pop();
							}

							result.List.forEach(function (controlList) {
								$scope.controlLists.push(controlList);
							});

							$defer.resolve(result.List);
						}, function (error) {
							$scope.controlListsIsLoading = false;
							repository.growl(error, 'danger');
						});
				}
			});

		var filterTypes = [
			{ value: 'all', name: translationService.translate('web-controlListXEntity-filterType-all', 'Alle') },
			{ value: 'new', name: translationService.translate('web-controlListXEntity-filterType-new', 'Nye') },
			{ value: 'active', name: translationService.translate('web-controlListXEntity-filterType-open', 'Aktive') },
			{ value: 'completed', name: translationService.translate('web-controlListXEntity-filterType-completed', 'Fullførte') }
		];

		$scope.setFilter = function (filterType) {
			$scope.selectedFilterName = _.find(filterTypes, { value: filterType }).name;
			$localStorage.selectedControlListXEntityFilterType = filterType;

			var existingStatusFilterIndex = _.findIndex($scope.controlListTable.filter().PropertyFilter, function (o) { return o.Property === 'Status'; });

			if (existingStatusFilterIndex > -1) {
				$scope.controlListTable.filter().PropertyFilter.splice(existingStatusFilterIndex, 1);
			}

			var propertyFilter = $scope.controlListTable.filter().PropertyFilter;

			switch (filterType) {
				case 'all':
					break;
				case 'new':
					if (propertyFilter)
						propertyFilter.push({ Property: 'Status', Operator: '=', Value: '0' });
					else
						$scope.controlListTable.filter().PropertyFilter = [{ Property: 'Status', Operator: '=', Value: '0' }];
					break;
				case 'active':
					if (propertyFilter)
						propertyFilter.push({ Property: 'Status', Operator: '=', Value: '1' });
					else
						$scope.controlListTable.filter().PropertyFilter = [{ Property: 'Status', Operator: '=', Value: '1' }];
					break;
				case 'completed':
					if (propertyFilter)
						propertyFilter.push({ Property: 'Status', Operator: '=', Value: '2' });
					else
						$scope.controlListTable.filter().PropertyFilter = [{ Property: 'Status', Operator: '=', Value: '2' }];
					break;
			}
		};

		if ($localStorage.selectedControlListXEntityFilterType) {
			$scope.selectedFilterName = _.find(filterTypes, { value: $localStorage.selectedControlListXEntityFilterType }).name;
			$scope.setFilter($localStorage.selectedControlListXEntityFilterType);
		} else {
			$scope.selectedFilterName = _.find(filterTypes, { value: 'all' }).name;
		}

		$scope.create = function () {
			$modal.open({
				templateUrl: 'app/controlList/views/addControlListModal.html',
				controller: 'AddControlListModalController',
				size: $scope.isSVV ? 'lg' : 'md',
				resolve: {
					params: function () {
						return {
							guidWorkOrder: $scope.parentEntity === 'WorkOrder' ? $scope.guid : null,
							guidPeriodicTask: $scope.parentEntity === 'PeriodicTask' ? $scope.guid : null,
							guidBuilding: $scope.parentModel.GuidBuilding,
							guidEstate: $scope.parentModel.GuidEstate,
							guidEquipment: $scope.parentModel.GuidEquipment,
							guidArea: $scope.parentModel.GuidArea,
						};
					}
				}
			}).result.then(function (response) {
				$scope.controlListTable.reload();
			}, function () {
				$scope.controlListTable.reload();
			});
		};

		$scope.openControlList = function (controlListXEntity) {
			$modal.open({
				templateUrl: 'app/controlList/views/controlListCompletionModal.html',
				size: 'lg',
				controller: 'ControlListCompletionModalController',
				resolve: {
					params: function () {
						return {
							controlList: controlListXEntity.ControlList,
							controlListXEntity: controlListXEntity,
							workOrderCaption: $scope.parentModel.Caption,
							workOrder: $scope.parentModel,
							restrictEdit: (controlListXEntity.WorkOrder && controlListXEntity.WorkOrder.EndDate) || $scope.parentModel.EndDate || $scope.parentEntity === 'PeriodicTask' || $scope.restrictEdit ? true : false
						};
					}
				}
			}).result.then(function (result) {
				$scope.controlListTable.reload();
				$scope.reloadDeviations = true;
			}, function (reloadLists) {
				$scope.controlListTable.reload();
				$scope.reloadDeviations = true;
			});
		};

		$scope.remove = function (item) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-controlList-message', 'Kontrollisten vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-controlList-button-confirm', 'Ja, fjern kontrollisten!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.deleteSingle(repository.apiData.controlListXEntity.url, item.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-controlList-success', 'Kontrollisten ble fjernet!'), result, "success");
						$scope.controlListTable.reload();
					}, function (error) {
						swal('Error', error, "error");
					});
			});
		};

		// SignalR
		if ($scope.parentEntity === 'WorkOrder' && $scope.guid) {
			$scope.signalRData = {};
			$scope.showAddingControlListWorkOrderMessage = false;
			signalR.on('addingControlListsToWorkOrder' + $scope.guid, function (progress) {
				// Increase both with 1, in order to show correct progress bar.
				progress.Current++;
				progress.Total++;

				if (!$scope.showAddingControlListWorkOrderMessage && progress.Started) $scope.showAddingControlListWorkOrderMessage = true;
				if ($scope.showAddingControlListWorkOrderMessage && progress.Finished) {
					$timeout(function () {
						$scope.showAddingControlListWorkOrderMessage = false;
					}, 5000);
				}

				$scope.addControlListToWorkOrderData = progress;
				if (progress.Finished) {
					$timeout(function () {
						$scope.controlListTable.reload();
					}, 250);
				}
			});

			$scope.$on('$destroy', function () {
				signalR.off('addingControlListsToWorkOrder' + $scope.guid);
			});
		}

		$scope.$on($rootScope.events.newSelection, function () {
			$scope.controlListTable.reload();
		});
	}
})();
