(function () {
	angular.module('Plania').controller('SidebarController', ['$scope', 'Repository', '$localStorage', '$rootScope', 'TranslationService', 'states', 'CommonService', controller]);

	function controller($scope, repository, $localStorage, $rootScope, translationService, states, commonService) {
		$scope.isBBY = $localStorage.generalOptions.IsBBY;
		$scope.guidDataOwner = commonService.getFilterData().selectedDataOwner.Guid;
		$scope.hasImageLogo = !!$localStorage.generalOptions.GuidImageLogo || commonService.getFilterData().selectedDataOwner.GuidImageLogo;
		$scope.mainMenu = [];

		var updateAccess = function () {
			repository.GetPaginated(repository.apiData.userAccess.url, 0, 0, {}, {}).then(function (response) {
				if (response) {
					response.forEach(function (row) {
						repository.authService.updateUserAccess(row);
					});
				}
				$rootScope.$broadcast($rootScope.events.updatedAccess, true);
				updateMenu();
			});
		};
		updateAccess();

		var canRead = $scope.hasReadAccess;

		var hasModule = {
			activity: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Activity),
			api: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Api),
			area: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Area),
			budget: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Budget),
			cleaningWeb: repository.moduleService.hasModule(repository.moduleService.moduleFlags.CleaningWeb),
			controlList: repository.moduleService.hasModule(repository.moduleService.moduleFlags.ControlList),
			core: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Core),
			dataAcquisition: repository.moduleService.hasModule(repository.moduleService.moduleFlags.DataAcquisition),
			dataImport: repository.moduleService.hasModule(repository.moduleService.moduleFlags.DataImport),
			deviation: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Deviation),
			document: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Document),
			entityLink: repository.moduleService.hasModule(repository.moduleService.moduleFlags.EntityLink),
			housing: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Housing),
			internalRent: repository.moduleService.hasModule(repository.moduleService.moduleFlags.InternalRent),
			inventoryControl: repository.moduleService.hasModule(repository.moduleService.moduleFlags.InventoryControl),
			invoice: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Invoice),
			key: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Key),
			map: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Map),
			projectManagement: repository.moduleService.hasModule(repository.moduleService.moduleFlags.ProjectManagement),
			rent: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Rent),
			sparePart: repository.moduleService.hasModule(repository.moduleService.moduleFlags.SparePart),
			brand: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Brand),
			elhub: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Elhub),
		};

		// Do not change function name, unless the name is changed in plania_translate.
		// Added this to simplify automatically add translation to translation list, since we will use systemName to get in-browser translation config to work.
		var menuItem = function (systemName, defaultName, sref, icon) {
			return {
				index: sref + systemName + defaultName,
				sref: sref,
				systemName: systemName,
				defaultName: defaultName,
				icon: icon,
				title: translationService.translate(systemName, defaultName), // For sorting
				children: []
			};
		};

		var createBasicMenu = function () {
			var menu = menuItem('web-topMenu-basicData', 'Grunndata', null, 'zmdi-collection-text');
			if (canRead(repository.commonService.prefix.Estate) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-estate', 'Eiendom', 'estate.list', 'zmdi-city zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Building) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-building', 'Bygg', 'building.list', 'zmdi-balance zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Equipment) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-equipment', 'Anlegg', 'equipment.list', 'zmdi-settings zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Equipment) && hasModule.dataAcquisition)
				menu.children.push(menuItem('web-topMenu-equipment-dataacquisition', 'Datafangst', 'equipment.dataacquisition', 'zmdi-view-list-alt zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Drawing) && hasModule.area)
				menu.children.push(menuItem('web-topMenu-drawing', 'Tegninger', 'dwg.list', 'zmdi-image zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Area) && hasModule.area)
				menu.children.push(menuItem('web-topMenu-area', 'Rom', 'area.list', 'zmdi-layers zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Article) && (hasModule.activity || hasModule.rent || hasModule.internalRent || hasModule.housing))
				menu.children.push(menuItem('web-topMenu-article', 'Artikler', 'article.list', 'zmdi-book-image zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Document) && hasModule.document)
				menu.children.push(menuItem('web-topMenu-document', 'Dokumenter', 'document.list', 'zmdi-file-text zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Component) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-component', 'Komponenter', 'component.list', 'zmdi-card-sim zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Customer) && (hasModule.activity || hasModule.area || hasModule.key || hasModule.rent || hasModule.internalRent || hasModule.housing))
				menu.children.push(menuItem('web-topMenu-customer', 'Kunder', 'customer.list', 'zmdi-accounts-list zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Supplier) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-supplier', 'Leverandører', 'supplier.list', 'zmdi-truck zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Person) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-person', 'Personer', 'person.list', 'zmdi-male-female zmdi-hc-fw'));
			return menu;
		};

		var createSparePartMenu = function () {
			var menu = menuItem('web-topMenu-group-sparePart', 'Lagerhold', null, 'zmdi-input-power zmdi-hc-fw');

			if (canRead(repository.commonService.prefix.SparePart) && hasModule.sparePart)
				menu.children.push(menuItem('web-topMenu-sparePart', 'Reservedeler', 'sparePart.list'));

			if (canRead(repository.commonService.prefix.SparePartCounting) && hasModule.inventoryControl)
				menu.children.push(menuItem('web-topMenu-sparePartCounting', 'Varetelling', 'sparePartCounting.list'));

			if (canRead(repository.commonService.prefix.SparePartCountingItem) && hasModule.inventoryControl)
				menu.children.push(menuItem('web-topMenu-sparePartCountingItem', 'Lager avvik', 'sparePartCountingItem.list'));

			if (canRead(repository.commonService.prefix.SparePartWithdrawal) && hasModule.sparePart)
				menu.children.push(menuItem('web-topMenu-sparePartWithdrawal', 'Uttakshistorikk', 'sparePartWithdrawal.list'));

			return menu;
		};

		var createMaintenanceMenu = function () {
			var menu = menuItem('web-topMenu-maintenance', 'Vedlikehold', null, 'zmdi-wrench');

			if (canRead(repository.commonService.prefix.WorkOrder) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-workOrder', 'Arbeidsordre', 'workOrder.list', 'zmdi-case zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Deviation) && hasModule.deviation)
				menu.children.push(menuItem('web-topMenu-deviation', 'Avvik', 'deviation.list', 'zmdi-alert-octagon zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Budget) && hasModule.budget)
				menu.children.push(menuItem('web-topMenu-budget', 'Budsjett', 'budget.list', 'zmdi-money-box zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Cost) && (hasModule.budget || hasModule.activity))
				menu.children.push(menuItem('web-topMenu-cost', 'Kostnader', 'cost.list', 'zmdi-receipt zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.PurchaseOrder) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-purchaseOrder', 'Bestilling', 'purchaseOrder.list', 'zmdi-shopping-cart zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.PurchaseOrderItem) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-purchaseOrderItem', 'Bestillingsobjekter', 'purchaseOrderItem.list', 'zmdi-shopping-cart-add zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Request) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-request', 'Meldinger', 'request.list', 'zmdi-email zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.OperationalMessage) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-operationalMessage', 'Driftsmeldinger', 'operationalMessage.list', 'zmdi-comment-alert zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.PeriodicTask) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-periodicTask', 'Periodiske rutiner', 'periodicTask.list', 'zmdi-calendar-note zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Project) && hasModule.projectManagement)
				menu.children.push(menuItem('web-topMenu-project', 'Prosjekter', 'project.list', 'zmdi-collection-bookmark zmdi-hc-fw'));

			var sparePartMenu = createSparePartMenu();
			if (sparePartMenu.children.length) menu.children.push(sparePartMenu);

			if (canRead(repository.commonService.prefix.Condition) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-correctiveAction', 'Tilstand og Tiltak', 'condition.list', 'zmdi-plaster zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.ControlListXEntity) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-completedControlLists', 'Utfylte kontrollister', 'completedControlLists.list', 'zmdi-view-list zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.ControlListItemAnswer) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-controlListAnswer', 'Kontrollistesvar', 'controlListItemAnswer.list', 'zmdi-view-list zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.WorkOrder) && canRead(repository.commonService.prefix.PeriodicTask) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-maintenancePlan', 'Vedlikeholdsplan', 'maintenancePlan', 'zmdi-calendar-alt zmdi-hc-fw'));

			return menu;
		};

		var createManagementMenu = function () {
			var menu = menuItem('web-topMenu-management', 'Forvaltning', null, 'zmdi-collection-bookmark');
			if (canRead(repository.commonService.prefix.Area) && hasModule.housing)
				menu.children.push(menuItem('web-topMenu-housing', 'Bolig', 'housing.list', 'zmdi-home zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Contract) && hasModule.housing && $localStorage.generalOptions.ShowContractHousingInWeb)
				menu.children.push(menuItem('web-topMenu-housingContract', 'Boligavtaler', 'housingContract.list', 'zmdi-book zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Customer) && hasModule.housing)
				menu.children.push(menuItem('web-topMenu-housingCustomer', 'Leietaker', 'housingCustomer.list', 'zmdi-accounts-list zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Contract) && (hasModule.internalRent || hasModule.rent || hasModule.invoice) && $localStorage.generalOptions.UseReinvoicing)
				menu.children.push(menuItem('web-topMenu-contract', 'Fakturering', 'reinvoicingContract.list', 'zmdi-assignment zmdi-hc-fw'));

			return menu;
		};

		var createCleaningMenu = function () {
			var menu = menuItem('web-topMenu-cleaningMenu', 'Renhold', null, 'zmdi-washing-machine');
			if (canRead(repository.commonService.prefix.Cleaning) && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaning', 'Renhold', 'cleaning.list', 'zmdi-flower zmdi-hc-fw'));

			if (canRead(repository.apiData.cleaningQualityControl.prefix) && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaningQualityControl', 'INSTA-kontroll', 'cleaningQualityControl.list', 'zmdi-assignment-check zmdi-hc-fw'));

			if (canRead(repository.apiData.cleaningCompletion.prefix) && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaningCompletion', 'Aktivitetsoversikt', 'cleaningCompletion.list', 'zmdi-folder-person zmdi-hc-fw'));

			if (canRead(repository.apiData.cleaningCompletionHistory.prefix) && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaningCompletion-overview', 'Historikk', 'cleaningCompletion.overview', 'zmdi-collection-text zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Drawing) && hasModule.area && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaning-drawing', 'Tegninger', 'cleaning.dwg.list', 'zmdi-image zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.CleaningTask) && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaningTask', 'Oppgaveadministrasjon', 'cleaningTask.list', 'zmdi-assignment zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.Area) && hasModule.area && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaning-area', 'Rom', 'cleaning.area.list', 'zmdi-layers zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.ResourceGroup) && hasModule.activity && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaning-resourceGroup', 'Renholdsteam', 'cleaning.resourceGroup.list', 'zmdi-accounts-list-alt zmdi-hc-fw'));

			if (canRead(repository.commonService.prefix.CleaningQuality) && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaningQuality', 'Renholdskvalitet', 'cleaningQuality.list', 'zmdi-badge-check zmdi-hc-fw'));

			return menu;
		};

		var createBasicDataAdminMenu = function () {
			var menu = menuItem('web-topMenu-basicDataAdministration', 'Basisdata Grunn', null, 'zmdi-view-carousel zmdi-hc-fw');
			if (!$scope.userInfo.isSystemAdministrator) return menu;

			if (canRead(repository.commonService.prefix.EquipmentCategory) && $localStorage.generalOptions.EditCategoriesInWeb)
				menu.children.push(menuItem('web-topMenu-equipmentCategory', 'Anleggskategori', 'equipmentCategory.list'));

			if (canRead(repository.commonService.prefix.ComponentCategory) && $localStorage.generalOptions.EditCategoriesInWeb)
				menu.children.push(menuItem('web-topMenu-componentCategory', 'Komponentkategori', 'componentCategory.list'));

			if (canRead(repository.commonService.prefix.EquipmentOperatingHourType))
				menu.children.push(menuItem('web-topMenu-equipmentOperatingHourType', 'Anlegg registreringstype', 'equipmentOperatingHourType.list'));

			if (canRead(repository.commonService.prefix.AreaCategory) && $localStorage.generalOptions.EditCategoriesInWeb)
				menu.children.push(menuItem('web-topMenu-areaCategory', 'Arealkategori', 'areaCategory.list'));

			if (canRead(repository.commonService.prefix.Department) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-department', 'Avdeling', 'department.list'));

			if (canRead(repository.commonService.prefix.BuildingCategory) && $localStorage.generalOptions.EditCategoriesInWeb)
				menu.children.push(menuItem('web-topMenu-buildingCategory', 'Byggkategori', 'buildingCategory.list'));

			if (canRead(repository.commonService.prefix.CustomerLineOfBusiness) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-customerLineOfBusiness', 'Bransje(kunde)', 'customerLineOfBusiness.list'));

			if (canRead(repository.commonService.prefix.AccountingDimension) && hasModule.core && repository.commonService.getFilterData().selectedDataOwner.Dimension1 && repository.commonService.getFilterData().selectedDataOwner.Dimension1.length > 0)
				menu.children.push(menuItem('web-topMenu-accounting', 'Dimensjoner', 'accounting.list'));

			if (canRead(repository.commonService.prefix.DocumentCategory) && hasModule.document)
				menu.children.push(menuItem('web-topMenu-documentCategory', 'Dokumentkategori', 'documentCategory.list'));

			if (canRead(repository.commonService.prefix.DocumentType) && hasModule.document)
				menu.children.push(menuItem('web-topMenu-documentType', 'Dokumenttype', 'documentType.list'));

			if (canRead(repository.commonService.prefix.EstateCategory) && $localStorage.generalOptions.EditCategoriesInWeb)
				menu.children.push(menuItem('web-topMenu-estateCategory', 'Eiendomskategori', 'estateCategory.list'));

			if (canRead(repository.commonService.prefix.Account) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-account', 'Kontoer', 'account.list'));

			if (canRead(repository.commonService.prefix.CostCenter) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-costCenter', 'Koststeder', 'costCenter.list'));

			if (canRead(repository.commonService.prefix.CustomerGroup) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-customerGroup', 'Kundegruppe', 'customerGroup.list'));

			if (canRead(repository.commonService.prefix.CustomerCategory) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-customerCategory', 'Kundekategori', 'customerCategory.list'));

			if (canRead(repository.commonService.prefix.NamedSelection) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-dropdowns', 'Nedtrekksfelter', 'namedSelection.list'));

			if (canRead(repository.commonService.prefix.Region) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-region', 'Områder', 'region.list'));

			if (canRead(repository.commonService.prefix.PersonRole) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-personRole', 'Personroller', 'personRole.list'));

			if (canRead(repository.commonService.prefix.ReferenceType) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-referenceType', 'Referanser', 'referenceType.list'));

			menu.children = _.sortBy(menu.children, "title");

			return menu;
		};

		var createTemplatesMenu = function () {
			var menu = menuItem('web-topMenu-templates', 'Maler', null, 'zmdi-hc-fw zmdi-tab-unselected');
			if (!$scope.userInfo.isSystemAdministrator) return menu;

			if (canRead(repository.commonService.prefix.Building) && hasModule.core && $localStorage.generalOptions.UseBuildingTemplates)
				menu.children.push(menuItem('web-topMenu-buildingTemplate', 'Byggmal', 'buildingTemplate.list'));
			if (canRead(repository.commonService.prefix.PeriodicTask) && hasModule.activity && $localStorage.generalOptions.UseBuildingTemplates)
				menu.children.push(menuItem('web-topMenu-periodicTaskTemplate', 'Periodiske malrutiner', 'periodicTaskTemplate.list'));

			if (canRead(repository.commonService.prefix.Project) && hasModule.projectManagement)
				menu.children.push(menuItem('web-topMenu-projectTemplate', 'Prosjektmaler', 'projectTemplate.list'));

			if (canRead(repository.commonService.prefix.EmailTemplate) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-emailTemplate', 'Epost-maler', 'emailTemplate.list'));

			if (canRead(repository.commonService.prefix.ControlList) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-controlList', 'Kontrollister', 'controlList.list'));

			if (canRead(repository.commonService.prefix.PeriodicTask) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-workOrderTemplate', 'Aktivitetsmaler', 'workOrderTemplate.list'));

			return menu;
		};

		var createBasicFvdAdminMenu = function () {
			var menu = menuItem('web-topMenu-basicFDV', 'Basisdata FDV', null, 'zmdi-receipt zmdi-hc-fw');
			if (!$scope.userInfo.isSystemAdministrator) return menu;

			if (canRead(repository.commonService.prefix.ActivityCategory) && hasModule.activity && $localStorage.generalOptions.EditCategoriesInWeb)
				menu.children.push(menuItem('web-topMenu-activityCategory', 'Aktivitetskategori', 'activityCategory.list'));

			if (canRead(repository.commonService.prefix.ActivityGroup) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-activityGroup', 'Aktivitetsgrupper', 'activityGroup.list'));

			if (canRead(repository.commonService.prefix.Deviation) && hasModule.deviation)
				menu.children.push(menuItem('web-topMenu-deviationType', 'Avvikstype', 'deviationType.list'));

			if (canRead(repository.commonService.prefix.PurchaseOrderForm) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-purchaseOrderForm', 'Bestillingsskjema', 'purchaseOrderForm.list'));

			if (canRead(repository.commonService.prefix.PaymentTerm) && (hasModule.activity || hasModule.rent || hasModule.internalRent || hasModule.housing || hasModule.invoice))
				menu.children.push(menuItem('web-topMenu-paymentTerm', 'Betalingsbetingelser', 'paymentTerm.list'));

			if (canRead(repository.commonService.prefix.PaymentOrderForm) && hasModule.invoice)
				menu.children.push(menuItem('web-topMenu-paymentOrderForm', 'Fakturatyper', 'paymentOrderForm.list'));

			if (canRead(repository.commonService.prefix.InvoicingTerm) && hasModule.invoice)
				menu.children.push(menuItem('web-topMenu-invoicingTerm', 'Fakturering', 'invoicingTerm.list'));

			if (canRead(repository.commonService.prefix.ContractCategory) && hasModule.invoice)
				menu.children.push(menuItem('web-topMenu-contractCategory', 'Kontrakt kategori', 'contractCategory.list'));

			if (canRead(repository.commonService.prefix.ContractType) && hasModule.invoice)
				menu.children.push(menuItem('web-topMenu-contractType', 'Kontrakt type', 'contractType.list'));

			if (canRead(repository.commonService.prefix.Consequence) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-consequence', 'Konsekvens', 'consequence.list'));

			if (canRead(repository.commonService.prefix.ConsequenceType) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-consequenceType', 'Konsekvenstype', 'consequenceType.list'));

			if (canRead(repository.commonService.prefix.Priority) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-priority', 'Prioritet', 'priority.list'));

			if (canRead(repository.commonService.prefix.ProjectCategory) && hasModule.projectManagement)
				menu.children.push(menuItem('web-topMenu-projectCategory', 'Prosjektkategori', 'projectCategory.list'));

			if (canRead(repository.commonService.prefix.ProjectType) && hasModule.projectManagement)
				menu.children.push(menuItem('web-topMenu-projectType', 'Prosjekttype', 'projectType.list'));

			if (canRead(repository.commonService.prefix.ResourceGroup) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-resourceGroup', 'Ressursgrupper', 'resourceGroup.list'));

			if (canRead(repository.commonService.prefix.StandardText) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-standardText', 'Standardtekster', 'standardText.list'));

			if (canRead(repository.commonService.prefix.CylinderType) && hasModule.key)
				menu.children.push(menuItem('web-topMenu-cylinderType', 'Sylindertype', 'cylinderType.list'));

			if (canRead(repository.commonService.prefix.ConditionType) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-conditionType', 'Tilstandstyper', 'conditionType.list'));

			if (canRead(repository.commonService.prefix.HourType) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-hourType', 'Time typer', 'hourType.list'));

			if (canRead(repository.commonService.prefix.Cause) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-cause', 'Årsak', 'cause.list'));

			menu.children = _.sortBy(menu.children, "title");

			return menu;
		};

		var createUserAdministrationMenu = function () {
			var menu = menuItem('web-topMenu-userAdministration', 'Brukeradministrasjon', null, 'zmdi-accounts zmdi-hc-fw');
			if (!$scope.userInfo.isUserAdministrator) return menu;

			if (canRead(repository.commonService.prefix.ApiClient) && hasModule.api)
				menu.children.push(menuItem('web-topMenu-apiClient', 'API klienter', 'apiClient.list'));

			if (canRead(repository.commonService.prefix.User) && hasModule.core) {
				menu.children.push(menuItem('web-topMenu-user', 'Brukere', 'user.list'));
				menu.children.push(menuItem('web-topMenu-usergroup', 'Brukergrupper', 'usergroup.list'));
			}

			menu.children = _.sortBy(menu.children, function (ch) { return ch.title.toLowerCase(); });

			return menu;
		};

		var createUserAdministrationExperienceMenu = function () {
			var menu = menuItem('web-topMenu-userAdministrationExperience', 'Brukeropplevelse', null, 'zmdi-view-quilt zmdi-hc-fw');
			if (!$scope.userInfo.isUserAdministrator) return menu;

			if (canRead(repository.commonService.prefix.WebDashboard))
				menu.children.push(menuItem('web-topMenu-dashboard', 'Dashbord', 'dashboard.list'));

			if (canRead(repository.commonService.prefix.MobileMenuProfile) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-mobileMenuProfile', 'Mobilmenyprofiler', 'mobileMenuProfile.list'));

			if (canRead(repository.commonService.prefix.EntityLink) && hasModule.entityLink)
				menu.children.push(menuItem('web-topMenu-entityLink', 'Navigasjonslenker', 'entityLink.list'));

			if (canRead(repository.commonService.prefix.BuildingSelection) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-buildingSelection', 'Utvalg', 'buildingSelection.list'));

			if (canRead(repository.commonService.prefix.WebMenu) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-webMenu', 'Webmenyer', 'webMenu.list'));

			if (canRead(repository.commonService.prefix.WebProfile) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-webProfile', 'Webprofiler', 'webProfile.list'));

			if (hasModule.brand)
				menu.children.push(menuItem('web-topMenu-bradning', 'Administrasjon av merkevare', 'branding'));

			menu.children = _.sortBy(menu.children, function (ch) { return ch.title.toLowerCase(); });

			return menu;
		};

		var createSystemOperationsMenu = function () {
			var menu = menuItem('web-topMenu-systemOperation', 'Systemdrift', null, 'zmdi-notifications-none zmdi-hc-fw');
			if (!$scope.userInfo.isSystemAdministrator) return menu;

			if (canRead(repository.commonService.prefix.ApiRequestLog) && hasModule.api)
				menu.children.push(menuItem('web-topMenu-apiRequestLog', 'API log', 'apiRequestLog.list'));

			var showAnonymizationDashboard = (canRead(repository.commonService.prefix.Area) && hasModule.housing) ||
				(canRead(repository.commonService.prefix.Customer) && hasModule.housing) ||
				(canRead(repository.commonService.prefix.Contract) && (hasModule.internalRent || hasModule.rent || hasModule.invoice)) ||
				(canRead(repository.commonService.prefix.Contract) && hasModule.housing && $localStorage.generalOptions.ShowContractHousingInWeb);

			if (showAnonymizationDashboard)
				menu.children.push(menuItem('web-topMenu-anonymization', 'Anonymisering av leietakere', 'customer.anonymizationDashboard'));

			if (canRead(repository.commonService.prefix.Document) && hasModule.document)
				menu.children.push(menuItem('web-topMenu-documentArchiveList', 'Arkivbehandling', 'documentXEntity.archiveList'));

			if (canRead(repository.commonService.prefix.ScheduledJob))
				menu.children.push(menuItem('web-topMenu-scheduledJobs', 'Bakgrunnsjobber', 'scheduledJob.list'));

			menu.children.push(menuItem('web-topMenu-cacheInfo', 'Cacheoversikt', 'cacheInfo.list'));

			if (canRead(repository.commonService.prefix.EntityTask) && hasModule.entityLink)
				menu.children.push(menuItem('web-topMenu-entityTask', 'Handlinger', 'entityTask.list'));

			if (canRead(repository.commonService.prefix.WebUserToken) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-loggedInUsers', 'Innloggede brukere', 'webUserToken.list'));

			if (canRead(repository.commonService.prefix.IntegrationData))
				menu.children.push(menuItem('web-topMenu-integrationData', 'Integrasjonsdata', 'integrationData.list'));

			menu.children.push(menuItem('web-topMenu-log', 'Logg', 'log.list'));

			if (canRead(repository.commonService.prefix.AlarmLog) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-alarmLog', 'Sendte elementer', 'alarmLog.list'));

			if (canRead(repository.commonService.prefix.DataImport) && (hasModule.dataAcquisition || hasModule.dataImport))
				menu.children.push(menuItem('web-topMenu-dataImport', 'Dataimport', 'dataImport.list'));

			menu.children = _.sortBy(menu.children, function (ch) { return ch.title.toLowerCase(); });

			return menu;
		};

		var createSystemConfigurationAndLicenseMenu = function () {
			var menu = menuItem('web-topMenu-systemConfigurationAndLicense', 'Systemkonfigurasjon', null, 'zmdi-settings zmdi-hc-fw');
			if (!$scope.userInfo.isSystemAdministrator) return menu;

			if (canRead(repository.commonService.prefix.FieldInfoOverride))
				menu.children.push(menuItem('web-topMenu-registeredFields', 'Definering av felter', 'registeredFields'));

			if (canRead(repository.commonService.prefix.DataOwner))
				menu.children.push(menuItem('web-topMenu-dataOwner', 'Klienter', 'dataOwner.list'));

			if (canRead(repository.commonService.prefix.GeneralOptions) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-generalOptions', 'Kundeprofil', 'generalOptions'));

			if (canRead(repository.commonService.prefix.LanguageXWebText))
				menu.children.push(menuItem('web-topMenu-translation', 'Oversettelser', 'translation.list'));

			if (canRead(repository.commonService.prefix.Report))
				menu.children.push(menuItem('web-topMenu-report', 'Rapporter', 'report.list'));

			menu.children.push(menuItem('web-topMenu-planiaSystem', 'System- og lisensinformasjon', 'planiasystem.ps'));

			menu.children = _.sortBy(menu.children, function (ch) { return ch.title.toLowerCase(); });

			return menu;
		};

		var createDataInsightMenu = function () {

			var menu = menuItem('web-topMenu-dataInsight', 'Innsikt', null, 'zmdi-graduation-cap zmdi-hc-fw');
			if (!$scope.userInfo.isSystemAdministrator || !hasModule.elhub) return menu;

			if (canRead(repository.commonService.prefix.EquipmentOperatingHours))
				menu.children.push(menuItem('web-topMenu-equipmentOperatingHoursDashboard', 'Forbruk (driftstimer)', 'equipmentOperatingHours.dashboard'));

			menu.children = _.sortBy(menu.children, function (ch) { return ch.title.toLowerCase(); });

			return menu;
		};

		var createMapMenu = function () {
			var menu = menuItem('web-topMenu-map', 'Kart', null, 'zmdi-map');

			if (hasModule.map)
				menu.children.push(menuItem('web-topMenu-map', 'Vis kart', 'map.view', 'zmdi-pin zmdi-hc-fw'));

			return menu;
		};

		var createAdminMenu = function () {
			var menu = menuItem('web-topMenu-admin', 'Admin', null, 'zmdi-settings');

			var basicDataAdminMenu = createBasicDataAdminMenu();
			if (basicDataAdminMenu.children.length) menu.children.push(basicDataAdminMenu);

			var basicFvdAdminMenu = createBasicFvdAdminMenu();
			if (basicFvdAdminMenu.children.length) menu.children.push(basicFvdAdminMenu);

			var userAdministrationMenu = createUserAdministrationMenu();
			if (userAdministrationMenu.children.length) menu.children.push(userAdministrationMenu);

			var userAdministrationExperienceMenu = createUserAdministrationExperienceMenu();
			if (userAdministrationExperienceMenu.children.length) menu.children.push(userAdministrationExperienceMenu);

			var templatesAdminMenu = createTemplatesMenu();
			if (templatesAdminMenu.children.length) menu.children.push(templatesAdminMenu);

			var systemOperationsMenu = createSystemOperationsMenu();
			if (systemOperationsMenu.children.length) menu.children.push(systemOperationsMenu);

			var systemConfigurationAndLicenseMenu = createSystemConfigurationAndLicenseMenu();
			if (systemConfigurationAndLicenseMenu.children.length) menu.children.push(systemConfigurationAndLicenseMenu);

			var dataInsightMenu = createDataInsightMenu();
			if (dataInsightMenu.children.length) menu.children.push(dataInsightMenu);

			return menu;
		};

		// Recreate menu for each update
		var updateMenu = function () {
			index = 0;
			var mainMenu = [];
			var basicMenu = createBasicMenu();
			var maintenanceMenu = createMaintenanceMenu();
			var managementMenu = createManagementMenu();
			var cleaningMenu = createCleaningMenu();
			var mapMenu = createMapMenu();
			var adminMenu = createAdminMenu();

			if (basicMenu.children.length)
				mainMenu.push(basicMenu);
			if (maintenanceMenu.children.length)
				mainMenu.push(maintenanceMenu);
			if (managementMenu.children.length)
				mainMenu.push(managementMenu);
			if (cleaningMenu.children.length)
				mainMenu.push(cleaningMenu);
			if (mapMenu.children.length)
				mainMenu.push(mapMenu);
			if (($scope.userInfo.isSystemAdministrator || $scope.userInfo.isUserAdministrator) && adminMenu.children.length)
				mainMenu.push(adminMenu);

			$scope.mainMenu = mainMenu;
		};

		updateMenu();

		$scope.clickSidebarItem = function (event, menuItem) {
			if ($scope.mainctrl && $scope.mainctrl.sidebarStat)
				$scope.mainctrl.sidebarStat(event);

			var state = _.find(states.list, function (state) {
				return state.name === menuItem.sref;
			});
			if (state && state.params && state.params.entityData && state.params.entityData.prefix) {
				var prefix = state.params.entityData.prefix;
				if ($localStorage.listParams && $localStorage.listParams[prefix])
					delete $localStorage.listParams[prefix].page;
			}
		};

		$scope.$on($scope.events.updateMenu, function () {
			$scope.guidDataOwner = commonService.getFilterData().selectedDataOwner.Guid;
			$scope.hasImageLogo = !!$localStorage.generalOptions.GuidImageLogo || commonService.getFilterData().selectedDataOwner.GuidImageLogo;
			updateAccess();
		});
	}
})();
