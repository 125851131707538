//This directive displays a table of documents and a add button for adding to specified document_x_object with correct column data.
(function () {

	var app = angular.module('Plania');

	app.directive('document', function () {
		return {
			restrict: 'E',
			scope: {
				isCollapsed: '=isCollapsed',
				reload: '=reload',
				documents: '=documents',
				entityType: '=entityType',
				defaultCategory: '=defaultCategory',
				disableActions: '=disableActions',
				totalCount: '=totalCount',
				guidArea: '=guidArea',
				guidBuilding: '=guidBuilding',
				guidCleaningTask: '=guidCleaningTask',
				guidComponent: '=guidComponent',
				guidCustomer: '=guidCustomer',
				guidEquipment: '=guidEquipment',
				guidEstate: '=guidEstate',
				guidPeriodicTask: '=guidPeriodicTask',
				guidPurchaseOrder: '=guidPurchaseOrder',
				guidRequest: '=guidRequest',
				guidWorkOrder: '=guidWorkOrder',
				guidDeviation: '=guidDeviation',
				guidCondition: '=guidCondition',
				guidSupplier: '=guidSupplier',
				guidCost: '=guidCost',
				guidPaymentOrder: '=guidPaymentOrder',
				guidContract: '=guidContract',
				printView: '=print',
				useList: '=useList', //Set to true if using directive in small width container
				inline: '=inline' //Set to true if used inline in modal
			},
			controller: ['$scope', '$modal', 'ngTableParams', 'Repository', 'TranslationService','DocumentService', '$rootScope', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/common/directives/views/DocumentDirective.html'
		};
	});

	function controller($scope, $modal, ngTableParams, repository, translationService,documentService, $rootScope) {
		$scope.documentsIsLoading = true;
		$scope.search = {};
		$scope.canChangeDocumentArchiveStatus = $rootScope.userInfo.canChangeDocumentArchiveStatus;
		$scope.enableWebNavigation = repository.enableWebNavigation;
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.$watch('reload', function (newValue, oldValue) {
			//if (newValue === oldValue) return;

			if ($scope.reload && $scope.reload === true) {
				$scope.documentTable.filter().GuidArea = $scope.guidArea;
				$scope.documentTable.filter().GuidCleaningTask = $scope.guidCleaningTask;
				$scope.documentTable.filter().GuidComponent = $scope.guidComponent;
				$scope.documentTable.filter().GuidCustomer = $scope.guidCustomer;
				$scope.documentTable.filter().GuidEquipment = $scope.guidEquipment;
				$scope.documentTable.filter().GuidEstateDocuments = $scope.guidEstate;
				$scope.documentTable.filter().GuidPeriodicTask = $scope.guidPeriodicTask;
				$scope.documentTable.filter().GuidPurchaseOrder = $scope.guidPurchaseOrder;
				$scope.documentTable.filter().GuidRequest = $scope.guidRequest;
				$scope.documentTable.filter().GuidWorkOrder = $scope.guidWorkOrder;
				$scope.documentTable.filter().GuidDeviation = $scope.guidDeviation;
				$scope.documentTable.filter().GuidCondition = $scope.guidCondition;
				$scope.documentTable.filter().GuidBuildingDocuments = $scope.guidBuilding;
				$scope.documentTable.filter().GuidSupplier = $scope.guidSupplier;
				$scope.documentTable.filter().GuidCost = $scope.guidCost;
				$scope.documentTable.filter().GuidPaymentOrder = $scope.guidPaymentOrder;
				$scope.documentTable.filter().GuidContract = $scope.guidContract;
				$scope.documentTable.filter().EntityType = $scope.entityType;
				$scope.reload = false;
			}
		});

		$scope.sortingProperty = 'Document.Description';
		$scope.sortingDirection = 'asc';

		$scope.sortingProperties = [
			{ value: 'Document.Description', label: translationService.translate('web-document-description', 'Beskrivelse') },
			{ value: 'Document.DocumentCategory.Description', label: 'Kategori' },
			{ value: 'Document.DocumentType.Id', label: 'Type' },
			{ value: 'Document.Supplier.Description', label: 'Leverandør' },
		];

		$scope.setSorting = function (item) {
			if ($scope.sortingProperty === item.value) {
				$scope.sortingDirection = $scope.sortingDirection === 'desc' ? 'asc' : 'desc';
			} else {
				$scope.sorting = {};
				$scope.sortingProperty = item.value;
				$scope.sortingDirection = 'desc';
			}

			$scope.documentTable.sorting($scope.sortingProperty, $scope.sortingDirection);
		};

		$scope.columns = [
			{ Position: 1, Title: translationService.translate('web-document-id'), Property: 'Document.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-document-description'), Property: 'Document.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-document-filename'), Property: 'Document.FilePath', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-document-documentType'), Property: 'Document.DocumentType.Id', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-document-documentCategory'), Property: 'Document.DocumentCategory.Description', PropertyType: 'string' },
			{ Position: 6, Title: translationService.translate('web-document-supplier'), Property: 'Document.Supplier.Description', PropertyType: 'string' }
		];

		if ($rootScope.hasModule('DataAcquisition')) {
			$scope.columns.push({
				Position: 7,
				Title: translationService.translate('web-document-dataAcquisition'),
				Property: 'DataAcquisitionStatus',
				PropertyType: 'enum.DataAcquisitionStatus'
			});
		}

		var countArray = [];
		var countNumber = 1000;
		if ($scope.printView === undefined) {
			countArray = [10, 20, 50];
			countNumber = 10;
		}

		if ($scope.useList) countArray = [];

		$scope.documentTable = new ngTableParams({
			page: 1,
			count: countNumber,
			sorting: { 'Document.Description': 'asc' }
		}, {
			total: 0,
			counts: countArray,
			filterDelay: 50,
			paginationMaxBlocks: $scope.useList ? 6 : 8,
			getData: function ($defer, params) {
				if (!params.filter().GuidArea &&
					!params.filter().GuidBuildingDocuments &&
					!params.filter().GuidCleaningTask &&
					!params.filter().GuidComponent &&
					!params.filter().GuidCustomer &&
					!params.filter().GuidEquipment &&
					!params.filter().GuidEstateDocuments &&
					!params.filter().GuidPeriodicTask &&
					!params.filter().GuidPurchaseOrder &&
					!params.filter().GuidRequest &&
					!params.filter().GuidWorkOrder &&
					!params.filter().GuidDeviation &&
					!params.filter().GuidCondition &&
					!params.filter().GuidSupplier &&
					!params.filter().GuidCost &&
					!params.filter().GuidPaymentOrder &&
					!params.filter().GuidContract
				)
					return;
				$scope.documentsIsLoading = true;

				var columns = [];
				$scope.columns.forEach(function (col) {
					columns.push(col.Property);
				});

				columns.push('ArchiveType', 'ArchiveStatus', 'Document.DocumentCategory.IsArchivable');

				repository.GetPaginated(repository.apiData.documentXEntity.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
					.then(function (result) {
						$scope.documentTable.settings().total = result.TotalCount;
						$scope.totalCount = result.TotalCount;
						$scope.documentTable.settings().filterDelay = 500;

						if (!$scope.documents)
							$scope.documents = [];

						if ($scope.documents) {
							while ($scope.documents.length > 0) {
								$scope.documents.pop();
							}
						}

						result.List.forEach(function (document) {
							$scope.documents.push(document);
						});

						$defer.resolve(result.List);
						$scope.documentsIsLoading = false;
						$scope.enableWebNavigation = repository.enableWebNavigation;
					},
						function (error) {
							repository.growl(error, 'danger');
						});
			}
		});

		$scope.getPropertyValue = function (row, column) {
			var properties = column.Property.split('.');
			var temp = row;

			properties.forEach(function (prop) {
				if (temp) {
					temp = temp[prop];
				}
			});

			if (column.PropertyType === 'date' && temp) {
				var date = new Date(temp);
				return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
			}

			return temp;
		};

		var resolveParams = function () {
			return {
				guidArea: $scope.guidArea,
				guidBuilding: $scope.guidBuilding,
				guidCleaningTask: $scope.guidCleaningTask,
				guidComponent: $scope.guidComponent,
				guidCustomer: $scope.guidCustomer,
				guidEquipment: $scope.guidEquipment,
				guidEstate: $scope.guidEstate,
				guidPeriodicTask: $scope.guidPeriodicTask,
				guidPurchaseOrder: $scope.guidPurchaseOrder,
				guidRequest: $scope.guidRequest,
				guidWorkOrder: $scope.guidWorkOrder,
				guidDeviation: $scope.guidDeviation,
				guidCondition: $scope.guidCondition,
				guidSupplier: $scope.guidSupplier,
				guidCost: $scope.guidCost,
				guidPaymentOrder: $scope.guidPaymentOrder,
				guidContract: $scope.guidContract,
				defaultCategory: $scope.defaultCategory
			};
		};

		$scope.addNewDocument = function () {
			$scope.isCollapsed = false;
			$modal.open({
				templateUrl: 'app/document/views/createDocumentModal.html',
				controller: 'CreateDocumentXEntityController',
				size: 'lg',
				resolve: {
					params: resolveParams
				}
			}).result.then(function () {
				$scope.documentTable.reload();
				$scope.isCollapsed = false;
			}, function (msg) {
				$scope.isCollapsed = false;
			});
		};

		$scope.addExistingDocument = function () {
			$scope.isCollapsed = false;
			$modal.open({
				templateUrl: 'app/document/views/addExistingDocument.html',
				controller: 'AddExistingDocumentController',
				resolve: {
					params: resolveParams
				}
			}).result.then(function () {
				$scope.documentTable.reload();
				$scope.isCollapsed = false;
			}, function (msg) {
				//cancel
				$scope.isCollapsed = false;
			});
		};

		$scope.removeDocument = function (document) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-document-message', "Dokumentet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-document-button-confirm', 'Ja, fjern dokumentet'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.documentXEntity.url, document.Guid).then(function (result) {
					swal(translationService.translate('web-swal-document-success', 'Dokumentet ble fjernet!'), result, "success");
					repository.growl('Dokumentet har blitt fjernet', 'success');
					$scope.documentTable.reload();
				});
			});
		};

		$scope.editDocument = function (document) {
			$modal.open({
				templateUrl: 'app/document/views/editDocumentMetadataModal.html',
				size: 'lg',
				controller: 'EditDocumentController',
				resolve: {
					$stateParams: function () { return { guid: document.GuidDocument, isModal: true }; } //feil guid ???
				}
			}).result.then(function () {
				$scope.documentTable.reload();
				$scope.isCollapsed = false;
			}, function (reloadTable) {
				//cancel
				if (reloadTable) {
					$scope.documentTable.reload();
				}
				$scope.isCollapsed = false;
			});
		};

		$scope.goToMainEditPage = function (document) {
			$rootScope.navigation.go('document.edit', { guid: document.Document.Guid });
		};

		$scope.openDocument = function (document) {
			documentService.openDocument(document.Document).then(function(result){
				$scope.isCollapsed = false;
			});
		};

		$scope.setDataAcquisitionStatus = function (document, status) {
			swal({
					title: translationService.translate('web-swal-document-setStatus-title',
						'Vil du sette datafangst status til ' +
						translationService.translate('web-enum-DataAcquisitionStatus-' + status, status) +
						'?'),
					text: translationService.translate('web-swal-equipment-approval-message',
						'Status vil bli til ' +
						translationService.translate('web-enum-DataAcquisitionStatus-' + status, status) +
						"!"),
					type: "info",
					showCancelButton: true,
					confirmButtonColor: "#337ab7",
					confirmButtonText: translationService.translate('web-swal-equipment-button-confirm-set-dataAcquisitionStatus', 'Ja, endre status!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: true
				},
				function () {
					window.onkeydown = null;
					window.onfocus = null;

					var postModel = angular.copy(document);
					postModel.RequestAction = 'setDataAcquisitionStatus';
					postModel.RequestActionParameter1 = status;
					var success = function (result) {
						$scope.documentTable.reload();
						repository.growl(translationService.translate('web-document-dataAcquisition-status-updated', 'Status har blitt satt!'), 'success');
					};
					var updateError = function (result) {
						repository.growl(result, 'danger');
					};
					repository.updateSingleDictionary(repository.apiData.documentXEntity.url, postModel)
						.then(success, updateError);
				});
		};
		$scope.setArchiveStatus = function (documentXEntity, archiveStatus) {
			var properties = {
				ArchiveStatus: archiveStatus
			};

			repository.patch(repository.apiData.documentXEntity.url, documentXEntity.Guid, JSON.stringify(properties)).then(function (result) {
				$scope.documentTable.reload();
				repository.growl('Arkivstatus oppdatert', 'success');
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};
	}
})();
