(function () {
	var app = angular.module('Plania');

	app.directive('plDrawingWidget', ['TranslationService', function (translationService) {
		return {
			restrict: 'A',
			require: '^plDashboardGrid',
			scope: {
				widget: '=widget',
				edit: '=edit',
				saveFunction: '=saveFunction',
				shouldStopInterval: '=shouldStopInterval'
			},
			link: function (scope, element, attrs, gridCtrl) {
				scope.$parent.attachWidget(element, attrs);

				scope.removeWidget = function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-dashboard-drawing-message', "Tegningene vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-dashboard-drawing-confirm', 'Ja, fjern tegningene'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-dashboard-drawing-success', 'Tegningene ble fjernet!'), '', "success");
						scope.$parent.removeWidget(element, attrs);
					});
				};
			},
			templateUrl: 'app/dashboard/directives/widgets/drawingWidget.html',
			controller: ['$scope', 'TranslationService', 'Repository', '$modal', '$interval', 'DashboardSharedDataService', function ($scope, translationService, repository, modal, $interval, sharedDataService) {
				$scope.model = $scope.widget;
				$scope.model.fullscreen = false;
				$scope.showNetAreal = true;
				$scope.selectedTheme = 'cleaning';
				$scope.isStaticTheme = true;
				$scope.update = false;

				if (typeof ($scope.model.WidgetData) === "string") {
					$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
				}

				var guidDrawings = _.map($scope.model.WidgetData.Drawings, 'Guid');

				sharedDataService.setDrawingGuids(guidDrawings);

				//serviceUrl, page, pageSize, sorting, filter, includeGuid, columns
				var filter = { PropertyFilter: [{ Property: 'GuidDrawing', Operator: 'in', value: guidDrawings.join(',') }] };
				var columns = ['Id'];
				var areas = [];
				var pageSize = 100;
				var page = 0;
				var getAreas = function () {
					repository.GetPaginated(repository.apiData.area.url, page, pageSize, {}, filter, null, JSON.stringify(columns)).then(function (result) {
						areas = areas.concat(result.List);
						if (result.TotalCount > areas.length) {
							page++;
							getAreas();
						}
					});
				};

				getAreas();

				$scope.model.selectedDrawing = $scope.model.WidgetData.Drawings[0];

				$scope.$on('dashboard.activateItem', function (evt, data) {
					if (!data.GuidArea) return;
					var area = _.find(areas, { Guid: data.GuidArea });
					if (!area) //selected request is not in a area connected to any of the drawings
						return;
					var drawing = _.find($scope.model.WidgetData.Drawings, { Guid: area.GuidDrawing });
					if (!drawing) return;
					$scope.model.selectedDrawing = drawing;
					$scope.model.highlightArea = data.GuidArea;
				});

				$scope.setFullscreen = function () {
					$scope.model.fullscreen = !$scope.model.fullscreen;
				};

				$scope.editWidget = function () {
					modal.open({
						controller: 'AddWidgetModalController',
						templateUrl: 'app/dashboard/addWidgetModal.html',
						resolve: {
							widget: function () {
								return $scope.model;
							}
						}
					}).result.then(function (widgetModel) {
						$scope.saveFunction(false);
					}, function () {
						//Dismissed
					});
				};

				$scope.updateFunction = function () {
					$scope.update = !$scope.update;
				};
			}],
		};
	}]);
})();
