(function () {
	angular.module('Plania').controller('CopyPeriodicTaskModalController', ['$scope', 'Repository', 'params', 'ngTableParams', '$filter', '$modalInstance', 'authService', 'TranslationService', 'ListService', '$localStorage', '$q', 'RegisteredFieldService', controller]);

	function controller($scope, repository, params, ngTableParams, $filter, $modalInstance, authService, translationService, listService, $localStorage, $q, registeredFieldService) {
		$scope.allRowsSelected = false;
		$scope.selectedItems = [];

		//#region Get fieldrules and set initial list selection
		$scope.mandatoryFields = {};
		params.fieldRules.forEach(function (field) {
			if (field.FieldName === 'GUID_ESTATE')
				$scope.mandatoryFields.estate = field.Mandatory;
			if (field.FieldName === 'GUID_BUILDING')
				$scope.mandatoryFields.building = field.Mandatory;
			if (field.FieldName === 'GUID_EQUIPMENT')
				$scope.mandatoryFields.equipment = field.Mandatory;
			if (field.FieldName === 'GUID_AREA')
				$scope.mandatoryFields.area = field.Mandatory;
		});

		$scope.displayMaintenaceObject = {
			estate: $scope.mandatoryFields.equipment ? false : $scope.mandatoryFields.area ? false : $scope.mandatoryFields.building ? false : true,
			building: $scope.mandatoryFields.equipment ? false : $scope.mandatoryFields.area ? false : true
		};

		$scope.listSelection = $scope.displayMaintenaceObject.building ? 'building' : 'equipment';
		//#endregion

		//#region Itemtable configuration and functions
		$scope.estateColumns = [
			{ Position: 1, Title: translationService.translate('web-estate-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-estate-description'), Property: 'Description', PropertyType: 'string' },
		];

		$scope.buildingColumns = [
			{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Description', PropertyType: 'string' },
		];

		$scope.equipmentColumns = [
			{ Position: 1, Title: translationService.translate('web-equipment-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-equipment-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'string' },
		];

		$scope.areaColumns = [
			{ Position: 1, Title: translationService.translate('web-area-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-area-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'string' },
		];

		var setListColumns = function () {
			$scope.listColumns = [];
			switch ($scope.listSelection) {
				case 'estate':
					$scope.listColumns = $scope.estateColumns;
					break;
				case 'building':
					$scope.listColumns = $scope.buildingColumns;
					break;
				case 'equipment':
					$scope.listColumns = $scope.equipmentColumns;
					break;
				case 'area':
					$scope.listColumns = $scope.areaColumns;
					break;
			}
		};

		var setFilter = function (filter) {
			if (!filter.ProprtyFilter)
				filter.PropertyFilter = [];

			switch ($scope.listSelection) {
				case 'estate':
					break;
				case 'building':
					filter.PropertyFilter.push({ Property: 'IsTemplate', Operator: '=', Value: '0' });
					break;
				case 'equipment':
					break;
				case 'area':
					break;
			}
			return filter;
		};

		var getDataColumns = function () {
			var columns = [];

			$scope.listColumns.forEach(function (col) {
				columns.push(col.Property);
			});

			switch ($scope.listSelection) {
				case 'building':
					columns.push('Estate.Id');
					columns.push('Estate.Description');
					break;

				case 'equipment':
				case 'area':
					columns.push('Building.Description');
					columns.push('Building.Estate.Id');
					columns.push('Building.Estate.Description');
					break;
			}

			return columns;
		};

		$scope.itemTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'asc' },
			filter: {}
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					setListColumns();

					var url = repository.apiData[$scope.listSelection].url;
					var filter = angular.copy(params.filter());
					setFilter(filter);
					var columns = getDataColumns();

					repository.GetPaginated(url, params.page() - 1, params.count(), params.sorting(), filter, null, JSON.stringify(columns)).then(
						function (result) {
							params.total(result.TotalCount);
							$defer.resolve(result.List);
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.getPropertyValue = function (entity, field) {
			return listService.GetPropertyValue(entity, field);
		};

		$scope.addSelectedItem = function (object) {
			if (!$scope.isItemSelected(object)) {
				$scope.selectedItems.push(object);
			} else {
				for (var i = 0; i < $scope.selectedItems.length; i++) {
					if ($scope.selectedItems[i].Guid === object.Guid) {
						$scope.selectedItems.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isItemSelected = function (object) {
			return _.find($scope.selectedItems, function (o) { return o.Guid === object.Guid; });
		};

		$scope.reloadList = function () {
			$scope.itemTable.reload();
		};

		//#endregion

		//#region Tab configuration
		$scope.tabs = [
			{ heading: translationService.translate('web-periodicTask-maintenanceObject', 'Velg vedlikehold objekt'), step: 0 },
			{ heading: translationService.translate('web-periodicTask-maintenanceObject-preview', 'Bekreft valgte'), step: 1 },
		];

		$scope.step = 0;

		$scope.isCurrentStep = function (step) {
			return $scope.step === step;
		};

		$scope.setCurrentStep = function (step) {
			if ($scope.isValid(step)) {
				$('.tab-content').css('overflow', 'hidden');
				setTimeout(function () {
					$('.tab-content').css('overflow', 'visible');
				}, 500);
				$scope.step = step;
			}
		};

		$scope.isLastStep = function () {
			return $scope.step === ($scope.tabs.length - 1);
		};

		$scope.getNextLabel = function () {
			return $scope.isLastStep() ? translationService.translate('web-button-saveAndClose', 'Lagre og lukk') : translationService.translate('web-button-next', 'Neste');
		};

		$scope.handlePrevious = function () {
			$scope.setCurrentStep($scope.step - ($scope.step === 0 ? 0 : 1));
		};

		$scope.handleNext = function () {
			if ($scope.isLastStep()) {
				$scope.copyPeriodicTask();
			} else {
				$scope.reloadTables();
				$scope.setCurrentStep($scope.step += 1);
			}
		};

		$scope.isValid = function (step) {
			var valid = true;

			switch (step) {
				case 0:
					break;
				case 1:
					if ($scope.selectedItems.length === 0)
						valid = false;
					break;
			}
			return valid;
		};
		//#endregion

		//#region Preview table configuration
		$scope.amountOfSelectedItems = function (entity) {
			if (!entity)
				return $scope.selectedItems.length;

			return $scope.selectedItems.filter(function (item) { return item.Prefix === entity; }).length;
		};

		$scope.estimateInMinutes = function() {
			var items = $scope.selectedItems.length;
			if (!items) return null;
			var estimateForEachPeriodicTaskInSeconds = 10;
			return Math.ceil(items * estimateForEachPeriodicTaskInSeconds / 60);
		};

		$scope.removeSelectedItem = function (item) {
			for (var i = 0; i < $scope.selectedItems.length; i++) {
				if ($scope.selectedItems[i].Guid === item.Guid) {
					$scope.selectedItems.splice(i, 1);
					$scope.reloadTables();
					return;
				}
			}
		};

		$scope.reloadTables = function () {
			$scope.estateTable.reload();
			$scope.buildingTable.reload();
			$scope.equipmentTable.reload();
			$scope.areaTable.reload();
		};

		$scope.estateTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'asc' },
			filter: {}
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					var rawData = $scope.selectedItems.filter(function (item) { return item.Prefix === 'Estate'; });
					params.total($scope.amountOfSelectedItems('Estate'));

					var data = params.sorting() ? $filter('orderBy')(rawData, params.orderBy()) : rawData;
					data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
					$defer.resolve(data);
				}
			});

		$scope.buildingTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'asc' },
			filter: {}
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					var rawData = $scope.selectedItems.filter(function (item) { return item.Prefix === 'Building'; });
					params.total($scope.amountOfSelectedItems('Building'));

					var data = params.sorting() ? $filter('orderBy')(rawData, params.orderBy()) : rawData;
					data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
					$defer.resolve(data);
				}
			});

		$scope.equipmentTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'asc' },
			filter: {}
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					var rawData = $scope.selectedItems.filter(function (item) { return item.Prefix === 'Equipment'; });
					params.total($scope.amountOfSelectedItems('Equipment'));

					var data = params.sorting() ? $filter('orderBy')(rawData, params.orderBy()) : rawData;
					data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
					$defer.resolve(data);
				}
			});

		$scope.areaTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'asc' },
			filter: {}
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					var rawData = $scope.selectedItems.filter(function (item) { return item.Prefix === 'Area'; });
					params.total($scope.amountOfSelectedItems('Area'));

					var data = params.sorting() ? $filter('orderBy')(rawData, params.orderBy()) : rawData;
					data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
					$defer.resolve(data);
				}
			});

		//#endregion

		$scope.copyPeriodicTask = function () {
			if ($scope.selectedItems === 0)
				return;

			var arrayOfMaintenanceObjects = [];

			// Send only required fields to reduce message size
			$scope.selectedItems.forEach(function (object) {
				var transferObject = {};
				if (object.Prefix === 'Equipment') {
					transferObject.GuidEquipment = object.Guid;
					transferObject.GuidBuilding = object.GuidBuilding;
					transferObject.GuidEstate = object.Building.Estate.Guid;
				}
				else if (object.Prefix === 'Area') {
					transferObject.GuidArea = object.Guid;
					transferObject.GuidBuilding = object.GuidBuilding;
					transferObject.GuidEstate = object.Building.Estate.Guid;
				}
				else if (object.Prefix === 'Building') {
					transferObject.GuidBuilding = object.Guid;
					transferObject.GuidEstate = object.GuidEstate;
				}
				else if (object.Prefix === 'Estate') {
					transferObject.GuidEstate = object.Guid;
				}

				arrayOfMaintenanceObjects.push(JSON.stringify(transferObject));
			});

			repository.createMultipleWithGuid(repository.apiData.periodicTask.url + 'copyPeriodicTask/', params.GuidPeriodicTask, arrayOfMaintenanceObjects).then(function (result) {
				repository.growl('Periodisk rutine har blitt kopiert', 'success');
				$modalInstance.close();
			}).catch(function (error) {
				repository.growl(error, 'danger');
			});
		};
	}
}) ();
