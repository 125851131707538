(function () {
    angular.module('Plania').controller('BuildingXBuildingSelection', ['$scope', '$modalInstance', 'params', 'Repository','ngTableParams', controller]);

    function controller($scope, $modalInstance, params, repository, ngTableParams) {
        $scope.buildingTable = new ngTableParams({
            page: 1,
            count: 10,
            sorting: { 'Id': 'asc' },
            filter: {}
        },
		{
		    total: 0,
		    counts: [10, 25, 50],
		    getData: function ($defer, params) {
		        $scope.isLoading = true;
		        repository.GetPaginated(repository.apiData.building.url, params.page() - 1, params.count(), params.sorting(), params.filter()).then(
					function (result) {
					    params.total(result.TotalCount);
					    $scope.isLoading = false;
					    $defer.resolve(result.List);
					},
					function (error) {
					    repository.growl(error, 'danger');
					});
		    }
		});


        //var selectedBuildings = [];

        //$scope.saveBuilding = function () {
        //    console.log("t:");
        //    console.log(params.guidUser);
        //    repository.createMultipleWithGuid(repository.apiData.buildingBuildingSelection.url, params.guidUser, selectedBuildings).then(function (result) {
        //        repository.growl('Byggninger har blitt lagt til utvalget', 'success');
        //        $modalInstance.close('OK');
        //    });
        //};


        //$scope.addBuilding = function (guid) {
        //    console.log("runs....");
        //    if (!_.contains(selectedBuildings, guid))
        //        selectedBuildings.push(guid);
        //    else {
        //        for (var i = 0; i < selectedBuildings.length; i++) {
        //            var p = selectedBuildings[i];
        //            if (p == guid) {
        //                selectedBuildings.splice(i, 1);
        //                return;
        //            }
        //        }
        //    }
        //};


        $scope.isBuildingSelected = function (guid) {
            return _.contains(selectedBuildings, guid);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };


    }

})();
