(function () {
	angular.module('Plania').controller('ControlListDeviationConfirmationController', ['$scope', 'Repository', 'params', 'ngTableParams', '$modalInstance', 'TranslationService', '$localStorage', controller]);

	function controller($scope, repository, params, ngTableParams, modalInstance, translationService, $localStorage) {
		$scope.deviations = params.deviations;
		$scope.selectedDeviation = $scope.deviations[0];
		$scope.selectedDeviationIndex = 0;
		$scope.inWorkOrder = params.inWorkOrder;
		$scope.reload = false;
		$scope.viewMode = false;
		$scope.activateAutocomplete = true;
		$scope.isEdit = false;
		$scope.parentEntity = 'WorkOrder';
		$scope.isPreview = params.isPreview;


		$scope.deviations.forEach(function (deviation) {
			if (deviation.GuidEquipment && !deviation.GuidBuilding) {
				var equipmentColumns = ['Building.Id', 'Building.Description', 'Building.Estate.Id', 'Building.Estate.Description', 'Building.Estate.Guid'];
				repository.getSingle(repository.apiData.equipment.url, deviation.GuidEquipment, JSON.stringify(equipmentColumns)).then(function (response) {
					var equipment = response.Data;

					deviation.GuidBuilding = equipment.GuidBuilding;
					deviation.Building = equipment.Building;
					deviation.GuidEstate = equipment.Building.Estate.Guid;
					deviation.Estate = equipment.Building.Estate;
				}, function (error) {

				});
			}
		});

		$scope.removeDeviation = function (index) {
			$scope.deviations.splice(index, 1);
			if ($scope.deviations.length > 0) $scope.selectDeviation($scope.deviations[0]);
			else if ($scope.deviations.length < 1) $scope.selectedDeviation = null;
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "building":
					if ($scope.selectedDeviation.GuidEstate) {
						filter.PropertyFilter = [{ Property: 'GuidEstate', Operator: '=', Value: $scope.selectedDeviation.GuidEstate }];
					}
					return filter;
				case "area":
					filter = { GuidBuilding: $scope.selectedDeviation.GuidBuilding ? $scope.selectedDeviation.GuidBuilding : null };
					if ($scope.selectedDeviation.GuidEstate) {
						filter.PropertyFilter = [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.selectedDeviation.GuidEstate }];
					}
					return filter;
				case "equipment":
					filter = { GuidBuilding: $scope.selectedDeviation.GuidBuilding, hasConditionControl: true, PropertyFilter: [] };
					if ($scope.selectedDeviation.GuidEstate && !$scope.selectedDeviation.GuidBuilding) {
						filter.PropertyFilter = [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.selectedDeviation.GuidEstate }];
					}

					if ($localStorage.generalOptions.FilterDeviationByEqPrefix && $scope.selectedDeviation.GuidDeviationType) {
						var id = $scope.selectedDeviation.DeviationType.Caption.substr(0, 3).trim();
						filter.PropertyFilter.push({ Property: 'Id', Operator: 'StartsWith', Value: id });
					}

					filter.PropertyFilter.push({ Property: 'IsTemplate', Operator: '=', Value: false });
					return filter;
				case "deviationType":
					if ($localStorage.generalOptions.FilterDeviationByEqPrefix && $scope.selectedDeviation.GuidEquipment) {
						return { PropertyFilter: [{ Property: 'Id', Operator: 'StartsWith', Value: $scope.selectedDeviation.Equipment.Caption.substr(0, 3).trim() }] };
					}
					return {};
				default:
					return {};
			}
		};

		$scope.selectDeviation = function (deviation, index) {
			deviation.hasBeenViewed = true;
			$scope.selectedDeviation = deviation;
			$scope.selectedDeviationIndex = index;
		};

		$scope.selectNextDeviation = function () {

			var currentDeviationIndex = $scope.selectedDeviationIndex;

			if (currentDeviationIndex + 1 === $scope.deviations.length) {
				$scope.selectDeviation($scope.deviations[0], 0);
			} else {
				$scope.selectDeviation($scope.deviations[currentDeviationIndex + 1], currentDeviationIndex + 1);
			}
		};

		$scope.hasMissingProperties = function (deviation) {
			var mandatoryFieldRules = _.filter($scope.fieldRules, { Mandatory: true });

            return _.some(mandatoryFieldRules, function (fieldRule) {
                return fieldRule.FieldName.toUpperCase() !== 'ID' && !deviation[fieldRule.DomainFieldName];
            });
		};

		$scope.onEstateSelect = function (estate) {
			if (!estate) {
				$scope.selectedDeviation.GuidEstate = "";
				$scope.selectedDeviation.Estate = "";
				return;
			}

			if ($scope.selectedDeviation.GuidEstate !== estate.Guid) {
				$scope.selectedDeviation.GuidBuilding = "";
				$scope.selectedDeviation.Building = null;
				$scope.selectedDeviation.GuidEquipment = "";
				$scope.selectedDeviation.Equipment = null;
				$scope.selectedDeviation.GuidArea = "";
				$scope.selectedDeviation.Area = null;
			}

			$scope.selectedDeviation.GuidEstate = estate.Guid;
			$scope.selectedDeviation.Estate = estate;
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.selectedDeviation.GuidBuilding = "";
				$scope.selectedDeviation.Building = null;
				return;
			}

			if ($scope.selectedDeviation.GuidBuilding !== building.Guid) {
				$scope.selectedDeviation.GuidEquipment = "";
				$scope.selectedDeviation.Equipment = null;
				$scope.selectedDeviation.GuidArea = "";
				$scope.selectedDeviation.Area = null;
			}

			$scope.selectedDeviation.GuidBuilding = building.Guid;
			$scope.selectedDeviation.Building = building;

			if (!$scope.selectedDeviation.GuidEstate || $scope.selectedDeviation.GuidEstate === "") {
				$scope.selectedDeviation.GuidEstate = building.GuidEstate;
				$scope.selectedDeviation.Estate = building.Estate;
			}
		};

		$scope.onAreaSelect = function (area) {
			if (!area) {
				$scope.selectedDeviation.GuidArea = "";
				$scope.selectedDeviation.Area = null;
				return;
			}

			$scope.selectedDeviation.GuidArea = area.Guid;
			$scope.selectedDeviation.Area = area;

			if (!$scope.selectedDeviation.GuidBuilding || $scope.selectedDeviation.GuidBuilding === "") {
				$scope.selectedDeviation.GuidBuilding = area.GuidBuilding;
				$scope.selectedDeviation.Building = area.Building;
			}

			if (!$scope.selectedDeviation.GuidEstate || $scope.selectedDeviation.GuidEstate === "") {
				$scope.selectedDeviation.GuidEstate = area.Building.Estate.Guid;
				$scope.selectedDeviation.Estate = area.Building.Estate;
			}
		};

		$scope.onEquipmentSelect = function (equipment) {
			if (!equipment) {
				$scope.selectedDeviation.GuidEquipment = "";
				$scope.selectedDeviation.Equipment = null;
				return;
			}

			$scope.selectedDeviation.GuidEquipment = equipment.Guid;
			$scope.selectedDeviation.Equipment = equipment;

			if (!$scope.selectedDeviation.GuidEstate || $scope.selectedDeviation.GuidEstate === "") {
				$scope.selectedDeviation.GuidEstate = equipment.Building.Estate.Guid;
				$scope.selectedDeviation.Estate = equipment.Building.Estate;
			}

			if (!$scope.selectedDeviation.GuidBuilding || $scope.selectedDeviation.GuidBuilding === "") {
				$scope.selectedDeviation.GuidBuilding = equipment.GuidBuilding;
				$scope.selectedDeviation.Building = equipment.Building;
			}

			if (equipment.GuidArea) {
				$scope.selectedDeviation.GuidArea = equipment.GuidArea;
				$scope.selectedDeviation.Area = equipment.Area;
			}
		};

		$scope.onDeviationTypeSelect = function (deviationType) {
			if (!deviationType) {
				$scope.selectedDeviation.GuidDeviationType = "";
				$scope.selectedDeviation.DeviationType = null;
				return;
			}

			$scope.selectedDeviation.GuidDeviationType = deviationType.Guid;
			$scope.selectedDeviation.DeviationType = deviationType;
		};

		$scope.createDeviations = function (destination) {
			if ($scope.deviations.length > 0 && !$scope.isPreview) {
				var deviations = [];
				$scope.deviations.forEach(function (deviation) {
					deviations.push(JSON.stringify(getDeviationInfo(deviation)));
				});

				repository.createMultiple(repository.apiData.deviation.url, deviations, 'returnAll=true').then(function (response) {
					var hasError = false;
					// Assumption -> Returned list is same order as the local list
					for (var i = response.length - 1; i >= 0; i--) {
						if (response[i].IsSuccess) {
							$scope.deviations.splice(i, 1);
						}
						else {
							hasError = true;
							repository.growl(response[i].Message, 'danger');
						}
					}
					if (!hasError) {
						repository.growl(translationService.translate('web-deviations-create-success', 'Avvikene ble opprettet'), 'success');
						modalInstance.close({ destination: destination, guid: params.workOrder ? params.workOrder.Guid : null });
					}
				});
			}
			else {
				modalInstance.close({ destination: destination, guid: params.workOrder ? params.workOrder.Guid : null });
			}
		};

		var getDeviationInfo = function (deviation) {
			var postItem = angular.copy(deviation);
			var postImages = [];

			if (deviation.Images && deviation.Images.length > 0) {
				deviation.Images.forEach(function (image) {
					var postImage = angular.copy(image);
					delete postImage.Guid;
					delete postImage.GuidControlListItemAnswer;
					delete postImage.ControlListItemAnswer;
					delete postImage.GuidControlListXEntity;
					delete postImage.ControlListXEntity;
					postImages.push(postImage);
				});
			}

			postItem.ImageXEntity = postImages;

			delete postItem.DeviationType;
			delete postItem.Estate;
			delete postItem.Building;
			delete postItem.Equipment;
			delete postItem.Area;
			delete postItem.Images;
			delete postItem.IsMandatory;

			return postItem;
		};

		$scope.cancel = function () {
			modalInstance.dismiss('cancel');
		};

		repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix.Deviation)
			.then(function (result) {
				$scope.fieldRules = result;
				$scope.reload = true;
			});
	}
})();
