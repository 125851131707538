(function () {
	angular.module('Plania').controller('CreateConditionForInspectionController', ['$scope', '$modalInstance', 'params', 'Repository', 'ngTableParams', 'TranslationService', "RegisteredFieldService", 'ListService', '$localStorage', controller]);

	function controller($scope, $modalInstance, params, repository, ngTableParams, translationService, registeredFieldService, listService, $localStorage) {
		var isSVV = $localStorage.generalOptions.CustomerId === '10088';
		registeredFieldService.getRegisteredField(repository.commonService.prefix.Condition).then(
			function (result) {
				var equipmentMandatory = _.find(result, { DomainFieldName: 'GuidEquipment' }).Mandatory;
				var areaMandatory = _.find(result, { DomainFieldName: 'GuidArea' }).Mandatory;
				var buildingMandatory = _.find(result, { DomainFieldName: 'GuidBuilding' }).Mandatory;

				$scope.selectedEntityType = 'Equipment';
				$scope.showSelection = true;

				if (equipmentMandatory) {
					$scope.showEquipment = true;
					$scope.showArea = false;
					$scope.showBuilding = false;
					$scope.showEstate = false;

					$scope.showSelection = false;
				}
				else if (areaMandatory) {
					$scope.showArea = true;
					$scope.showEquipment = false;
					$scope.showBuilding = false;
					$scope.showEstate = false;

					$scope.showSelection = false;
					$scope.selectedEntityType = 'Area';
				}
				else if (buildingMandatory) {
					$scope.showBuilding = true;
					$scope.showEquipment = true;
					$scope.showArea = true;
					$scope.showEstate = false;

					$scope.selectedEntityType = 'Building';
				}
				else {
					$scope.showEstate = true;
					$scope.showEquipment = true;
					$scope.showBuilding = true;
					$scope.showArea = true;
				}
			});


		$scope.equipmentColumns = [
			{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Building.Description' },
			{ Position: 3, Title: translationService.translate('web-equipment-id'), Property: 'Id' },
			{ Position: 4, Title: translationService.translate('web-equipment-description'), Property: 'Description' },
		];

		if (isSVV) {
			$scope.equipmentColumns.push({ Position: 5, Title: translationService.translate('web-equipment-text20-svv', 'Driftsmerking'), Property: 'Text20' });
		}

		$scope.areaColumns = [
			{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Building.Description' },
			{ Position: 3, Title: translationService.translate('web-area-id'), Property: 'Id' },
			{ Position: 4, Title: translationService.translate('web-area-description'), Property: 'Description' },
		];

		$scope.buildingColumns = [
			{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Description' },
		];

		$scope.estateColumns = [
			{ Position: 1, Title: translationService.translate('web-estate-id'), Property: 'Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-estate-description'), Property: 'Description' },
		];

		$scope.columns = [];

		var getFilter = function (baseFilter) {
			var filter = {
				searchString: baseFilter.searchString,
				hasConditionControl: true,
				PropertyFilter: []
			};

			if (params.guidBuilding) {
				filter.PropertyFilter.push({ Property: 'GuidBuilding', Operator: '=', Value: params.guidBuilding });
			} else if (params.guidEstate) {
				filter.PropertyFilter.push({ Property: 'Building.GuidEstate', Operator: '=', Value: params.guidEstate });
			}

			if ($scope.selectedEntityType === 'Equipment') {
				filter.PropertyFilter.push({ Property: 'IsTemplate', Operator: '=', Value: false });
			}

			return filter;
		};

		$scope.items = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'asc' },
			filter: filter
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					$scope.columns = [];

					var filter = getFilter(params.filter());
					var url = repository.apiData.equipment.url;
					var columnProperties = [];

					switch ($scope.selectedEntityType) {
						case 'Estate':
							url = repository.apiData.estate.url;
							$scope.estateColumns.forEach(function (column) {
								$scope.columns.push(column);
							});
							break;
						case 'Building':
							url = repository.apiData.building.url;
							$scope.buildingColumns.forEach(function (column) {
								$scope.columns.push(column);
							});
							break;
						case 'Area':
							url = repository.apiData.area.url;
							$scope.areaColumns.forEach(function (column) {
								$scope.columns.push(column);
							});
							columnProperties.push('Building.Estate.Guid');
							break;
						case 'Equipment':
							url = repository.apiData.equipment.url;
							$scope.equipmentColumns.forEach(function (column) {
								$scope.columns.push(column);
							});
							columnProperties.push('Building.Estate.Guid');
							break;
					}


					$scope.columns.forEach(function (col) {
						columnProperties.push(col.Property);
					});

					repository.GetPaginated(url, params.page() - 1, params.count(), params.sorting(), filter, null, JSON.stringify(columnProperties)).then(
						function (result) {
							params.total(result.TotalCount);
							$defer.resolve(result.List);
						}, function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.$watch('selectedEntityType', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			if (newValue) {
				$scope.items.reload();
			}
		});


		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};

		$scope.selectedEntities = [];

		var getEntityMapping = function (entity) {

			var newEntity = { GuidWorkOrder: params.guidWorkOrder, Type: 'Undefined' };

			if (entity.Prefix) {
				switch (entity.Prefix) {
					case 'Estate':
						newEntity.GuidEstate = entity.Guid;
						break;
					case 'Building':
						newEntity.GuidBuilding = entity.Guid;
						break;
					case 'Area':
						newEntity.GuidArea = entity.Guid;
						break;
					case 'Equipment':
						newEntity.GuidEquipment = entity.Guid;
						break;
				}
			} else {
				newEntity.GuidEquipment = entity.Guid;
			}

			if (entity.GuidEstate) newEntity.GuidEstate = entity.GuidEstate;
			if (entity.Building && entity.Building.Estate) newEntity.GuidEstate = entity.Building.Estate.Guid;
			if (entity.GuidBuilding) newEntity.GuidBuilding = entity.GuidBuilding;
			if (entity.GuidArea) newEntity.GuidArea = entity.GuidArea;
			if (entity.GuidEquipment) newEntity.GuidEquipment = entity.GuidEquipment;

			return newEntity;
		};

		$scope.saveEntities = function () {

			var model = [];

			var entityType = $scope.items.filter().type;

			$scope.selectedEntities.forEach(function (entity) {
				model.push(getEntityMapping(entity));
			});

			repository.createMultipleWithGuid(repository.apiData.condition.url, params.guidWorkOrder, JSON.stringify(model))
				.then(function (result) {
					repository.growl(translationService.translate('condition-addMultiple-success', 'Objektene har blitt lagt til'), 'success');
					$modalInstance.close('OK');
				}, function (error) {
					repository.growl(error, 'danger');
				});
		};

		$scope.addEntity = function (entity) {
			if (!_.find($scope.selectedEntities, function (o) { return entity.Guid === o.Guid; }))
				$scope.selectedEntities.push(entity);
			else {
				for (var i = 0; i < $scope.selectedEntities.length; i++) {
					if ($scope.selectedEntities[i].Guid === entity.Guid) {
						$scope.selectedEntities.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isEntitySelected = function (guid) {
			return _.find($scope.selectedEntities, function (o) { return guid === o.Guid; });
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
