(function () {
	angular.module('Plania').controller('OperationalMessageModalController', ['$scope', 'Repository', 'params', '$modalInstance', '$modal', '$localStorage', 'TranslationService', 'Constants', controller]);
	function controller($scope, repository, params, $modalInstance, $modal, $localStorage, translationService, constants) {
		// Extract parameters
		$scope.model = {};
		$scope.guid = params.guid;
		$scope.parentGuid = params.parentGuid;
		$scope.parentEntityType = params.parentEntityType;
		$scope.parentModel = params.parentModel;

		$scope.isEdit = !!$scope.guid;
		$scope.canCreateDowntime = false;
		$scope.canChangeEquipment = false;

		$scope.hstep = 1;
		$scope.mstep = 5;

		$scope.showEntityConnection = !params.guid && !params.parentEntityType;
		$scope.selections = { selectedEntityType: null };

		$scope.entities = [
			{ value: 'Estate', translation: translationService.translate('web-estate') },
			{ value: 'Building', translation: translationService.translate('web-building') },
			{ value: 'Equipment', translation: translationService.translate('web-equipment') },
			{ value: 'Area', translation: translationService.translate('web-area') },
		];

		var updateAccess = function () {
			if ($scope.isEdit) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.OperationalMessage, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.OperationalMessage, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.OperationalMessage);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.OperationalMessage);
			}
		};
		updateAccess();

		$scope.onEquipmentSelect = function (equipment) {
			if (!equipment) {
				$scope.model.GuidEquipment = "";
				return;
			}

			$scope.model.GuidEquipment = equipment.Guid;
			$scope.canCreateDowntime = equipment.CanHaveDowntime;
			if (!$scope.canCreateDowntime)
				$scope.model.Type = "Information";
			else {
				$scope.model.Type = "Downtime";
			}


			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "") {
				$scope.model.GuidEstate = equipment.GuidEstate || equipment.Building.Estate.Guid;
				
			}

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = equipment.GuidBuilding;
				
			}

		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}

			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidEquipment = "";
				
			}

			$scope.model.GuidBuilding = building.Guid;

			

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "" || $scope.model.GuidEstate !== building.GuidEstate) {
				$scope.model.GuidEstate = building.GuidEstate;
				
			}

		};

		$scope.onEstateSelect = function (estate) {
			if (!estate) {
				$scope.model.GuidEstate = "";
				return;
			}

			if ($scope.model.GuidEstate !== estate.Guid) {
				$scope.model.GuidBuilding = "";
				$scope.model.GuidEquipment = "";
			
			}

			$scope.model.GuidEstate = estate.Guid;
			
		};

		var columns = [
			'Estate.Id', 'Estate.Description',
			'Building.Id', 'Building.Description',
			'Equipment.Id', 'Equipment.Description',
			'WorkOrder.Id', 'WorkOrder.Description',
			'Person.FirstName', 'Person.LastName',
			'User.RealName', 'User1.RealName'
		];

		var getMessage = function () {
			repository.getSingle(repository.apiData.operationalMessage.url, params.guid, JSON.stringify(columns)).then(function (result) {
				$scope.model = result.Data;
				updateAccess();
				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		if (params.guid) {
			getMessage();
		} else {
			$scope.model = {};
			if ($scope.parentGuid)
				$scope.model['Guid' + $scope.parentEntityType] = $scope.parentGuid;
			$scope.model.Type = "Information";
			$scope.model.StartDate = new Date().setMinutes(0);

			if ($scope.parentEntityType === "WorkOrder") {
				$scope.canCreateDowntime = $scope.parentModel && $scope.parentModel.Equipment && $scope.parentModel.Equipment.CanHaveDowntime;
				$scope.canChangeEquipment = true;
				$scope.model.Type = "Downtime";
				$scope.model.GuidEquipment = $scope.parentModel.GuidEquipment;
				$scope.model.Equipment = $scope.parentModel.Equipment;
			} else if ($scope.parentEntityType === "Equipment") {
				$scope.canCreateDowntime = $scope.parentModel && $scope.parentModel.CanHaveDowntime;
				$scope.model.Type = "Downtime";
			}
			updateAccess();
			$scope.activateAutocomplete = true;
		}

		$scope.save = function (action) {
			if ($scope.model.DueDate) $scope.model.DueDate = new Date($scope.model.DueDate).toISOString();
			if ($scope.model.StartDate) $scope.model.StartDate = new Date($scope.model.StartDate).toISOString();

			if (params.guidEquipment) $scope.model.GuidEquipment = params.guidEquipment;

			if (params.guid) {
				repository.updateSingleDictionary(repository.apiData.operationalMessage.url, $scope.model).then(function (result) {
					repository.growl(translationService.translate('web-operationalMessage-updated', 'Driftsmelding oppdatert'), 'success');
					$modalInstance.close('ok');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			} else {
				repository.createSingleDictionary(repository.apiData.operationalMessage.url, $scope.model).then(function (result) {
					repository.growl(translationService.translate('web-operationalMessage-created', "Driftsmeldingen ble lagt til"), 'success');
					$modalInstance.close('ok');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			}
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "person":
					return filter;
				case "building":
					if ($scope.model.GuidEstate)
						return { PropertyFilter: [{ Property: "GuidEstate", Operator: "=", Value: $scope.model.GuidEstate }] };
					return filter;
				case "equipment":
					filter.PropertyFilter = [{ Property: 'IsTemplate', Operator: '=', Value: false }];
					if ($scope.model.GuidBuilding)
						filter.PropertyFilter.push({ Property: "GuidBuilding", Operator: "=", Value: $scope.model.GuidBuilding });
					return filter;
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
