(function () {
	angular.module('Plania').controller('ControlListCompletionModalController', ['$scope', 'Repository', 'params', '$modalInstance', 'TranslationService', '$q', '$modal', '$filter', '$anchorScroll', '$location', 'focus', '$window', '$timeout', '$localStorage', 'RegisteredFieldService', controller]);

	function controller($scope, repository, params, modalInstance, translationService, $q, $modal, $filter, $anchorScroll, $location, focus, $window, $timeout, $localStorage, registeredFieldService) {
		var isSVV = $localStorage.generalOptions.CustomerId === '10088';
		var answerColumns = [
			'Value', 'ValueInt', 'ValueDate', 'ValueBool', 'ValueString', 'ValueDouble',
			'User.RealName', 'UpdatedDate',
			'ControlListItem.Name',
			'ControlListItem.Data',
			'ControlListItem.IsMandatory',
			'ControlListItem.Type',
			'LinkedDeviations',
			'LinkedRequests'
		];

		$scope.completedControlPoints = 0;
		$scope.totalAvailableControlPoints = 0;
		$scope.workOrder = params.workOrder;
		$scope.controlList = params.controlList;
		$scope.controlListXEntity = params.controlListXEntity;
		$scope.restrictEdit = params.restrictEdit;
		$scope.newDeviations = [];
		$scope.newRequests = [];
		$scope.lastUpdatedBy = null;
		$scope.lastUpdatedDate = null;
		$scope.isArray = angular.isArray;
		$scope.isNotExecutedTabVisible = false;

		$scope.enableWebNavigation = repository.enableWebNavigation;
		$scope.equipmentNavigationState = repository.apiData.equipment.navigationState;
		$scope.housingNavigationState = repository.apiData.housing.navigationState;
		$scope.areaNavigationState = repository.apiData.area.navigationState;
		$scope.forms = {};
		$scope.activeTabs = {
			controlListItems: true,
			images: false,
			notExecutedInfo: false
		};

		$scope.isPreview = params.isPreview;

		$scope.isNullOrEmpty = function (value) {
			return _.isUndefined(value) || _.isNull(value) || value === "";
		};

		//Returns the entity connected to the the controlList that is the lowest in the hierarchy
		$scope.getLowestEntityCaption = function () {
			if ($scope.controlListXEntity.Equipment) {
				var extraText = "";
				if (isSVV && $scope.controlListXEntity.Equipment && $scope.controlListXEntity.Equipment.Text20)
					extraText = " - " + $scope.controlListXEntity.Equipment.Text20;
				return $scope.getEntityCaption($scope.controlListXEntity.Equipment) + extraText;
			} 
			if ($scope.controlListXEntity.Area) return $scope.getEntityCaption($scope.controlListXEntity.Area);
			if ($scope.controlListXEntity.Building) return $scope.getEntityCaption($scope.controlListXEntity.Building);
			if ($scope.controlListXEntity.Estate) return $scope.getEntityCaption($scope.controlListXEntity.Estate);

			return translationService.translate('web-workorder') + ' ' + params.workOrderCaption;
		};

		repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix.ControlListItemAnswer).then(function (result) {
			$scope.controlListItemAnswerFieldRules = result;
			$scope.reload = true;
		});

		if ($scope.isPreview) {
			$scope.controlListRules = params.controlListRules;
			$scope.controlListItemAnswers = params.controlListItemAnswers;
			$scope.totalAvailableControlPoints = _.filter($scope.controlListItemAnswers, function (o) { return o.ControlListItem.Type !== 'CheckBox' && o.ControlListItem.Type !== 'SectionHeader' && o.ControlListItem.Type !== 'Info'; }).length;
		} else {
			repository.GetPaginated(repository.apiData.controlListRule.url, 0, 1000, {}, { PropertyFilter: [{ Property: 'GuidControlList', Operator: '=', Value: $scope.controlList.Guid }] }, '').then(function (response) {
				$scope.controlListRules = response.List;

				$scope.controlListRules.forEach(function (item) {
					if (item.Conditions) item.Conditions = JSON.parse(item.Conditions);
					if (item.Parameters) item.Parameters = JSON.parse(item.Parameters);
				});

				repository.GetPaginated(repository.apiData.controlListItemAnswer.url, 0, 1000, { 'ControlListItem.IndexPosition': 'asc' }, { PropertyFilter: [{ Property: 'GuidControlListXEntity', Operator: '=', Value: $scope.controlListXEntity.Guid }] }, '', JSON.stringify(answerColumns)).then(function (response) {
					$scope.totalAvailableControlPoints = _.filter(response.List, function (o) { return o.ControlListItem.Type !== 'CheckBox' && o.ControlListItem.Type !== 'SectionHeader' && o.ControlListItem.Type !== 'Info'; }).length;

					$scope.controlListItemAnswers = response.List;

					// Parse all json fields before doing further checks (with itemValueChanged in the second loop iteration)
					$scope.controlListItemAnswers.forEach(function (item) {
						if (item.ControlListItem.Data) item.ControlListItem.Data = JSON.parse(item.ControlListItem.Data);
					});

					$scope.controlListItemAnswers.forEach(function (item) {
						$scope.itemValueChanged(item);

						if (!$scope.lastUpdatedDate) {
							$scope.lastUpdatedBy = item.User ? item.User.RealName : 'Server';
							$scope.lastUpdatedDate = item.UpdatedDate;
						} else if (moment(item.UpdatedDate).isAfter(moment($scope.lastUpdatedDate))) {
							$scope.lastUpdatedBy = item.User ? item.User.RealName : 'Server';
							$scope.lastUpdatedDate = item.UpdatedDate;
						}
					});
				});
			});

			repository.GetPaginated(repository.apiData.imageXEntity.url, 0, 100, {}, { PropertyFilter: [{ Property: 'GuidControlListXEntity', Operator: '=', Value: $scope.controlListXEntity.Guid }] }, '', JSON.stringify(['IsDefault', 'Image.Guid', 'Image.Id', 'Image.Description', 'Image.FileName', 'ControlListItemAnswer.ControlListItem.Name'])).then(function (response) {
				$scope.controlListImages = response.List;
				$scope.controlListImages.forEach(function (imgXEntity) {
					if (imgXEntity.Image)
						imgXEntity.Image.IsDefault = imgXEntity.IsDefault;
				});
			});
		}

		$scope.hasCollapseRule = function (item) {
			var result = false;

			var itemRules = _.filter($scope.controlListRules, { GuidControlListItem: item.GuidControlListItem });
			if (!itemRules) return false;

			itemRules.forEach(function (itemRule) {
				if (itemRule.Action === 'Show' || itemRule.Action === 'Hide') result = true;
			});

			return result;
		};

		var shownError = false; // can be removed if we avoid duplicate error message with growl
		$scope.isCollapsed = function (item) {
			var itemRules = _.filter($scope.controlListRules, function (o) { return o.GuidControlListItem === item.GuidControlListItem && (o.Action === 'Show' || o.Action === 'Hide'); });
			if (!itemRules || itemRules.length === 0) return false;

			var conditionResult = evaluateItemRules(itemRules);

			if (itemRules[itemRules.length - 1].Action === 'Show') conditionResult = !conditionResult;

			item.isCollapsed = conditionResult;
			$scope.totalAvailableControlPoints = _.filter($scope.controlListItemAnswers, function (item) { return !item.isCollapsed && item.ControlListItem.Type !== 'Info' && item.ControlListItem.Type !== 'CheckBox' && item.ControlListItem.Type !== 'SectionHeader'; }).length;

			return conditionResult;
		};

		function isHidden(item) {
			var itemRules = _.filter($scope.controlListRules, function (o) { return o.GuidControlListItem === item.GuidControlListItem && o.Action === 'Hide'; });
			if (!itemRules || itemRules.length === 0) return false;

			return evaluateItemRules(itemRules);
		}

		function evaluateItemRules(itemRules) {
			var conditionResult = false;

			itemRules.forEach(function (itemRule) {
				itemRule.Conditions.Filter.forEach(function (filter) {
					var filterItem = _.find($scope.controlListItemAnswers, function (o) { return o.GuidControlListItem.toLowerCase() === filter.GuidControlListItem.toLowerCase(); });
					if (filterItem) {
						var evaluatedResult = evaluateCondition($scope.getValueFromItem(filterItem), filter.Operator, filter.Value, filterItem.ControlListItem.Type);
						if (evaluatedResult) {
							conditionResult = true;
						}
					} else {
						if (!shownError) {
							repository.growl('Feil i kontrolliste - et eller flere kontrollistepunkt kan ikke vises', 'danger');
							shownError = true;
						}
					}
				});
			});

			return conditionResult;
		}

		$scope.getValueFromItem = function (itemAnswer) {
			if (!itemAnswer) return null;

			switch (itemAnswer.ControlListItem.Type) {
				case 'Text':
				case 'TextArea':
				case 'Combo':
				case 'Radio':
					return itemAnswer.ValueString;
				case 'Numeric':
					return itemAnswer.ValueDouble;
				case 'CheckBox':
					return itemAnswer.ValueBool;
				case 'Date':
					return itemAnswer.ValueDate;
				case 'Checklist':
					return itemAnswer.Value;
				default:
					return itemAnswer.ValueString;
			}
		};

		$scope.showChecklistComment = function (item) {
			if (!item || !item.Value) return false;
			return item.Value.toLowerCase() === translationService.translate('web-controllist-answer-NotOk', 'Ikke Ok').toLowerCase();
		};

		var evaluateCondition = function (left, operator, right, type) {
			if (left === undefined || left === null) return false;
			if (type === 'Numeric' && left === "") {
				if (operator !== '=' && operator !== '<>')
					return false;
			}

			switch (operator) {
				case '=':
					if (type === 'Date') {
						return moment(left).isSame(right, 'day');
					} else if (type === 'Numeric') {
						if ($scope.isNullOrEmpty(right))
							return $scope.isNullOrEmpty(left);
						return left === right;
					} else {
						return left.toString().toLowerCase() === right.toString().toLowerCase();
					}
					break;
				case '<>':
					if (type === 'Date') {
						return !moment(left).isSame(right, 'day');
					} else if (type === 'Numeric') {
						if ($scope.isNullOrEmpty(right))
							return !$scope.isNullOrEmpty(left);
						return left !== right;
					} else {
						return left.toString().toLowerCase() !== right.toString().toLowerCase();
					}
					break;
				case '>':
					if (type === 'Date') {
						return moment(left).isAfter(right, 'day');
					} else if (type === 'Numeric') {
						return left > right;
					} else {
						return left.toString().toLowerCase() > right.toString().toLowerCase();
					}
					break;
				case '<':
					if (type === 'Date') {
						return moment(left).isBefore(right, 'day');
					} else if (type === 'Numeric') {
						return left < right;
					} else {
						return left.toString().toLowerCase() < right.toString().toLowerCase();
					}
					break;
				case '>=':
					if (type === 'Date') {
						return moment(left).isSameOrAfter(right, 'day');
					} else if (type === 'Numeric') {
						return left >= right;
					} else {
						return left.toString().toLowerCase() >= right.toString().toLowerCase();
					}
					break;
				case '<=':
					if (type === 'Date') {
						return moment(left).isSameOrBefore(right, 'day');
					} else if (type === 'Numeric') {
						return left <= right;
					} else {
						return left.toString().toLowerCase() <= right.toString().toLowerCase();
					}
					break;
			}
		};

		$scope.$watch('controlListItemAnswers', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			var counter = 0;
			$scope.controlListItemAnswers.forEach(function (item) {
				if (!item.isCollapsed && item.ControlListItem.Type !== 'CheckBox') {
					var value = $scope.getValueFromItem(item);

					if (item.ControlListItem.Type === 'Numeric') {
						if (!$scope.isNullOrEmpty(value))
							counter++;
					}
					else if (value) {
						counter++;
					}
				}
			});

			$scope.completedControlPoints = counter;
			updateProgress();
		}, true);

		var updateProgress = function () {
			var max = $scope.totalAvailableControlPoints,
				type = '',
				percentFill = $scope.completedControlPoints / max * 100;

			if (percentFill < 100) {
				type = 'info';
			} else if (percentFill === 100) {
				type = 'success';
			}

			$scope.progressbarType = type;
		};

		$scope.itemValueChanged = function (item) {
			if ($scope.controlListXEntity.ClosedDate || $scope.restrictEdit)
				return;

			item.hasAction = false;
			checkDeviationAction(item);
			checkRequestAction(item);
		};

		var hasValue = function (itemAnswer) {
			if (itemAnswer.isCollapsed) return true;
			var value = $scope.getValueFromItem(itemAnswer);
			return value ? true : false;
		};

		$scope.hasMandatoryEmptyFields = function (sectionHeaderItem) {
			if (!sectionHeaderItem || !sectionHeaderItem.isCollapsed) return false;

			var thisSectionHeaderIndex = _.findIndex($scope.controlListItemAnswers, function (o) { return o.Guid === sectionHeaderItem.Guid; });
			var nextSectionHeaderIndex;

			var itemAnswers = [];

			for (var i = thisSectionHeaderIndex + 1; $scope.controlListItemAnswers.length > i; i++) {
				if ($scope.controlListItemAnswers[i].ControlListItem.Type === 'SectionHeader') {
					nextSectionHeaderIndex = i;
					break;
				} else if ($scope.controlListItemAnswers[i].ControlListItem.Type !== 'Info') {
					itemAnswers.push($scope.controlListItemAnswers[i]);
				}
			}

			if (itemAnswers.length === 0) return false;

			var result = false;

			for (var j = 0; itemAnswers.length > j; j++) {
				if (itemAnswers[j].ControlListItem.IsMandatory && !hasValue(itemAnswers[j])) {
					result = true;
					break;
				}
			}

			return result;
		};

		// action?: "approve" | "close" | "notExecuted"
		$scope.save = function (action) {
			if ($scope.isPreview) return;

			var itemAnswers = [];

			$scope.controlListItemAnswers.forEach(function (item) {
				if (item.isCollapsed) return;

				var postItem = angular.copy(item);
				delete postItem.ControlListItem;
				delete postItem.User;
				itemAnswers.push(JSON.stringify(postItem));
			});

			repository.updateMultiple(repository.apiData.controlListItemAnswer.urlUpdateMultiple, itemAnswers).then(function (result) {
				$scope.forms.controlListForm.$setPristine();
				if (action === 'approve') {
					if ($scope.newDeviations.length > 0) {
						$scope.openControlListDeviationConfirmation($scope.newDeviations, true);
					} else if ($scope.newRequests.length > 0) {
						$scope.openControlListRequestConfirmation($scope.newRequests, true);
					} else {
						approveControlListXEntity();
					}
				} else if (action === 'close') {
					modalInstance.close();
				} else if (action === 'notExecuted') {
					setNotExecuted();
				}
			}, function (error) {
				swal('Error', error, 'error');
			});
		};

		var approveControlListXEntity = function (createdDeviations) {
			if ($scope.isPreview) return;
			var putData = angular.copy($scope.controlListXEntity);
			putData.ClosedDate = new Date().toISOString();
			putData.GuidUserClosedBy = $scope.userInfo.guidUser;
			putData.GuidReferenceDataNotExecuted = null;
			putData.NotExecutedComment = null;
			delete putData.ReferenceDataNotExecuted;

			repository.updateSingleDictionary(repository.apiData.controlListXEntity.url, putData).then(function (response) {
				$scope.controlListXEntity = response;
				modalInstance.close(createdDeviations);
			}, function (error) {
				$scope.newDeviations = [];
				swal('Error', error, "error");
			});
		};

		var setRuleParameters = function (item, rule) {
			if (rule && rule.Guid)
				item.GuidRule = rule.Guid;
			if (rule && rule.Parameters) {
				rule.Parameters.forEach(function (parameter) {
					if (parameter.IsExpandable) {
						if (parameter.GuidProperty) {
							if (typeof (parameter.Value) === "string") {
								switch (parameter.Value) {
									case "@LoggedInPerson":
										var guidPerson = repository.authService.getUserData().guidPerson;
										if (!guidPerson) break;

										item[parameter.GuidProperty] = guidPerson;
										var nameOfPerson = [
											repository.authService.getUserData().personFirstName,
											repository.authService.getUserData().personLastName
										].filter(function (text) {
											return text !== null && text !== undefined && text !== "";
										}).join(" ");
										item[parameter.Property] = {
											Guid: guidPerson,
											Caption: nameOfPerson
										};
										break;
								}
							} else {
								item[parameter.GuidProperty] = parameter.Value.Guid;
							}
						}
						else if (parameter.Value) { //need to keep this for backword compability, would require alle prefill with autocomplete to update controllist if we want to remove this
                            item['Guid' + parameter.Property] = parameter.Value.Guid;
						}
					} else if ($scope.isArray(parameter.Value)) {
						var texts = [];
						// For now we only support list of guids as prefill
						parameter.Value.forEach(function (guid) {
							if (guid && isGuid(guid)) {
								var answer = _.find($scope.controlListItemAnswers, { GuidControlListItem: guid.toLowerCase() });
								var answerValue = $scope.getValueFromItem(answer);
								if (answer.ControlListItem.Type === "Date" && answerValue && typeof (answerValue) === "string")
									answerValue = $filter('date')(answerValue, 'dd.MM.yyyy HH:mm:ss');
								texts.push([answer.ControlListItem.Name, answerValue].filter(function (text) { return text !== undefined && text !== null; }).join(" - "));
							}
						});

						var resultingText = texts.join("\n");
						var maxLength;

						if (rule.Action === "Deviation" || rule.Action === 'ClosedDeviation') {
							var deviationActionCommentRule = registeredFieldService.getLocalRegisteredField(repository.apiData.deviation.prefix, 'ActionComment');
							if (deviationActionCommentRule)
								maxLength = deviationActionCommentRule.MaxLength || 1024;
						}
						
						if (maxLength && resultingText.length > maxLength) {
							var breakStringText = "-- Tekst avbrutt --";
							resultingText = resultingText.substring(0, maxLength);
							resultingText = resultingText.substring(0, resultingText.length - breakStringText.length) + breakStringText;
						}

						item[parameter.Property] = resultingText;
					} else if (isGuid(parameter.Value)) {
						var answer = _.find($scope.controlListItemAnswers, { GuidControlListItem: parameter.Value.toLowerCase() });
						item[parameter.Property] = $scope.getValueFromItem(answer);
					} else {
						item[parameter.Property] = parameter.Value;
					}
				});
			}
		};

		var setEntityConnection = function (item) {
			if (!item.Equipment && $scope.controlListXEntity.Equipment) {
				item.Equipment = $scope.controlListXEntity.Equipment;
				if ($scope.controlListXEntity.Equipment.Building) {
					item.Building = $scope.controlListXEntity.Equipment.Building;
					item.GuidBuilding = $scope.controlListXEntity.Equipment.Building.Guid;

					if ($scope.controlListXEntity.Equipment.Building.Estate) {
						item.Estate = $scope.controlListXEntity.Equipment.Building.Estate;
						item.GuidEstate = $scope.controlListXEntity.Equipment.Building.Estate.Guid;
					}
				}
			}

			if (!item.Area && $scope.controlListXEntity.Area) {
				item.Area = $scope.controlListXEntity.Area;
				if ($scope.controlListXEntity.Area.Building) {
					item.Building = $scope.controlListXEntity.Area.Building;
					item.GuidBuilding = $scope.controlListXEntity.Area.Building.Guid;

					if ($scope.controlListXEntity.Area.Building.Estate) {
						item.Estate = $scope.controlListXEntity.Area.Building.Estate;
						item.GuidEstate = $scope.controlListXEntity.Area.Building.Estate.Guid;
					}
				}
			}

			if (!item.GuidEstate && !item.GuidBuilding && !item.GuidEquipment && !item.GuidArea) {
				if ($scope.workOrder.Estate) {
					item.Estate = $scope.workOrder.Estate;
					item.GuidEstate = $scope.workOrder.GuidEstate;
				}

				if ($scope.workOrder.Building) {
					item.Building = $scope.workOrder.Building;
					item.GuidBuilding = $scope.workOrder.GuidBuilding;
				}

				if ($scope.workOrder.Equipment) {
					item.Equipment = $scope.workOrder.Equipment;
					item.GuidEquipment = $scope.workOrder.GuidEquipment;
				}

				if ($scope.workOrder.Area) {
					item.Area = $scope.workOrder.Area;
					item.GuidArea = $scope.workOrder.GuidArea;
				}
			}
		};

		var isGuid = function (stringToTest) {
			if (!stringToTest) return false;

			var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
			return regexGuid.test(stringToTest);
		};

		$scope.reopen = function () {
			if ($scope.isPreview) return;
			$scope.controlListXEntity.ClosedDate = null;
			$scope.controlListXEntity.GuidUserClosedBy = null;

			$scope.controlListXEntity.GuidReferenceDataNotExecuted = null;
			$scope.controlListXEntity.NotExecutedComment = null;
			delete $scope.controlListXEntity.ReferenceDataNotExecuted;

			repository.updateSingleDictionary(repository.apiData.controlListXEntity.url, $scope.controlListXEntity).then(function (response) {
				$scope.controlListXEntity.Status = response.Status;
				$scope.isNotExecutedTabVisible = false;
				$scope.activeTabs.notExecutedInfo = false;
				$scope.activeTabs.controlListItems = true;
			}, function (error) {
				swal('Error', error, 'error');
			});
		};

		$scope.showNotExecutedTab = function () {
			$scope.isNotExecutedTabVisible = true;
			$scope.activeTabs.notExecutedInfo = true;
		};

		var setNotExecuted = function () {
			if ($scope.isPreview || !$scope.controlListXEntity.ControlList.GuidReferenceTypeNotExecuted || $scope.isSaving) return;

			if (!$scope.controlListXEntity.GuidReferenceDataNotExecuted) {
				swal({
					title: translationService.translate('web-swal-controlListXEntity-missingReferenceData-title', 'Må velge en årsak'),
					text: translationService.translate('web-swal-controlListXEntity-missingReferenceData-text', 'Årsak må bli valg for å sette en kontrolliste til ikke utført.'),
					type: "warning",
					showCancelButton: false,
					confirmButtonColor: "#ff9800",
					confirmButtonText: translationService.translate('web-swal-controlListXEntity-missingReferenceData-okButton', 'Ok'),
					closeOnConfirm: true
				});
				return;
			}

			var putData = angular.copy($scope.controlListXEntity);
			putData.ClosedDate = new Date().toISOString();
			putData.GuidUserClosedBy = $scope.userInfo.guidUser;

			$scope.isSaving = true;
			repository.updateSingleDictionary(repository.apiData.controlListXEntity.url, putData).then(function (response) {
				$scope.controlListXEntity = response;
				$scope.isSaving = false;
				modalInstance.close();
			}, function (error) {
				$scope.isSaving = false;
				swal('Error', error, "error");
			});
		};

		$scope.getReferenceDataColumns = function () {
			return [
				{ Title: translationService.translate('web-controlListXEntity-referenceData-indexPosition', 'Indeks'), Property: 'IndexPosition' },
				{ Title: translationService.translate('web-controlListXEntity-referenceData-id', 'Id'), Property: 'Id' },
				{ Title: translationService.translate('web-controlListXEntity-referenceData-description', 'Beskrivelse'), Property: 'Description' },
			];
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "referenceData":
					filter.PropertyFilter = [
						{ Property: "DisabledFromDate", Operator: ">", Value: new Date().toISOString() },
						{ Property: "DisabledFromDate", Operator: "=", Value: null, Operand: "OR" },
						{ Property: 'GuidReferenceType', Operator: "=", Value: $scope.controlListXEntity.ControlList.GuidReferenceTypeNotExecuted },
					];
					return filter;
			}
		};

		// #region Image Gallery
		$scope.photoColumnSize = 6;
		$scope.photoColumn = 'col-md-' + $scope.photoColumnSize;

		$scope.photoOptions = [
			{ value: 4, column: 3 },
			{ value: 6, column: 2 },
			{ value: 12, column: 1 }
		];

		$scope.showImages = function () {
			if (!$scope.hasModule('ControlList')) return false;
			return $scope.controlList.UseImage;
		};

		//Change grid
		$scope.photoGrid = function (size) {
			$scope.photoColumn = 'col-md-' + size;
			$scope.photoColumnSize = size;
		};

		$scope.addImage = function (item) {
			$modal.open({
				templateUrl: 'app/document/views/createImageModal.html',
				controller: 'CreateDocumentXEntityController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							guidControlListItemAnswer: item ? item.Guid : null,
							guidControlListXEntity: $scope.controlListXEntity.Guid,
							controlListItemAnswers: $scope.controlListItemAnswers
						};
					}
				}
			}).result.then(function (images) {
				if (!images) return;

				images.forEach(function (image) {
					var newImage = {
						GuidImage: image.Guid,
						IsDefault: image.IsDefault,
						Image: { Guid: image.Guid, Description: image.Description, IsDefault: image.IsDefault }
					};
					if (image.GuidControlListItemAnswer) {
						newImage.GuidControlListItemAnswer = image.GuidControlListItemAnswer;
						newImage.ControlListItemAnswer = _.find($scope.controlListItemAnswers, { Guid: image.GuidControlListItemAnswer });
					}
					if (image.GuidControlListXEntity) {
						newImage.GuidControlListXEntity = image.GuidControlListXEntity;
					}
					$scope.controlListImages.push(newImage);
					repository.growl('Bilde lagt til kontrolliste', 'success');
				});
			}, function (msg) {

			});
		};

		var onIsDefaultChange = function (image) {
			$scope.controlListImages.forEach(function (imgXEntity) {
				if (image.IsDefault && imgXEntity.GuidImage !== image.Guid) {
					imgXEntity.IsDefault = false;
					if (imgXEntity.Image)
						imgXEntity.Image.IsDefault = false;
				}
					
				if (imgXEntity.GuidImage === image.Guid) {
					imgXEntity.IsDefault = image.IsDefault;
					if (imgXEntity.Image)
						imgXEntity.Image.IsDefault = image.IsDefault;
				}
			});

			$scope.controlListImages = _.sortBy($scope.controlListImages, function (img) { return !img.IsDefault; });
		};

		$scope.editImage = function (imageXref, $index) {
			$modal.open({
				templateUrl: 'app/document/views/editImageModal.html',
				controller: 'EditImageController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							image: imageXref.Image,
							guidEntity: imageXref.GuidControlListItemAnswer || imageXref.GuidControlListXEntity,
							deleteAccess: $scope.hasDeleteAccess('ImageXEntity') && !$scope.controlListXEntity.ClosedDate,
							onIsDefaultChange: onIsDefaultChange
						};
					}
				}
			}).result.then(function (result) {
				if (result === 'Deleted') {
					$scope.controlListImages.splice($index, 1);
				}
				else {
					planiaUtils.copyProperties(result, imageXref.Image);
				}
			});
		};

		// #endregion Image Gallery

		var currentFocusedItem;

		$scope.goToNextEmptyItem = function () {
			var newHash, nextElement;
			var emptyElements = _.filter($scope.controlListItemAnswers, function (o) {
				var value = $scope.getValueFromItem(o);
				if (o.ControlListItem.Type === 'Numeric') {
					return $scope.isNullOrEmpty(value);
				}
				return !value && o.ControlListItem.Type !== 'Info' && o.ControlListItem.Type !== 'SectionHeader';
			});

			if (emptyElements.length < 1) {
				currentFocusedItem = null;
				return;
			}

			var emptyNotHiddenElements = [];
			_.forEach(emptyElements, function (emptyElem) {
				if (!isHidden(emptyElem)) {
					emptyNotHiddenElements.push(emptyElem);
				}
			});

			if (!currentFocusedItem || emptyNotHiddenElements.length === 1) {
				nextElement = emptyNotHiddenElements[0];
			} else {
				var currentElementIndex = _.findIndex(emptyNotHiddenElements, function (o) {
					return o.Guid === currentFocusedItem.Guid;
				});

				if (currentElementIndex + 1 === emptyNotHiddenElements.length) {
					nextElement = emptyNotHiddenElements[0];
				} else {
					nextElement = emptyNotHiddenElements[currentElementIndex + 1];
				}
			}

			if (nextElement) {
				currentFocusedItem = nextElement;
				newHash = nextElement.Guid;
				$location.hash(newHash);
				$anchorScroll();
				focus(newHash);
			}
		};

		$scope.cancel = function (reason) {
			if ($scope.forms.controlListForm.$dirty && !$scope.isPreview) {
				swal({
					title: translationService.translate('web-swal-error-unsavedChanges', 'Du har endringer som ikke er lagret!'),
					text: translationService.translate('web-swal-closeWithoutsaving', "Ønsker du å lukke uten å lagre?"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#ff9800",
					confirmButtonText: translationService.translate('web-button-closewithoutsaving', 'Lukk uten å lagre'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: true
				}, function () {
					modalInstance.dismiss(true);
				});
			} else {
				modalInstance.dismiss(false);
			}
		};

		$scope.printControlList = function (printImages) {
			if ($scope.isPreview) return;
			$localStorage.reportParams = JSON.stringify({
				Guid: $scope.controlListXEntity.Guid,
				PrintImages: printImages
			});

			var urlFriendlyControlListName = $scope.controlList.Name ? $scope.controlList.Name.replace(/[^a-zA-Z0-9]+/g, '') : '';

			var url = $scope.navigation.href('report.print', { reportName: 'Plania.API.Reports.ControlListPrint, Plania.API=' + urlFriendlyControlListName });

			$timeout(function () {
				$window.open(url, '_blank');
			}, 100);
		};

		// #region Deviation Methods

		var checkDeviationAction = function (item) {
			if (item.LinkedDeviations && item.LinkedDeviations.length > 0) {
				return;
			}

			if ($scope.isCollapsed(item)) {
				// If collapsed, it should not contain any objects to create.
				// Remove from normal rules
				var rules = _.filter($scope.controlListRules,
					function (o) { return o.GuidControlListItem === item.GuidControlListItem && (o.Action === 'Deviation' || o.Action === 'ClosedDeviation'); });

				rules.forEach(function (rule) {
					if (!rule) return;
					_.remove($scope.newDeviations, function (o) { return o.GuidRule === rule.Guid; });
				});

				// Remove from checklist special rule
				if (item.ControlListItem.Type === 'Checklist')
					_.remove($scope.newDeviations, function (o) { return o.GuidRule === undefined && o.GuidControlListItemAnswer === item.Guid; });
				return;
			}

			$scope.modifyDeviation(item);

			if (item.ControlListItem.Type === 'Checklist') {
				if (item.Value && item.Value.toLowerCase() === translationService.translate('web-controllist-answer-NotOk', 'Ikke Ok').toLowerCase()) {
					var deviationInfo = {
						deviationType: item.ControlListItem.Data.DeviationType,
						guidDeviationType: item.ControlListItem.Data.GuidDeviationType,
						actionComment: item.ValueString
					};
					$scope.newDeviations.push(addNewDeviation(item, null, deviationInfo));
					item.hasAction = true;
				} else {
					_.remove($scope.newDeviations, function (o) { return o.GuidRule === undefined && o.GuidControlListItemAnswer === item.Guid; });
				}
			}
		};

		// Add, update or delete the deviation
		$scope.modifyDeviation = function (item) {
			if (item.LinkedDeviations && item.LinkedDeviations.length > 0) {
				return;
			}

			var rules = _.filter($scope.controlListRules,
				function (o) { return o.GuidControlListItem === item.GuidControlListItem && (o.Action === 'Deviation' || o.Action === 'ClosedDeviation'); });

			rules.forEach(function (rule) {
				if (!rule) return;

				var conditionResult;
				rule.Conditions.Filter.forEach(function (filter) {
					var filterItem = _.find($scope.controlListItemAnswers,
						function (o) { return o.GuidControlListItem.toLowerCase() === filter.GuidControlListItem.toLowerCase(); });
					conditionResult = evaluateCondition($scope.getValueFromItem(filterItem),
						filter.Operator,
						filter.Value,
						filterItem.ControlListItem.Type);
				});

				if (conditionResult === true) {
					var existingDeviation = _.findIndex($scope.newDeviations, function (o) { return o.GuidRule === rule.Guid; });
					if (existingDeviation !== -1) {
						$scope.newDeviations[existingDeviation] = addNewDeviation(item, rule);
					}
					else {
						$scope.newDeviations.push(addNewDeviation(item, rule));
					}
					item.hasAction = true;
				} else {
					_.remove($scope.newDeviations, function (o) { return o.GuidRule === rule.Guid; });
				}
			});
		};

		var addNewDeviation = function (item, rule, deviationInfo) {
			var deviation = {
				IsMandatory: rule ? rule.IsMandatory : true,
				Status: 0,
				GuidControlListItemAnswer: item.Guid,
				GuidWorkOrder: $scope.controlListXEntity.GuidWorkOrder,
				GuidArea: $scope.controlListXEntity.GuidArea,
				GuidEquipment: $scope.controlListXEntity.GuidEquipment,
				Remarks: item.ControlListItem.Name + ' - ' + (item.ControlListItem.Type === 'Date' ? $filter('date')($scope.getValueFromItem(item), 'dd.MM.yyyy') : $scope.getValueFromItem(item))
			};

			if (deviationInfo) {
				deviation.DeviationType = deviationInfo.deviationType ? { Caption: deviationInfo.deviationType } : null;
				deviation.GuidDeviationType = deviationInfo.guidDeviationType;
				deviation.ActionComment = deviationInfo.actionComment;
			}

			if (rule && rule.Action === 'ClosedDeviation') {
				deviation.CloseOnCreation = true;
				deviation.canCloseOnCreation = true;
			}

			setRuleParameters(deviation, rule);
			setEntityConnection(deviation);
			return deviation;
		};

		$scope.openDeviation = function (deviation) {
			$modal.open({
				templateUrl: 'app/deviation/views/editDeviationModal.html',
				controller: 'DeviationController',
				resolve: {
					$stateParams: function () {
						return {
							guid: deviation.Guid,
							isModal: true,
							parentEntity: $scope.workOrder,
						};
					}
				}
			}).result.then(function () {
				//ok
			}, function () {
				//cancel
			});
		};

		$scope.openControlListDeviationConfirmation = function (deviations, approve) {
			if (!approve) $scope.save();

			// Check for any updates on the deviation
			deviations.forEach(function (deviation) {
				var item = _.find($scope.controlListItemAnswers, { Guid: deviation.GuidControlListItemAnswer });
				$scope.modifyDeviation(item);
			});

			// Check for updated ActionComment for checklist and add images
			deviations.forEach(function (deviation) {
				var item = _.find($scope.controlListItemAnswers, { Guid: deviation.GuidControlListItemAnswer });

				if (item.ControlListItem.Type === 'Checklist') {
					deviation.ActionComment = item.ValueString;
				}

				var images = _.filter($scope.controlListImages, function (image) {
					return image.GuidControlListItemAnswer === item.Guid;
				});

				if (images.length > 0) {
					deviation.Images = images;
				}
			});

			$modal.open({
				templateUrl: 'app/controlList/views/ControlListDeviationConfirmationModal.html',
				controller: 'ControlListDeviationConfirmationController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							inWorkOrder: true,
							deviations: deviations,
							isPreview: $scope.isPreview
						};
					}
				}
			}).result.then(function (result) {
				if ($scope.isPreview) return;

				angular.copy(deviations).forEach(function (deviation) {
					if (!deviation.Id)
						return;

					var answer = _.find($scope.controlListItemAnswers, function (o) { return o.Guid === deviation.GuidControlListItemAnswer; });
					if (answer) {
						if (answer.LinkedDeviations)
							answer.LinkedDeviations.push(deviation);
						else
							answer.LinkedDeviations = [deviation];
						_.remove($scope.newDeviations,
							function (o) {
								return o.GuidControlListItemAnswer === answer.Guid;
							});
						$scope.itemValueChanged(answer);
					}
				});

				if (approve && $scope.newRequests.length < 1) {
					approveControlListXEntity(true);
				} else if (approve && $scope.newRequests.length > 0) {
					$scope.openControlListRequestConfirmation($scope.newRequests, approve);
				}
			});
		};
		// #endregion Deviation Methods

		// #region Request Methods

		var checkRequestAction = function (item) {
			if (item.LinkedRequests && item.LinkedRequests.length > 0) {
				return;
			}

			var rules = _.filter($scope.controlListRules, function (o) { return o.GuidControlListItem === item.GuidControlListItem && o.Action === 'Request'; });

			if ($scope.isCollapsed(item)) {
				// If collapsed, it should contain any objects to create.
				// Remove from normal rules
				rules.forEach(function (rule) {
					if (!rule) return;
					_.remove($scope.newRequests, function (o) { return o.GuidRule === rule.Guid; });
				});
				return;
			}

			rules.forEach(function (rule) {
				if (!rule) return;

				var conditionResult;
				rule.Conditions.Filter.forEach(function (filter) {
					var filterItem = _.find($scope.controlListItemAnswers,
						function (o) { return o.GuidControlListItem.toLowerCase() === filter.GuidControlListItem.toLowerCase(); });
					conditionResult = evaluateCondition($scope.getValueFromItem(filterItem), filter.Operator, filter.Value, filterItem.ControlListItem.Type);
				});

				if (conditionResult === true) {
					var existingRequest = _.findIndex($scope.newRequests, function (o) { return o.GuidRule === rule.Guid; });
					if (existingRequest !== -1) {
						$scope.newRequests[existingRequest] = addNewRequest(item, rule);
					}
					else {
						$scope.newRequests.push(addNewRequest(item, rule));
					}
					item.hasAction = true;
				} else {
					_.remove($scope.newRequests, function (o) { return o.GuidRule === rule.Guid; });
				}

			});
		};

		var addNewRequest = function (item, rule) {
			var request = {
				IsMandatory: rule ? rule.IsMandatory : false,
				GuidControlListItemAnswer: item.Guid,
				//GuidWorkOrder: $scope.controlListXEntity.GuidWorkOrder,
				GuidArea: $scope.controlListXEntity.GuidArea,
				GuidEquipment: $scope.controlListXEntity.GuidEquipment,
				ExplanatoryText: item.ControlListItem.Name + ' - ' + (item.ControlListItem.Type === 'Date' ? $filter('date')($scope.getValueFromItem(item), 'dd.MM.yyyy') : $scope.getValueFromItem(item))
			};

			var userData = $localStorage.userData;

			if (userData.guidPerson) {
				request.ContactPerson = userData.personFirstName + ' ' + userData.personLastName;
			} else {
				request.ContactPerson = userData.realName;
			}

			if (userData.email) request.EmailAddress = userData.email;
			if (userData.phone) request.Telephone = userData.phone;

			setRuleParameters(request, rule);
			setEntityConnection(request);

			return request;
		};

		$scope.openControlListRequestConfirmation = function (requests, approve) {
			if (!approve) {
				$scope.save();
			}

			requests.forEach(function (request) {
				var item = _.find($scope.controlListItemAnswers, { Guid: request.GuidControlListItemAnswer });
				$scope.itemValueChanged(item, true);
			});

			requests.forEach(function (request) {
				var item = _.find($scope.controlListItemAnswers, { Guid: request.GuidControlListItemAnswer });

				var images = _.filter($scope.controlListImages, function (image) {
					return image.GuidControlListItemAnswer === item.Guid;
				});

				if (images.length > 0) {
					request.Images = images;
				}

				var rules = _.filter($scope.controlListRules,
					function (o) { return o.GuidControlListItem === item.GuidControlListItem && o.Action === 'Request'; });

				rules.forEach(function (rule) {
					if (!rule) return;
					setRuleParameters(request, rule);
				});
			});

			$modal.open({
				templateUrl: 'app/controlList/views/controlListRequestConfirmationModal.html',
				controller: 'ControlListRequestConfirmationController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							inWorkOrder: true,
							requests: requests,
							isPreview: $scope.isPreview
						};
					}
				}
			}).result.then(function (result) {
				if ($scope.isPreview) return;

				if (approve) {
					approveControlListXEntity(true);
				}
				angular.copy(requests).forEach(function (request) {
					if (!request.Id) return;

					var answer = _.find($scope.controlListItemAnswers, function (o) { return o.Guid === request.GuidControlListItemAnswer; });
					if (answer) {
						if (answer.LinkedRequests)
							answer.LinkedRequests.push(request);
						else
							answer.LinkedRequests = [request];
						_.remove($scope.newRequests,
							function (o) {
								return o.GuidControlListItemAnswer === answer.Guid;
							});
						$scope.itemValueChanged(answer);
					}
				});
			});
		};

		// #endregion Request Methods

		var preview = function (printChoice) {
			if ($scope.forms.controlListForm.$dirty && !$scope.isPreview) {
				swal({
					title: translationService.translate('web-swal-error-unsavedChanges', 'Du har endringer som ikke er lagret!'),
					text: translationService.translate('web-swal-previewWithoutsaving', "Ønsker du å forhåndsvise uten å lagre?"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#ff9800",
					confirmButtonText: translationService.translate('web-button-previewwithoutsaving', 'Forhåndsvis uten å lagre'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: true
				}, function () {
					$scope.printControlList(printChoice);
				});
			} else {
				$scope.printControlList(printChoice);
			}
		};

		$scope.printControlListContextMenu = function () {
			var options = [];
			var noImagesText = $scope.controlListXEntity.ClosedDate? translationService.translate('web-controlListCompletion-printControlList-noImages', 'Print kontrolliste uten bilder'):translationService.translate('web-controlListCompletion-previewControlList-noImages', 'Forhåndsvis kontrolliste uten bilder');
			var inLineText = $scope.controlListXEntity.ClosedDate? translationService.translate('web-controlListCompletion-printControlList-withImagesInLine', 'Print kontrolliste med bilder på linje'):translationService.translate('web-controlListCompletion-previewControlList-withImagesInLine', 'Forhåndsvis kontrolliste med bilder på linje');
			var attachmentText = $scope.controlListXEntity.ClosedDate? translationService.translate('web-controlListCompletion-printControlList-withImagesAsAttachment', 'Print kontrolliste med bilder på slutten'):translationService.translate('web-controlListCompletion-previewControlList-withImagesAsAttachment', 'Forhåndsvis kontrolliste med bilder på slutten');

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + noImagesText + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					preview("None");
				}
			});

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + inLineText + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					preview("InLine");
				}
			});

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + attachmentText + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					preview("Attachment");
				}
			});

			return options;
		};
	}
})();

