(function () {
	angular.module('Plania').controller('PeriodicTaskController', ['$scope', 'Repository', '$stateParams', 'ngTableParams', '$modal', '$modalStack', 'authService', 'TranslationService', 'ListService', '$localStorage', '$q', 'RegisteredFieldService', controller]);

	function controller($scope, repository, $stateParams, ngTableParams, $modal, $modalStack, authService, translationService, listService, $localStorage, $q, registeredFieldService) {
		$scope.isUpdate = $scope.navigation.current.name === 'periodicTask.edit' || $scope.navigation.current.name === 'workOrderTemplate.edit';
		$scope.isDocumentsCollapsed = true;
		$scope.isTextCollapsed = true;
		$scope.isResourcesCollapsed = true;
		$scope.isAreaCollapsed = true;
		$scope.model = { Guid: $stateParams.guid };
		$scope.modelDto = {};
		$scope.activateAutocomplete = false;
		$scope.validCharacters = '^(?!.*<[a-zA-ZæøåÆØÅ]+).*';
		$scope.isBuildingDisabled = false;
		$scope.isEquipmentDisabled = false;
		$scope.isAreaDisabled = false;
		$scope.restrictEdit = true;
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.reload = false;
		$scope.generalOptions = $localStorage.generalOptions;
		$scope.count = { workOrder: 0 };
		$scope.reloadWorkOrderTable = false;
		$scope.routeEntity = $scope.navigation.current.name.split('.')[0];

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !authService.hasEditAccess(repository.commonService.prefix.PeriodicTask, checkOtherDo);
				$scope.restrictDelete = !authService.hasDeleteAccess(repository.commonService.prefix.PeriodicTask, checkOtherDo);
				$scope.isBuildingDisabled = $scope.restrictEdit;
				$scope.isEquipmentDisabled = $scope.restrictEdit;
				$scope.isAreaDisabled = $scope.restrictEdit;
			} else {
				$scope.restrictEdit = !authService.hasCreateAccess(repository.commonService.prefix.PeriodicTask);
				$scope.isBuildingDisabled = $scope.restrictEdit;
				$scope.isEquipmentDisabled = $scope.restrictEdit;
				$scope.isAreaDisabled = $scope.restrictEdit;
			}
		};

		updateAccess();

		$scope.workOrderColumns = [
			{ Position: 1, Title: translationService.translate('web-workorder-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 1, Title: translationService.translate('web-workorder-type'), Property: 'Type', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-workorder-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-equipment-resourcegroup-description', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidResourceGroup', AutocompleteService: 'resourceGroup' },
			{ Position: 6, Title: translationService.translate('web-workorder-startdate'), Property: 'StartDate', PropertyType: 'date' },
			{ Position: 7, Title: translationService.translate('web-workorder-enddate'), Property: 'EndDate', PropertyType: 'date' },
		];

		var getCardTables = function () {
			$scope.equipmentInfo.initTable();
			$scope.resourceInfo.initTable();
			$scope.purchaseOrderItemInfo.initTable();
			$scope.sparePartInfo.initTable();
			$scope.standardTextInfo.initTable();
		};

		$scope.getPropertyValue = function (building, column) {
			return listService.GetPropertyValue(building, column);
		};

		var columns = [
			'Estate.Id', 'Estate.Description', 'Estate.Guid', 'Estate.GuidCostCenter', 'Estate.CostCenter.Id', 'Estate.CostCenter.Description',
			'Building.Id', 'Building.Description', 'Building.GuidCostCenter', 'Building.Guid', 'Building.CostCenter.Id', 'Building.CostCenter.Description',
			'Equipment.Id', 'Equipment.Description',
			'Area.Id', 'Area.Description', 'Area.Guid', 'Area.IsHousing', 'Area.GuidCostCenter', 'Area.CostCenter.Id', 'Area.CostCenter.Description',
			'Project.Id', 'Project.Description',
			'ResourceGroup.Id', 'ResourceGroup.Description', 'ResourceGroup.CostPrHour', 'Cause.Id',
			'Person.FirstName', 'Person.LastName',
			'ResourceResponsible.FirstName', 'ResourceResponsible.LastName',
			'User.RealName', 'User1.RealName',
			'Supplier.Id', 'Supplier.Description', 'Priority.Description',
			'ActivityGroup.Id', 'Department.Id', 'Department.Description',
			'ActivityCategory.Description', 'Department.Id',
			'InvoiceCustomer.Id', 'InvoiceCustomer.Description'
		];

		$scope.isTemplateDisabled = function (field) {
			if (!$scope.model.TemplateFieldConstraints)
				return false;
			return $scope.model.TemplateFieldConstraints.some(function (f) { return f === field; });
		};

		var getPresets = function () {
			if ($scope.isUpdate)
				return null;
			var presets = null;

			var setParameter = function (name, value) {
				if (!value)
					return false;
				if (!presets)
					presets = {};
				presets[name] = value;
				return true;
			};

			if ($stateParams.showPersistantData) {
				var persistentData = repository.persistedData.getPersistedData('periodicTask.create');
				if (persistentData) {
					setParameter("GuidEstate", persistentData.guidEstate);
					setParameter("GuidBuilding", persistentData.guidBuilding);
					setParameter("GuidArea", persistentData.guidArea);
					setParameter("GuidEquipment", persistentData.guidEquipment);
					setParameter("GuidProject", persistentData.guidProject);
					if (persistentData.model) {
						setParameter("GuidResourceGroup", persistentData.model.GuidResourceGroup);
						setParameter("GuidResponsiblePerson", persistentData.model.GuidResponsiblePerson);
						setParameter("GuidDepartment", persistentData.model.GuidDepartment);
						setParameter("GuidCause", persistentData.model.GuidCause);
						setParameter("GuidActivityCategory", persistentData.model.GuidActivityCategory);
						setParameter("GuidPriority", persistentData.model.GuidPriority);
					}
				}
			}

			setParameter("TemplateType", $scope.navigation.current.name === 'workOrderTemplate.create' ? 'WorkOrderTemplate' : 'Periodic');
			setParameter("IsTemplate", $scope.navigation.current.name === 'workOrderTemplate.create' ? true : false);
			$scope.model.TemplateType = presets.TemplateType;
			$scope.model.IsTemplate = presets.IsTemplate;

			return presets;
		};

		var getEntity = function () {
			// for either update (with existing GUID) or insert (with default null GUID), fetch the whole entity to get proper FieldRules, which do not only depend on the entity type, but also on the current entity properties
			// furthermore, some fields may be dynamically initialized for new entities, what may be difficult to maintain in front end code
			// (there is not performance loss compared to the old solution, since a new object already needed a request to fetch field rules anyway)
			var queryString = JSON.stringify(columns);
			if ($stateParams.guidEntityToCopy)
				queryString += "&GuidCopyFrom=" + $stateParams.guidEntityToCopy;
			var presets = getPresets();

			return repository.getMainModel(repository.apiData.periodicTask.url,
				($scope.isUpdate ? $stateParams.guid : "00000000-0000-0000-0000-000000000000"), queryString, presets ? JSON.stringify(presets) : null);
		};

		var getSingle = function (skipCardTables) {
			getEntity().then(function (response) {
				$scope.fieldRules = response.FieldRules;
				var periodicTask = response.Data;
				$scope.model = periodicTask;
				$scope.reload = true;
				if ($scope.isUpdate) {
					$scope.reloadDocumentTable = true;
					$scope.reloadControlLists = true;
					if (!skipCardTables)
						getCardTables();
				}
				updateAccess();

				if (!$scope.restrictEdit) {
					if ($scope.model.GuidBuilding) {
						$scope.isBuildingDisabled = true;
					}
					if ($scope.model.GuidEquipment) {
						$scope.isEquipmentDisabled = true;
					}
					if ($scope.model.GuidArea) {
						$scope.isAreaDisabled = true;
					}
				}

				setProperties();

				if (!$scope.isUpdate) {
					var user = repository.authService.getUserData();

					if (user.guidDepartment) {
						$scope.model.GuidDepartment = user.guidDepartment;
						$scope.modelDto.department = user.userDepartment;
					}

					if (user.guidResourceGroup) {
						$scope.model.GuidResourceGroup = user.guidResourceGroup;
						$scope.modelDto.resourceGroup = user.userResourceGroup;
					}

					var selectedBuilding = repository.commonService.getFilterData().selectedBuilding;
					if (selectedBuilding.Guid) {
						$scope.model.GuidBuilding = selectedBuilding.Guid;
						$scope.modelDto.building = selectedBuilding.Name;

						if (selectedBuilding.Estate) {
							$scope.model.GuidEstate = selectedBuilding.Estate.Guid;
							$scope.modelDto.estate = selectedBuilding.Estate.Caption;
						}
					}

					if (repository.commonService.getFilterData().selectedEstate) {
						var estate = repository.commonService.getFilterData().selectedEstate;
						$scope.model.GuidEstate = estate.Guid;
						$scope.modelDto.estate = estate.Name;
					}

					if ($stateParams.DueDateControlCode || $stateParams.DueDateControlCode === 0) {
						var selectedDueDateControlCode = _.filter($scope.dueDateControlCodes, { value: $stateParams.DueDateControlCode });
						if (selectedDueDateControlCode.length > 0) {
							$scope.selectedDueDateControlCode = selectedDueDateControlCode[0];
						}
					}

					var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
					if (webMenuParams && webMenuParams.Prefill) {
						webMenuParams.Prefill.forEach(function (prefill) {
							if (typeof (prefill.Value) === 'object') {
								$scope.modelDto[prefill.Property.toLowerCase()] = prefill.Value.Label;
								$scope.model['Guid' + prefill.Property] = prefill.Value.Guid;
							} else if (prefill.Value === '@CurrentBuilding') {
								var building = repository.commonService.getFilterData().selectedBuilding;
								if (building.Guid) {
									$scope.modelDto.building = building.Name;
									$scope.model.GuidBuilding = building.Guid;
								}
							} else if (prefill.Value === '@LoggedInPerson') {
								$scope.modelDto.personResponsible = user.personFirstName + ' ' + user.personLastName;
								$scope.model.GuidResponsiblePerson = repository.authService.getUserData().guidPerson;
							} else {
								$scope.model[prefill.Property] = prefill.Value;
							}
						});
					}
				}


				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			}, function (error) {
				repository.growl(error, 'danger');
			});

		};

		$scope.onEstateSelect = function (estate) {
			if (!estate) {
				$scope.model.GuidEstate = "";
				return;
			}

			if ($scope.model.GuidEstate !== estate.Guid) {
				$scope.model.GuidBuilding = "";
				$scope.modelDto.building = "";
				$scope.model.GuidEquipment = "";
				$scope.modelDto.equipment = "";
				$scope.model.GuidArea = "";
				$scope.modelDto.area = "";
			}

			$scope.model.GuidEstate = estate.Guid;
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}

			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidEquipment = "";
				$scope.modelDto.equipment = "";
				$scope.model.GuidArea = "";
				$scope.modelDto.area = "";
			}

			$scope.model.GuidBuilding = building.Guid;

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "" || $scope.model.GuidEstate !== building.GuidEstate) {
				$scope.model.GuidEstate = building.GuidEstate;
				$scope.modelDto.estate = building.Estate.Id + " - " + building.Estate.Description;
			}
		};

		$scope.onEquipmentSelect = function (equipment) {

			if (!equipment) {
				$scope.model.GuidEquipment = "";
				return;
			}
			$scope.model.GuidEquipment = equipment.Guid;

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = equipment.GuidBuilding;
				if (equipment.Building) {
					$scope.modelDto.building = equipment.Building.Caption;
				}
			}

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "") {
				if (equipment.Building.Estate) {
					$scope.model.GuidEstate = equipment.Building.Estate.Guid;
					$scope.modelDto.estate = equipment.Building.Estate.Caption;
				}
			}

			if (equipment.GuidArea) {
				$scope.model.GuidArea = equipment.GuidArea;
				$scope.modelDto.area = equipment.Area.Caption;
			}
		};

		$scope.onPrioritySelect = function (priority) {
			if (priority) {
				$scope.model.GuidPriority = priority.Guid;
				if (priority.HasDeadline) {
					$scope.model.DeadlinePeriodNumber = priority.DeadlinePeriodNumber;
					$scope.model.DeadlinePeriodUnit = priority.DeadlinePeriodUnit;
				}
			} else {
				$scope.model.GuidPriority = null;
			}
		};

		var setProperties = function () {
			var periodicTask = $scope.model;

			if (periodicTask.Estate) $scope.modelDto.estate = periodicTask.Estate.Caption;
			if (periodicTask.Building) $scope.modelDto.building = periodicTask.Building.Caption;
			if (periodicTask.Equipment) $scope.modelDto.equipment = periodicTask.Equipment.Caption;
			if (periodicTask.Area) $scope.modelDto.area = periodicTask.Area.Caption;
			if (periodicTask.ResourceGroup) $scope.modelDto.resourceGroup = periodicTask.ResourceGroup.Caption;
			if (periodicTask.Supplier) $scope.modelDto.supplier = periodicTask.Supplier.Caption;
			if (periodicTask.Department) $scope.modelDto.department = periodicTask.Department.Caption;
			if (periodicTask.ActivityGroup) $scope.modelDto.activityGroup = periodicTask.ActivityGroup.Id;
			if (periodicTask.Priority) $scope.modelDto.priority = periodicTask.Priority.Description;
			if (periodicTask.Cause) $scope.modelDto.cause = periodicTask.Cause.Id;
			if (periodicTask.ActivityCategory) $scope.modelDto.activityCategory = periodicTask.ActivityCategory.Description;
			if (periodicTask.Project) $scope.modelDto.project = periodicTask.Project.Caption;
			if (periodicTask.InvoiceCustomer) $scope.modelDto.invoiceCustomer = periodicTask.InvoiceCustomer.Description; // only show description #16128

			if (periodicTask.Person) {
				$scope.modelDto.personResponsible = periodicTask.Person.FirstName + ' ' + periodicTask.Person.LastName;
			}

			if (periodicTask.ResourceResponsible) {
				$scope.modelDto.resourceResponsible = periodicTask.ResourceResponsible.FirstName + ' ' + periodicTask.ResourceResponsible.LastName;
			}
		};

		getSingle(false);

		$scope.getTextValue = function (intValue, collection) {
			var collectionItem = _.find(collection, function (o) { return o.value === intValue; });
			return collectionItem ? collectionItem.text : collection[0].text;
		};

		var runUpdate = function (success, error) {
			// get all (not null) parent properties currently defined for the entity (not all "registered" properties of the BL class may exist in the JS model)
			// assume that no parent property is defined in the JS entity without its FK counterpart (= for all 'XXX' there is a 'GuidXXX')
			var parentProperties = [];
			for (var propertyName in $scope.model) {
				if (!propertyName.startsWith('Guid'))
					continue;
				var parentPropertyName = _.capitalize(propertyName.substring(4));
				var parent = $scope.model[parentPropertyName];
				if (parent)
					parentProperties.push(parentPropertyName);
			}

			// get a copy of the original entity whose parent entities are reset (to be passed as payload for update function)
			var payloadEntity = angular.copy($scope.model);
			parentProperties.forEach(function (field) {
				delete payloadEntity[field];
			});

			if ($scope.isUpdate)
				repository.updateSingleDictionary(repository.apiData.periodicTask.url, payloadEntity).then(success).catch(error);
			else
				repository.createSingleDictionary(repository.apiData.periodicTask.url, payloadEntity).then(success).catch(error);
		};

		$scope.updatePeriodicTask = function (destination) {
			if ($scope.model.DueDate)
				$scope.model.DueDate = new Date($scope.model.DueDate).toISOString();
			if ($scope.model.LastExecutedDate)
				$scope.model.LastExecutedDate = new Date($scope.model.LastExecutedDate).toISOString();

			if ($scope.model.DeadlinePeriodNumber)
				$scope.model.DeadlinePeriodNumber = Math.floor($scope.model.DeadlinePeriodNumber);

			var success = function (result) {
				var successMessage = "";
				if ($scope.model.TemplateType === 'PeriodicTemplate') {
					successMessage = $scope.isUpdate ?
						translationService.translate('web-periodictask-periodicTemplate-updated', 'Malrutine oppdatert') :
						translationService.translate('web-periodictask-periodicTemplate-created', 'Malrutine opprettet');
				} else if ($scope.model.TemplateType === 'WorkOrderTemplate') {
					successMessage = $scope.isUpdate ?
						translationService.translate('web-periodictask-workOrderTemplate-updated', 'Malaktivitet oppdatert') :
						translationService.translate('web-periodictask-workOrderTemplate-created', 'Malaktivitet opprettet');
				} else {
					successMessage = $scope.isUpdate ?
						translationService.translate('web-periodictask-periodic-updated', 'Periodisk rutine oppdatert') :
						translationService.translate('web-periodictask-periodic-created', 'Periodisk rutine opprettet');
				}

				repository.growl(successMessage, 'success');
				var ptGuid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, ptGuid);
				$scope.goBack(destination, { guid: ptGuid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			// Set default inverval when periodic is used for project template
			if ($scope.model.GuidProject || $scope.model.TemplateType === 'WorkOrderTemplate')
				$scope.model.Interval = 1;

			if ($scope.model.Interval) {
				$scope.model.Interval = Math.floor($scope.model.Interval);
				runUpdate(success, error);
			} else {
				registeredFieldService.getFieldRule(repository.commonService.prefix.periodicTask, 'Interval').then(function (result) {
					if (result.Mandatory) {
						swal({
							title: "Intervall er ikke satt.",
							text: "Intervall vil automatisk bli satt til 1. Vil du fortsatt lagre?",
							type: "info",
							showCancelButton: true,
							confirmButtonText: "Lagre",
							cancelButtonText: "Avbryt",
							closeOnConfirm: true,
							closeOnCancel: true
						}, function (isConfirm) {
							if (isConfirm) {
								$scope.model.Interval = 1;
								runUpdate(success, error);
							}
						});
					}
				});
			}
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "estate":
					return filter;
				case "building":
					if ($scope.model.GuidEstate) {
						return registeredFieldService
							.getFieldRule(repository.commonService.prefix.periodicTask, 'Interval').then(
								function (result) {
									if (!result.Hidden) {
										filter.PropertyFilter = [
											{ Property: 'GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }
										];
									}
									return filter;
								});
					}
					return filter;
				case "equipment":
					filter = { GuidBuilding: $scope.model.GuidBuilding, IsMaintainable: 1, PropertyFilter: [] };
					if ($scope.model.GuidEstate && !$scope.model.GuidBuilding) {
						filter.PropertyFilter.push({ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate });
					}

					if (!$scope.model.IsTemplate)
						filter.PropertyFilter.push({ Property: 'IsTemplate', Operator: '=', Value: false });
					return filter;
				case "area":
					filter = { GuidBuilding: $scope.model.GuidBuilding };
					if ($scope.model.GuidEstate) {
						filter.PropertyFilter = [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }];
					}
					return filter;
				case "person":
					if ($localStorage.generalOptions.UseOnlyActivPersonOnActiv) {
						filter.DoesMaintenanceTasks = 1;
					}

					if ($localStorage.generalOptions.UseOnlyPersInBldOnActiv) {
						filter.GuidBuildingResponsible = $scope.model.GuidBuilding;
					}

					return filter;
				case "resourceResponsible":
					return { DoesMaintenanceTasks: 1 };
				case "resourceGroup":
					return { PropertyFilter: [{ Property: 'DoesMaintenanceTasks', Operator: '=', Value: '1' }] };
				case "priority":
					return { PropertyFilter: [{ Property: 'EntityType', Operator: '=', Value: 6 }] };
				case "cause":
					if ($scope.model.GuidResourceGroup) {
						filter.FilterOnResourceGroup = $scope.model.GuidResourceGroup;
					}
					return filter;

			}
		};

		$scope.removePeriodicTask = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-periodictask-message', "Den periodiske rutinen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-periodictask-button-confirm', 'Ja, fjern rutinen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				var destination = 'periodicTask.list';

				repository.deleteSingle(repository.apiData.periodicTask.url, $scope.model.Guid).then(function (result) {
					swal(translationService.translate('web-swal-periodictask-success', 'Den periodiske rutinen ble fjernet!'), result, "success");
					$scope.goBack(destination, { menuGuid: $scope.navigation.params.menuGuid });
				}, function (error) {
					swal({
						title: translationService.translate('web-swal-periodictask-deactivate-title', 'Kunne ikke slette periodiske rutinen'),
						text: error.Message + "<br/><br/> " + translationService.translate('web-swal-periodictask-deactivate-text', 'Vil du deaktivere periodiske rutinen?'),
						type: "error",
						html: true,
						showCancelButton: true,
						confirmButtonText: translationService.translate('web-button-deactivate', 'Deaktiver'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: true,
						closeOnCancel: true
					}, function (isConfirm) {
						window.onkeydown = null;
						window.onfocus = null;
						if (isConfirm) {
							$scope.model.IsDeactivated = true;
							runUpdate(function (result) {
								var successMessage = $scope.model.IsTemplate ?
									translationService.translate('web-periodictask-template-disabled', 'Malrutinen er deaktivert') :
									translationService.translate('web-periodictask-disabled', 'Periodisk rutine er deaktivert');
								
								repository.growl(successMessage, 'success');
								var ptGuid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
								repository.commonService.setLastRegisterGuid(destination, ptGuid);
								$scope.goBack(destination, { guid: ptGuid, menuGuid: $scope.navigation.params.menuGuid });
							});
						}
					}
					);
				});
			});
		};

		$scope.createWorkOrderFromPeriodicTask = function () {
			if ((!$scope.userInfo.canRunSystemFunction && !$scope.userInfo.canRunNewPeriod) || !$scope.hasEditAccess('WorkOrder')) return;
			runUpdate();
			$modal.open({
				templateUrl: 'app/periodicTask/views/periodicTaskGenerateOrdersConfirmationModal.html',
				controller: 'PeriodicTaskGenerateOrdersConfirmationModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							guids: [$scope.model.Guid]
						};
					}
				}
			}).result.then(function (result) {
				getSingle(false);
				$scope.reloadWorkOrderTable = !$scope.reloadWorkOrderTable;
			}, function () {
			});
		};

		// #region cards
		$scope.sparePartInfo = new function () {
			var me = this;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-sparepart-componentXSupplier-partnumber', 'Delenummer'), Property: 'SparePart.ComponentXSupplier.PartNumber', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-sparepart-component-description', 'Komponentnavn'), Property: 'SparePart.Component.Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-sparePart-location', 'Plassering'), Property: 'SparePart.Location', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-periodicTaskxsparepart-Quantity', 'Antall'), Property: 'Quantity', PropertyType: 'number' },
				{ Position: 5, Title: translationService.translate('web-periodicTaskxsparepart-Price', 'Price'), Property: 'Price', PropertyType: 'double' },
				{ Position: 7, Title: translationService.translate('web-sparePart-Inventory', 'Beholdning'), Property: 'SparePart.Inventory', PropertyType: 'number' },
				{ Position: 8, Title: translationService.translate('web-componentXSupplier-supplier-description', 'Leverandør'), Property: 'SparePart.ComponentXSupplier.Supplier.Description', PropertyType: 'string' },
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'UpdatedDate': 'desc' },
					filter: { PropertyFilter: [{ Property: 'GuidPeriodicTask', Operator: '=', Value: $scope.model.Guid }] }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});
							repository.GetPaginated(repository.apiData.periodicTaskXSparePart.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.growl(error, 'danger');
									});
						}
					});
			};

			this.add = function () {
				var selectedGuids = [];

				me.table.data.forEach(function (sparePart) {
					selectedGuids.push(sparePart.GuidSparePart);
				});

				$modal.open({
					templateUrl: 'app/periodicTask/views/createPeriodicTaskXSparePart.html',
					controller: 'CreatePeriodicTaskXSparePartController',
					size: 'lg',
					resolve: {
						params: function () {
							return {
								update: false,
								excludeGuids: selectedGuids,
							};
						}
					}
				}).result.then(function (item) {
					if (item && item.GuidSparePart) {
						item.GuidPeriodicTask = $scope.model.Guid;
						repository.createSingleDictionary(repository.apiData.periodicTaskXSparePart.url, item)
							.then(function (result) {
								if (!item.QuantityWithdrawn)
									item.QuantityWithdrawn = 0;
								repository.growl(translationService.translate('web-periodictask-sparepart-added', 'Reservedel er lagt til periodiske rutinen'), 'success');
								me.table.reload();
							});
					}
				}, function () {
					//cancel
				});
			};

			this.update = function (sparePart) {
				if (!me.canEdit(sparePart)) return;
				$modal.open({
					templateUrl: 'app/periodicTask/views/createPeriodicTaskXSparePart.html',
					controller: 'CreatePeriodicTaskXSparePartController',
					resolve: {
						params: function () {
							return {
								update: true,
								guid: sparePart.Guid
							};
						}
					}
				}).result.then(function (item) {
					if (item && item.GuidSparePart) {
						item.GuidPeriodicTask = $scope.model.Guid;
						item.Guid = sparePart.Guid;
						repository.updateSingleDictionary(repository.apiData.periodicTaskXSparePart.url, item)
							.then(function (result) {
								repository.growl(translationService.translate('web-spare-part-updated', 'Reservedel er oppdatert'), 'success');
								me.table.reload();
							});
					}
				});
			};

			this.remove = function (sparePart) {
				if (!me.canDelete(sparePart)) return;
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-periodictask-sparepart-swal-confirmRemove', 'Reservedelen vil bli fjernet fra periodisk rutine'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-sparePart-button-confirm', 'Ja, fjern reservedelen!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
						repository.deleteSingle(repository.apiData.periodicTaskXSparePart.url, sparePart.Guid)
						.then(function (result) {
							swal(translationService.translate('web-periodictask-swal-sparePartRemoval-success', 'Reservedelen ble fjernet!'), result, "success");
							repository.growl(translationService.translate('web-periodictask-sparepart-removed', 'Reservedelen er fjernet fra periodiske rutinen'), 'success');
							me.table.reload();
						});
				});
			};

			this.canEdit = function (sparePart) {
				return sparePart &&
					$scope.hasEditAccess('PeriodicTaskXSparePart');
			};

			this.canDelete = function (sparePart) {
				return sparePart &&
					$scope.hasDeleteAccess('PeriodicTaskXSparePart');
			};

			this.contextMenuOptions = function (item) {
				var options = [];

				if (me.canEdit(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-periodicTask-periodicTaskXSparePart-edit', 'Rediger reservedel') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.update(item);
						}
					});
				}

				if (me.canDelete(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-periodicTask-periodicTaskXSparePart-delete', 'Slett reservedel') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.remove(item);
						}
					});
				}

				return options;
			};

		}();

		$scope.equipmentInfo = new function () {
			var me = this;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Equipment.Building.Id', PropertyType: 'string' },
				{ Position: 1, Title: translationService.translate('web-building-description'), Property: 'Equipment.Building.Description', PropertyType: 'string' },
				{ Position: 1, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-equipment-description'), Property: 'Equipment.Description', PropertyType: 'string' },
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: {},
					filter: { PropertyFilter: [{ Property: 'GuidPeriodicTask', Operator: '=', Value: $scope.model.Guid }] }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});
							repository.GetPaginated(repository.apiData.periodicTaskXEquipment.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.growl(error, 'danger');
									});
						}
					});
			};

			this.add = function () {
				var selectedGuids = [];
				me.table.data.forEach(function (equipment) {
					selectedGuids.push(equipment.GuidEquipment);
				});

				$modal.open({
					templateUrl: 'app/periodicTask/views/createPeriodicTaskXEquipment.html',
					controller: 'CreatePeriodicTaskXEquipmentController',
					resolve: {
						params: function () {
							return { guidPeriodicTask: $scope.model.Guid, guidBuilding: $scope.model.GuidBuilding, guidEstate: $scope.model.GuidEstate, selectedGuids: selectedGuids };
						}
					}
				}).result.then(function (item) {
					me.table.reload();
				}, function () {
					//cancel
				});
			};

			this.remove = function (equipment) {
				if (!me.canDelete(equipment)) return;
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-periodictask-equipment-message', "Anlegget vil bli permanent fjernet!"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-periodictask-equipment-button-confirm', 'Ja, fjern anlegget'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
						repository.deleteSingle(repository.apiData.periodicTaskXEquipment.url, equipment.Guid)
						.then(function (result) {
							swal(translationService.translate('web-swal-periodictask-equipment-success', 'Anlegget ble fjernet!'), result, "success");
							me.table.reload();
						});
				});
			};

			this.canDelete = function (equipment) {
				return equipment &&
					$scope.hasDeleteAccess('PeriodicTaskXEquipment');
			};

			this.contextMenuOptions = function (item) {
				var options = [];

				if (me.canDelete(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-periodicTask-periodicTaskXEquipment-delete', 'Slett anlegg') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.remove(item);
						}
					});
				}

				return options;
			};
		}();

		$scope.resourceInfo = new function () {
			var me = this;

			this.columns = [
				{ Position: 1, Title: translationService.translate('workOrder-resourceTable-resource', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('workOrder-resourceTable-person', 'Person'), Property: 'Person.FirstName', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('workOrder-resourceTable-estimatedTime', 'Est. Tid'), Property: 'EstimatedTime', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('workOrder-resourceTable-estimatedCost', 'Est. Kost'), Property: 'EstimatedCost', PropertyType: 'double' }
			];

			this.initTable = function () {
				if (me.table) return;

				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'ResourceGroup.Id': 'asc' },
					filter: { PropertyFilter: [{ Property: 'GuidPeriodicTask', Operator: '=', Value: $scope.model.Guid }] }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							columns.push('ResourceGroup.Description');
							columns.push('ResourceGroup.Guid');
							columns.push('Person.LastName');

							repository.GetPaginated(repository.apiData.periodicTaskXResourceGroup.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.growl(error, 'danger');
									});
						}
					});
			};

			this.getResourceGroup = function () {
				if (!$scope.model.ResourceGroup) return null;
				$scope.model.ResourceGroup.Guid = $scope.model.GuidResourceGroup;
				return $scope.model.ResourceGroup;
			};

			this.add = function () {
				$modal.open({
					templateUrl: 'app/periodicTask/views/editPeriodicTaskXResourceGroup.html',
					controller: 'EditPeriodicTaskXResourceGroupController',
					resolve: {
						params: function () {
							return {
								GuidPeriodicTask: $scope.model.Guid,
								ResourceGroup: me.getResourceGroup(),
								isUpdate: false,
								isFromProjectTemplate: !!$scope.model.GuidProject
							};
						}
					}
				}).result.then(function () {
					getSingle(true);
					me.table.reload();
				}, function () {
					//cancel
				});
			};

			this.update = function (resource) {
				if (!me.canEdit(resource)) return;
				$modal.open({
					templateUrl: 'app/periodicTask/views/editPeriodicTaskXResourceGroup.html',
					controller: 'EditPeriodicTaskXResourceGroupController',
					resolve: {
						params: function () {
							return {
								GuidWorkOrderXResourceGroupGroup: resource.Guid,
								isUpdate: true,
								isFromProjectTemplate: !!$scope.model.GuidProject
							};
						}
					}
				}).result.then(function () {
					getSingle(true);
					me.table.reload();
				}, function () {
					//cancel
				});
			};

			this.remove = function (resource) {
				if (!me.canDelete(resource)) return;
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-periodictask-resource-message', "Ressursen vil bli permanent fjernet!"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-periodictask-resource-button-confirm', 'Ja, fjern ressursen'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.periodicTaskXResourceGroup.url, resource.Guid)
						.then(function (result) {
							swal(translationService.translate('web-swal-periodictask-resource-success', 'Ressursen ble fjernet!'), result, "success");
							getSingle(true);
							me.table.reload();
						});
				});
			};

			this.canEdit = function (resource) {
				return resource &&
					$scope.hasEditAccess('PeriodicTaskXResourceGroup');
			};

			this.canDelete = function (resource) {
				return resource &&
					$scope.hasDeleteAccess('PeriodicTaskXResourceGroup');
			};

			this.contextMenuOptions = function (item) {
				var options = [];

				if (me.canEdit(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-periodicTask-periodicTaskXResourceGroup-edit', 'Rediger ressurs') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.update(item);
						}
					});
				}

				if (me.canDelete(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-periodicTask-periodicTaskXResourceGroup-delete', 'Slett ressurs') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.remove(item);
						}
					});
				}

				return options;
			};
		}();

		$scope.purchaseOrderItemInfo = new function () {
			var me = this;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-purchaseOrder-Id'), Property: 'PurchaseOrder.Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-purchaseOrderItem-Id'), Property: 'Id', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-purchaseOrderItem-Description'), Property: 'Description', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-supplier-Id', 'Leverandør navn'), Property: 'Supplier.Id', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-purchaseOrderItem-OrderedQuantity', 'Bestilt antall'), Property: 'OrderedQuantity', PropertyType: 'number' },
				{ Position: 6, Title: translationService.translate('web-purchaseOrderItem-UnitPrice', 'Enhetspris'), Property: 'UnitPrice', PropertyType: 'number' },
				{ Position: 7, Title: translationService.translate('web-purchaseOrderItem-OrderedAmount', 'Bestilt beløp'), Property: 'OrderedAmount', PropertyType: 'number' },
				{ Position: 8, Title: translationService.translate('web-purchaseOrderItem-Status', 'Status'), Property: 'Status', PropertyType: 'enum.PurchaseOrderItemStatus' },
			];

			this.initTable = function () {
				if (me.table) return;

				var purchaseOrderItemTableFilter = {
					PropertyFilter: [{ Property: 'GuidPeriodicTask', Operator: '=', Value: $scope.model.Guid }]
				};

				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'Id': 'desc' },
					filter: purchaseOrderItemTableFilter
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							repository.GetPaginated(repository.apiData.purchaseOrderItem.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.growl(error, 'danger');
									});
						}
					});
			};

			this.add = function () {
				$modal.open({
					templateUrl: 'app/purchaseOrder/views/purchaseOrderItemModal.html',
					controller: 'PurchaseOrderItemModalController',
					size: 'md',
					resolve: {
						params: function () {
							var guidCostCenter;
							var costCenterCaption;
							if ($scope.model.Area && $scope.model.Area.GuidCostCenter) {
								guidCostCenter = $scope.model.Area.GuidCostCenter;
								costCenterCaption = $scope.model.Area.CostCenter.Caption;
							}

							else if ($scope.model.Building && $scope.model.Building.GuidCostCenter) {
								guidCostCenter = $scope.model.Building.GuidCostCenter;
								costCenterCaption = $scope.model.Building.CostCenter.Caption;
							}

							else if ($scope.model.Estate && $scope.model.Estate.GuidCostCenter) {
								guidCostCenter = $scope.model.Estate.GuidCostCenter;
								costCenterCaption = $scope.model.Estate.CostCenter.Caption;
							}

							return {
								purchaseOrderItem: {
									GuidPeriodicTask: $scope.model.Guid,
									GuidDataOwner: repository.commonService.getFilterData().selectedDataOwner.Guid,
									GuidBuilding: $scope.model.GuidBuilding,
									GuidSupplier: $scope.model.GuidSupplier,
									Supplier: $scope.model.Supplier,
									GuidDepartment: $scope.model.GuidDepartment,
									GuidCostCenter: guidCostCenter,
								},
								costCenterCaption: costCenterCaption,
								isFromProjectTemplate: !!$scope.model.GuidProject
							};
						}
					}
				}).result.then(function () {
					me.table.reload();
				}, function () {
					//cancel
				});
			};

			this.update = function (purchaseOrderItem) {
				if (!me.canEdit(purchaseOrderItem)) return;

				$modal.open({
					templateUrl: 'app/purchaseOrder/views/purchaseOrderItemModal.html',
					controller: 'PurchaseOrderItemModalController',
					size: 'xl',
					resolve: {
						params: function () {
							return {
								Guid: purchaseOrderItem.Guid,
								isFromProjectTemplate: !!$scope.model.GuidProject
							};
						}
					}
				}).result.then(function () {
					me.table.reload();
				}, function () {
					//cancel
				});
			};

			this.remove = function (purchaseOrderItem) {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-purchaseOrderItem-message', 'Bestilling objektet vil bli permanent fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-purchaseOrderItem-button-confirm', 'Ja, fjern bestilling objektet!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
						repository.deleteSingle(repository.apiData.purchaseOrderItem.url, purchaseOrderItem.Guid)
						.then(function (result) {
							swal(translationService.translate('web-swal-purchaseOrderItem-success', 'Bestilling objektet ble fjernet!'), result, "success");
							repository.growl(translationService.translate('web-workorder-purchaseorder-removed', 'Bestilling objektet er fjernet fra bestillingen'), 'success');
							me.table.reload();
						});
				});
			};

			this.canEdit = function (purchaseOrderItem) {
				return purchaseOrderItem &&
					$scope.hasEditAccess('PurchaseOrderItem');
			};

			this.canDelete = function (purchaseOrderItem) {
				return purchaseOrderItem &&
					$scope.hasDeleteAccess('PurchaseOrderItem') &&
					purchaseOrderItem.Status === 'Proposal';
			};

			this.contextMenuOptions = function (item) {
				var options = [];

				if (me.canEdit(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-workorder-purchaseorderitem-edit', 'Rediger konteringsobjekt') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.update(item);
						}
					});
				}

				if (me.canDelete(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-workorder-purchaseorderitem-delete', 'Slett konteringsobjekt') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.remove(item);
						}
					});
				}

				if (item.GuidPurchaseOrder && repository.enableWebNavigation) {
					options.push(getMenuLink($rootScope.navigation.href('purchaseOrder.edit', { guid: item.GuidPurchaseOrder }), 'zmdi-shopping-cart', translationService.translate('web-workorder-viewPurchaseOrder', 'Se bestilling')));
				}

				return options;
			};
		}();

		$scope.standardTextInfo = new function () {
			var me = this;
			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'StandardText.Id': 'asc' },
					filter: { PropertyFilter: [{ Property: 'GuidPeriodicTask', Operator: '=', Value: $scope.model.Guid }] }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							repository.GetPaginated(repository.apiData.periodicTaskXStandardText.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(['CreationDate', 'StandardText.Id', 'StandardText.Description', 'StandardText.DescriptiveText'])).then(
									function (result) {
										$scope.standardTexts = result.List;
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.growl(error, 'danger');
									});
						}
					});
			};

			this.addNewStandardText = function (guidStandardText) {
				var deferred = $q.defer();

				repository.createSingleDictionary(repository.apiData.periodicTaskXStandardText.url, { GuidPeriodicTask: $scope.model.Guid, GuidStandardText: guidStandardText }).then(function (response) {
					deferred.resolve(response);
				}, function (error) {
					deferred.reject(error);
				});

				return deferred.promise;
			};

			this.add = function () {
				$modal.open({
					templateUrl: 'app/common/views/multipleSelectModal.html',
					controller: 'MultipleSelectModalController',
					size: 'lg',
					resolve: {
						modalParams: function () {
							return {
								title: 'Velg standardtekster',
								columns: [
									{ Title: translationService.translate('web-standardText-id'), Property: 'Id' },
									{ Title: translationService.translate('web-standardText-description'), Property: 'Description' },
									{ Title: translationService.translate('web-standardText-DescriptiveText'), Property: 'DescriptiveText' },
								],
								url: repository.apiData.standardText.url,
								sorting: { Id: 'asc' },
								filter: { ExcludeExisting: $scope.model.Guid },
								alreadySavedGuids: me.table.data.map(function (o) { return o.GuidStandardText; })
							};
						}
					}
				}).result.then(function (selectedGuids) {
					var promises = [];

					selectedGuids.forEach(function (guid) {
						promises.push(me.addNewStandardText(guid));
					});

					$q.all(promises).then(function () {
						me.table.reload();
					}, function (error) {
						$scope.standardTextTable.reload();
						swal("Error", error, 'error');
					});
				}, function () {
					//cancel
				});
			};

			this.openStandardText = function (standardText) {
				$modal.open({
					templateUrl: 'app/common/views/messageModal.html',
					size: 'md',
					controller: 'MessageModalController',
					resolve: {
						content: function () {
							return {
								header: standardText.StandardText.Id + ' - ' + standardText.StandardText.Description,
								message: standardText.StandardText.DescriptiveText,
								timestamp: standardText.CreationDate
							};
						}
					}
				});
			};

			this.remove = function (standardText) {
				if (!me.canDelete(standardText)) return;
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-standartText-message', "Standardteksten vil bli permanent fjernet!"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-standartText-button-confirm', 'Ja, fjern standardteksten'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
						repository.deleteSingle(repository.apiData.periodicTaskXStandardText.url, standardText.Guid)
						.then(function (result) {
							swal(translationService.translate('web-swal-standartText-success', 'Standardteksten ble fjernet!'), result, "success");
							me.table.reload();
						});
				});
			};

			this.canDelete = function (standardText) {
				return standardText &&
					$scope.hasDeleteAccess('PeriodicTaskXStandardText');
			};

			this.contextMenuOptions = function (item) {
				var options = [];

				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-eye zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-periodicTask-periodicTaskXStandardText-open', 'Åpne standardtekst') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						me.openStandardText(item);
					}
				});

				if (me.canDelete(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-periodicTask-periodicTaskXStandardText-delete', 'Slett standardtekst') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.remove(item);
						}
					});
				}

				return options;
			};
		}();
		// #endregion

		$scope.onChangePeriod = function (value) {
			if (value === 'HourOfUse')
				$scope.model.DueDateControlCode = 'CounterValue';
			else if ($scope.model.DueDateControlCode === 'CounterValue')
				$scope.model.DueDateControlCode = 'EndOfLastWorkOrder';
		};

		$scope.cancel = function () {
			$modalStack.dismissAll();
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.openCopyModal = function () {
			$modal.open({
				templateUrl: 'app/periodicTask/views/copyPeriodicTaskModal.html',
				controller: 'CopyPeriodicTaskModalController',
				size: 'md',
				resolve: {
					params: function () {
						return {
							GuidPeriodicTask: $scope.model.Guid,
							fieldRules: $scope.fieldRules
						};
					}
				}
			}).result.then(function () {
			},
				function () {
					//cancel
				});
		};
	}
})();
