(function () {
	angular.module('Plania').directive('plListWidget', ['TranslationService', 'ListService', '$timeout', '$modal', function (translationService, listService, $timeout, $modal) {
		return {
			restrict: 'A',
			require: '^plDashboardGrid',
			scope: {
				widget: '=widget',
				edit: '=edit',
				saveFunction: '=saveFunction',
				selectedDrawings: '=selectedDrawings'
			},
			link: function (scope, element, attrs, gridCtrl) {
				scope.$parent.attachWidget(element, attrs);

				scope.removeWidget = function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-dashboard-list-message', "Listen vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-dashboard-list-confirm', 'Ja, fjern listen'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-dashboard-list-success', 'Listen ble fjernet!'), '', "success");
						scope.$parent.removeWidget(element, attrs);
					});
				};

				//Event for resizing
				$('.grid-stack').on('resizestop', function (event, ui) {
					if ($(event.target).find('.list-widget').length > 0) {
						$timeout(function () {
							scope.setHeight();
						}, 350);
					}
				});

				scope.setHeight = function () {
					var bodyHeight = element.height() - (element.find('.card-header').height() + 40);
					element.find('.card-body .lv-body').height(bodyHeight);
				};

				// Timeout to allow for UI to be drawn slightly. Height here would be 22, but at load some fields that should be hidden causes the height to be larger than normal.
				$timeout(function () {
					scope.setHeight();
				}, 10);
			},
			templateUrl: 'app/dashboard/directives/widgets/listWidget.html',
			controller: ['$scope', 'TranslationService', 'Repository', 'OperationalMessageService', '$modal', '$rootScope', 'states', '$interval', 'DashboardSharedDataService', function ($scope, translationService, repository, operationalMessageService, modal, $rootScope, states, $interval, sharedDataService) {
				$scope.model = $scope.widget;
				$scope.search = {};
				$scope.showFilter = false;
				$scope.listviewSearchStat = false;
				$scope.hideSearch = function () {
					$scope.search = {};
					$scope.listviewSearchStat = false;
				};

				var updateAccess = function () {
					if (!$scope.model.WidgetData.DataType) return;

					var prefix = repository.apiData[$scope.model.WidgetData.DataType].prefix;
					if (!prefix) return;

					$scope.hasReadAccess = $rootScope.hasReadAccess(prefix);
					$scope.hasEditAccess = $rootScope.hasEditAccess(prefix);
					$scope.hasCreateAccess = $rootScope.hasCreateAccess(prefix);
					$scope.hasDeleteAccess = $rootScope.hasDeleteAccess(prefix);
				};
				updateAccess();

				$scope.count = 50;
				$scope.totalCount = 0;
				if (typeof ($scope.model.WidgetData) === "string") {
					$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
				}

				if (!$scope.widget.WidgetData) $scope.widget.WidgetData = {};

				$scope.guidDrawings = sharedDataService.getDrawingGuids();

				var searchTimeOutPromise;

				$scope.searchChanged = function () {
					$timeout.cancel(searchTimeOutPromise);
					searchTimeOutPromise = $timeout(function () {
						getItems();
					}, 500);
				};

				var filterTypes = operationalMessageService.getCommonFilterTypes();

				$scope.setFilter = function (filterType) {
					$scope.selectedFilter = _.find(filterTypes, { value: filterType });
					getItems();
					$scope.showFilterDropdown = false;
				};
				$scope.selectedFilter = _.find(filterTypes, { value: 'active' });

				var refreshInterval;
				var isFirstDataFetch = true;
				var itemShadowList = [];

				var getItems = function () {
					if (!$scope.model.WidgetData.DataType) return;
					var url = repository.apiData[$scope.model.WidgetData.DataType].url;
					var sorting = { 'CreationDate': 'desc' };
					var columns = ['Id', 'Description', 'CreationDate'];

					var filter = { PropertyFilter: [], searchString: $scope.search.searchString ? $scope.search.searchString : undefined };

					switch ($scope.model.WidgetData.DataType.toLowerCase()) {
						case "areaxcleaningtask":
							columns.push(
								'Area.Id', 'Area.Description', 'Area.Building.Id', 'Area.Building.Description',
								'CleaningTask.Id', 'CleaningTask.CleaningType'
							);
							filter.PropertyFilter.push(
								{ Property: 'GuidArea', Operator: '<>', Value: '' },
								{ Property: 'CleaningTask.CleaningType', Operator: '<>', Value: -1 },
								{ Property: 'CleaningTask.CleaningType', Operator: '<>', Value: 0 },
								{ Property: 'CleaningTask.CleaningType', Operator: '<>', Value: 2 },
								{ Property: 'GuidCleaner', Operator: '=', Value: '' },
								{ Property: 'GuidCleaningTeam', Operator: '=', Value: '' }
							);

							if ($scope.guidDrawings.length > 0)
								filter.PropertyFilter.push({ Property: 'Area.GuidDrawing', Operator: 'in', Value: $scope.guidDrawings.join(',') });
							break;
						case 'request':
							columns.push(
								'Building.Id', 'Building.Description',
								'Area.Building.Id', 'Area.Building.Description'
							);
							filter.PropertyFilter.push(
								{ Property: 'GuidArea', Operator: '<>', Value: '' },
								{ Operand: 'and', Property: 'Status', Operator: '=', Value: '0' }
							);

							if ($scope.guidDrawings.length > 0)
								filter.PropertyFilter.push({ Property: 'Area.GuidDrawing', Operator: 'in', Value: $scope.guidDrawings.join(',') });
							break;
						case 'operationalmessage':
							sorting = { 'DueDate': 'desc' };
							columns.push(
								'Message', 'DueDate', 'Type', 'StartDate',
								'Estate.Id', 'Estate.Description',
								'Building.Id', 'Building.Description', 'Building.Estate.Id', 'Building.Estate.Description',
								'Equipment.Id', 'Equipment.Description', 'Equipment.Building.Id', 'Equipment.Building.Description', 'Equipment.Building.Estate.Id', 'Equipment.Building.Estate.Description',
								'Person.FirstName', 'Person.LastName',
								'WorkOrder.Id', 'WorkOrder.Description'
							);
							var commonFilter = operationalMessageService.getCommonPropertyFilter($scope.selectedFilter.value);
							if (commonFilter && Array.isArray(commonFilter)) {
								commonFilter.forEach(function (f) { filter.PropertyFilter.push(f); });
							}
							break;
					}

					repository.GetPaginated(url, 0, $scope.count, sorting, filter, '', JSON.stringify(columns)).then(function (result) {
						$scope.totalCount = result.TotalCount;

						if ($scope.model.WidgetData.DataType === 'operationalMessage') {
							result.List.forEach(function (message) {
								message.state = operationalMessageService.getCurrentStateEnum(message);
								message.startDateText = operationalMessageService.getStartDateText(message);
								message.dueDateText = operationalMessageService.getDueDateText(message);
							});
						}
						
						$scope.items = result.List;

						if ($scope.items.length) {
							if (isFirstDataFetch) {
								firstListItemDate = $scope.items[0].CreationDate;
								isFirstDataFetch = false;
							}

							$scope.items.forEach(function (item) {
								if (_.contains(itemShadowList, item.Guid)) return;

								if (moment(item.CreationDate).isAfter(moment(firstListItemDate))) {
									itemShadowList.push(item.Guid);
								}
							});

							firstListItemDate = $scope.items[0].CreationDate;
						}
					}, function (error) {
						repository.growl(error, 'danger');
					});
				};

				getItems();

				if ($scope.model.WidgetData.AutoRefresh && $scope.model.WidgetData.RefreshInterval) {
					setRefreshInterval();
				}

				$scope.isNewAndUnread = function (item) {
					return _.contains(itemShadowList, item.Guid);
				};

				$scope.$watch('guidDrawings', function (newValue, oldValue) {
					if (newValue === oldValue) return;
					getItems();
				}, true);

				$scope.$watch('model.WidgetData', function (newValue, oldValue) {
					if (newValue === oldValue) return;

					if (typeof ($scope.model.WidgetData) === 'string') {
						$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
					}

					if ($scope.model.WidgetData.AutoRefresh && $scope.model.WidgetData.RefreshInterval) {
						setRefreshInterval();
					} else {
						if (refreshInterval) {
							cancelInterval(refreshInterval);
						}
					}

					getItems();
				}, true);

				function setRefreshInterval() {
					if (refreshInterval) {
						cancelInterval(refreshInterval);
					}

					refreshInterval = $interval(function () {
						getItems();
					}, $scope.model.WidgetData.RefreshInterval * 1000);

					$scope.$on('$destroy', function () {
						cancelInterval(refreshInterval);
					});
				}

				function cancelInterval(interval) {
					$interval.cancel(interval);
					interval = undefined;
				}

				$scope.addMoreData = function () {
					$scope.count += 50;
					getItems();
				};

				$rootScope.$on($rootScope.events.newSelection, function () {
					updateAccess();
					getItems();
				});

				$scope.activateItem = function (item) {
					removeItemFromShadowList(item);

					$scope.$root.$broadcast('dashboard.activateItem', item);
				};

				$scope.addNew = function () {
					if ($scope.model.WidgetData.DataType === 'operationalMessage') {
						$modal.open({
							templateUrl: 'app/operationalMessage/views/operationalMessageModal.html',
							controller: 'OperationalMessageModalController',
							resolve: {
								params: function () {
									return {};
								}
							}
						}).result.then(function () {
							getItems();
						}, function () {
							//cancel
						});
					}
				};

				$scope.editItem = function (item) {
					removeItemFromShadowList(item);

					if ($scope.model.WidgetData.DataType === 'request') {
						$rootScope.navigation.go('request.edit', { guid: item.Guid });
					} else if ($scope.model.WidgetData.DataType === 'areaXCleaningTask') {
						modal.open({
							templateUrl: 'app/area/views/EditAreaXCleaningTaskSimpleModal.html',
							controller: 'AreaXCleaningTaskController',
							resolve: {
								modalParams: function () {
									return { Guid: item.Guid };
								}
							}
						}).result.then(function (result) {
							repository.growl(result, 'info');
							getItems();
						});
					} else if ($scope.model.WidgetData.DataType === 'operationalMessage') {
						modal.open({
							templateUrl: 'app/operationalMessage/views/operationalMessageModal.html',
							controller: 'OperationalMessageModalController',
							resolve: {
								params: function () {
									return { guid: item.Guid };
								}
							}
						}).result.then(function (result) {
							getItems();
						});
					}
				};

				function removeItemFromShadowList(item) {
					_.remove(itemShadowList, function (o) {
						return o === item.Guid;
					});
				}

				$scope.editWidget = function () {
					modal.open({
						controller: 'AddWidgetModalController',
						templateUrl: 'app/dashboard/addWidgetModal.html',
						resolve: {
							widget: function () {
								return $scope.model;
							}
						}
					}).result.then(function (widgetModel) {
						$scope.saveFunction(false);
					}, function () {
						//Dismissed
					});
				};

				$scope.getLowestEntityCaption = operationalMessageService.getLowestEntityCaption;
				$scope.getEntityIcon = operationalMessageService.getEntityIcon;
				
				var getMenuLink = function (url, text, icon) {
					var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
					// Show icon, else draw a random icon to keep same padding on left.
					if (icon)
						htmlText += '<i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ';
					else
						htmlText += '<i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ';
					htmlText += text + '</a>';

					return {
						html: htmlText,
						isHref: true
					};
				};

				$scope.menuOptions = function (message) {
					var options = [];
					if ($scope.hasReadAccess) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href=""><i class="zmdi invisible zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-operationalMessage-menuOption-view', 'Åpne') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								$scope.editItem(message);
							}
						});
					}

					if (message.GuidEstate) {
						options.push(getMenuLink($rootScope.navigation.href('estate.edit', { guid: message.GuidEstate }), translationService.translate('web-operationalMessage-menuOption-viewEstate', 'Gå til eiendom')));
					}

					if (message.GuidBuilding) {
						options.push(getMenuLink($rootScope.navigation.href('building.edit', { guid: message.GuidBuilding }), translationService.translate('web-operationalMessage-menuOption-viewBuilding', 'Gå til bygg')));
					}

					if (message.GuidEquipment) {
						options.push(getMenuLink($rootScope.navigation.href('equipment.edit', { guid: message.GuidEquipment }), translationService.translate('web-operationalMessage-menuOption-viewEquipment', 'Gå til anlegg')));
					}

					if (message.GuidWorkOrder) {
						options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: message.GuidWorkOrder }), translationService.translate('web-operationalMessage-menuOption-viewWorkOrder', 'Gå til arbeidsodre')));
					}
					return options;
				};
			}],
		};
	}]);
})();
