(function () {
	angular.module('Plania').controller('UserController', ['$scope', 'TranslationService', 'Repository', '$modal', '$stateParams', 'ngTableParams', '$localStorage', '$rootScope', controller]);

	function controller($scope, translationService, repository, $modal, $stateParams, ngTableParams, $localStorage, $rootScope) {
		$scope.update = $scope.navigation.current.name === 'user.edit';
		$scope.languages = [];
		$scope.model = { Guid: $stateParams.guid };
		$scope.passwordExpirationDate = false;
		$scope.restrictEdit = false;
		$scope.activateAutocomplete = $scope.update ? false : true;
		$scope.viewMode = $scope.update ? true : false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		$scope.open = function ($event) {
			$scope.passwordExpirationDate = !$scope.passwordExpirationDate;
			$event.preventDefault();
			$event.stopPropagation();
		};

		var updateAccess = function () {
			if ($scope.update) {

				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.User, false);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.User, false);

				if (!$scope.userInfo.isUserAdministrator) {
					$scope.restrictEdit = $scope.restrictEdit || $scope.model.Guid !== $scope.userInfo.guidUser;
					$scope.restrictDelete = $scope.restrictEdit || $scope.model.Guid !== $scope.userInfo.guidUser;
				}

			} else {
				$scope.restrictEdit = !$scope.userInfo.isUserAdministrator || !repository.authService.hasCreateAccess(repository.commonService.prefix.User);
				$scope.restrictDelete = !$scope.userInfo.isUserAdministrator || !repository.authService.hasDeleteAccess(repository.commonService.prefix.User);
			}
		};

		function setTwoFactorStatus() {
			$scope.twoFactor = {};
			if ($localStorage.generalOptions.TwoFactorAuthenticationOption <= 0) {
				$scope.twoFactor.isEnabled = false;
				$scope.twoFactor.canChange = false;
				$scope.twoFactor.statusText = translationService.translate('web-user-twofactor-disabled', 'Tofaktor er avskrudd fra system');
			} else if ($localStorage.generalOptions.TwoFactorAuthenticationOption === 2) {
				$scope.twoFactor.isEnabled = true;
				$scope.twoFactor.canChange = false;
				$scope.twoFactor.statusText = translationService.translate('web-user-twofactor-enabled', 'Tofaktor er påskrudd fra system');
			} else if ($scope.model.UserGroup && $scope.model.UserGroup.IsTwoFactorAuthenticationEnabled) {
				$scope.twoFactor.isEnabled = true;
				$scope.twoFactor.canChange = false;
				$scope.twoFactor.statusText = translationService.translate('web-user-twofactor-usergroup-enabled', 'Tofaktor er påskrudd fra brukergruppe');
			} else {
				$scope.twoFactor.isEnabled = $scope.model.IsTwoFactorAuthenticationEnabled;
				$scope.twoFactor.canChange = true;
			}
		}

		updateAccess();
		setTwoFactorStatus();
		$scope.access = {
			Desktop: false,
			Web: false,
			Mobile: false,
			ReadOnlyWeb: false
		};

		var setUserAccess = function (access) {
			if (access.indexOf("Desktop") >= 0)
				$scope.access.Desktop = true;
			if (access.indexOf("Web") >= 0)
				$scope.access.Web = true;
			if (access.indexOf("Mobil") >= 0)
				$scope.access.Mobile = true;
			if (access.indexOf("ReadOnlyWeb") >= 0)
				$scope.access.ReadOnlyWeb = true;
		};

		var GetUserAccess = function () {
			var access = 0;
			if ($scope.access.Desktop)
				access = access | 1 << 0;
			if ($scope.access.Web)
				access = access | 1 << 1;
			if ($scope.access.Mobile)
				access = access | 1 << 2;
			if ($scope.access.ReadOnlyWeb)
				access = access | 1 << 3;

			return access;
		};

		$scope.userProfileColumns = [];
		$scope.userProfileColumns.addColumn = function (translationId, defaultText, property, propertyType) {
			this.push({ Position: this.length, Title: translationService.translate(translationId, defaultText), Property: property, PropertyType: propertyType });
			return this;
		};

		$scope.userProfileColumns
			//.addColumn('web-dataOwner-Description', "Klient", 'DataOwner.Description', 'string')
			//.addColumn('web-entityPermissionProfile-Id', "Profil", 'EntityPermissionProfile.Id', 'string')
			//.addColumn('web-entityPermissionProfile-Description', "Profilbeskrivelse", 'EntityPermissionProfile.Description', 'string')
			//.addColumn('web-userProfile-isDefaultDataOwner', "Standard Klient", 'IsDefaultDataOwner', 'string');
			.addColumn('user-clientTable-dataOwnerName', "Klient", 'LinkedDataOwner.Description', 'string')
			.addColumn('user-clientTable-userProfileName', "Profil", 'EntityPermissionProfile.Id', 'string')
			.addColumn('user-clientTable-userProfileDescription', "Profilbeskrivelse", 'EntityPermissionProfile.Description', 'string')
			.addColumn('user-clientTable-isDefaultDataOwner', "Standard Klient", 'IsDefaultDataOwner', 'string');

		var getCardTables = function () {
			$scope.dataOwnerTable = new ngTableParams(
				{ page: 1, count: -1, filter: { PropertyFilter: [{ Property: 'GuidUser', Operator: '=', Value: $scope.model.Guid }] } },
				{
					total: 0,
					filterDelay: 10,
					counts: false,
					getData: function ($defer, params) {
						var columns = [];
						$scope.userProfileColumns.forEach(function (col) {
							columns.push(col.Property);
						});
						repository.GetPaginated(repository.apiData.userProfile.url, params.page() - 1,
							params.count(),
							params.sorting(),
							params.filter(),
							null,
							JSON.stringify(columns)
						).then(
							function (result) {
								$scope.dataOwnerTable.settings().total = result.TotalCount;
								$scope.dataOwnerTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});

			$scope.userEquipmentTable = new ngTableParams(
				{ page: 1, count: 10, filter: { GuidUser: $scope.model.Guid } },
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						repository.GetPaginated(repository.apiData.userEquipment.url, params.page() - 1, params.count(), params.sorting(), params.filter()).then(
							function (result) {
								$scope.userEquipmentTable.settings().total = result.TotalCount;
								$scope.userEquipmentTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});
        };

		repository.GetPaginated(repository.apiData.webMenu.url, 0, 1000, { Name: "asc" }, {}, '', JSON.stringify(['Name']))
			.then(function (result) {
                var availableMenus = [];
                availableMenus.push({ text: 'Ingen', guid: null });
				if (result.List.length > 0) {
					result.List.forEach(function (webMenu) {

						if (!webMenu.IsBuildingDependent) {
							availableMenus.push({
								text: webMenu.Name,
								guid: webMenu.Guid
							});
						}
                    });
                }
				$scope.availableStartPages = availableMenus;
				if ($scope.model.GuidWebMenu)
					$scope.selectedStartMenu = _.filter($scope.availableStartPages, { guid: $scope.model.GuidWebMenu })[0] || $scope.availableStartPages[0];
			});

		var getSingle = function () {
			var columns = [
				'Person.FirstName', 'Person.LastName', 'Person.Email', 'Person.TelephoneWork',
				'ResourceGroup.Id',
				'Department.Id', 'Department.Description',
				'Account.Id', 'Account.Description',
				'Supplier.Description',
				'DefaultBuildingSelection.Description',
				'Customer.Id', 'Customer.Description',
				'MobileMenuProfile.Id', 'MobileMenuProfile.Description'];

			repository.getSingle(repository.apiData.user.url, $stateParams.guid, JSON.stringify(columns)).then(function (result) {
				$scope.model = result.Data;

				if (!$scope.model.UserGroup) {
					$scope.model.UserGroup = { IsStartPageForced: false };
				}

				updateAccess();
				setUserAccess($scope.model.AccessType);
				setTwoFactorStatus();
				repository.GetPaginated(repository.apiData.language.url, 0, 100, {}, {}).then(
					function (result) {
						$scope.languages = [];
						result.List.forEach(function (row) {
							$scope.languages.push(row);
						});
						$scope.model.GuidLanguage = $scope.model.GuidLanguage ? $scope.model.GuidLanguage : $scope.languages[0].Guid;
						$scope.selectedLanguage = _.filter($scope.languages, { Guid: $scope.model.GuidLanguage })[0];
					}
				);
				if ($scope.availableStartPages)
					$scope.selectedStartMenu = _.filter($scope.availableStartPages, { guid: $scope.model.GuidWebMenu })[0] || $scope.availableStartPages[0];

				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);

			},
				function (error) {
					repository.growl(error, 'danger');
				});

			getCardTables();
		};

		if ($scope.update) {
			getSingle();
		} else {
			$scope.model.UserGroup = { IsStartPageForced: false };
			repository.GetPaginated(repository.apiData.language.url, 0, 100, {}, {}).then(
				function (result) {
					result.List.forEach(function (row) {
						$scope.languages.push(row);
					});
					var language = _.filter($scope.languages, { Country: 'Norway' })[0] || $scope.languages[0];
					$scope.model.GuidLanguage = language.Guid;
				}
			);
		}

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "userGroup":
					filter.PropertyFilter = [{ Property: 'IsUserGroup', Operator: '=', Value: 1 }];
					return filter;
			}
			return filter;
		};

		$scope.GuidChangeLanguage = function (selectedLanguage) {
			$scope.model.GuidLanguage = selectedLanguage.Guid;
			$scope.selectedLanguage = _.filter($scope.languages, { Guid: selectedLanguage.Guid })[0];
		};

		$scope.changeStartPage = function (selected) {
			if (selected)
				$scope.model.GuidWebMenu = selected.guid;
		};

		$scope.removeDataOwner = function (dataOwner) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-user-dataOwner-message', 'Klienten vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-user-dataOwner-button-confirm', 'Ja, fjern klienten!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.userProfile.url, dataOwner.Guid)
					.then(function (result) {
						window.onkeydown = null;
						window.onfocus = null;
						repository.growl(translationService.translate('user-removeDataOwner-Success', 'Klient ble fjernet fra Bruker.'), 'success');
						$scope.dataOwnerTable.reload();
						swal.close();
					});
			});
		};

		$scope.deleteUser = function (destination, user) {
			$scope.isDeletingUser = true;
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-user-message', 'Bruker vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-user-button-confirm', 'Ja, fjern brukeren!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.user.url, user.Guid)
					.then(function (result) {
						window.onkeydown = null;
						window.onfocus = null;
						repository.growl(translationService.translate('delete-user-success-message', ' %Username% ble slettet fra systemet', user), 'success');
						$scope.goBack(destination, { menuGuid: $scope.navigation.params.menuGuid });
						swal.close();
					}, function (error) {
							swal({
								title: translationService.translate('web-user-delete-fail', 'Kunne ikke slette %Username%', user),
								text: error.Message + "<br/><br/> " + translationService.translate('web-user-deactivate-question', 'Vil du deaktivere %Username%', user),
								type: "error",
								html: true,
								showCancelButton: true,
								confirmButtonText: "Deaktiver",
								cancelButtonText: "Avbryt",
								closeOnConfirm: true,
								closeOnCancel: true
							}, function (isConfirm) {
								window.onkeydown = null;
								window.onfocus = null;
								if (isConfirm) {
									$scope.model.IsDeactivated = true;
									$scope.updateUser('user.list');
								}
							});

					});
			});
		};

		$scope.onUserGroupSelect = function (ug) {
			if (!ug) {
				$scope.model.GuidUserGroup = '';
				$scope.model.UserGroup.IsStartPageForced = false;
			}

			if ($scope.model.UserGroup && $scope.model.UserGroup.IsStartPageForced) {
				repository.GetPaginated(repository.apiData.webMenu.url, 0, 1000, { Id: "asc" }, {}, '', JSON.stringify(['Name']))
					.then(function (result) {
						var availableMenus = [];
						availableMenus.push({ text: 'Ingen', guid: null });
						if (result.List.length > 0) {
							result.List.forEach(function (webMenu) {

								if (!webMenu.IsBuildingDependent) {
									availableMenus.push({
										text: webMenu.Name,
										guid: webMenu.Guid
									});
								}
							});
						}
						$scope.availableStartPages = availableMenus;
						if ($scope.model.UserGroup.GuidWebMenu) {
							$scope.selectedStartMenu = _.filter($scope.availableStartPages, { guid: $scope.model.UserGroup.GuidWebMenu })[0] || $scope.availableStartPages[0];
							$scope.model.GuidWebMenu = $scope.model.UserGroup.GuidWebMenu;
						}
					});
			}
			//only set default values if it's create new user
			if (!$scope.update) {

				if (!$scope.model.GuidPerson) {
					$scope.model.GuidPerson = ug.guidPerson;
				}

				$scope.model.GuidResourceGroup = ug.GuidResourceGroup;
				$scope.model.ResourceGroup = ug.ResourceGroup;
				$scope.model.GuidAccount = ug.GuidAccount;
				$scope.model.Account = ug.Account;
				$scope.model.GuidDepartment = ug.GuidDepartment;
				$scope.model.Department = ug.Department;
				$scope.model.GuidCustomer = ug.GuidCustomer;
				$scope.model.Customer = ug.Customer;
				$scope.model.GuidDefaultBuildingSelection = ug.GuidDefaultBuildingSelection;
				$scope.model.DefaultBuildingSelection = ug.DefaultBuildingSelection;

				$scope.model.Procuration = ug.Procuration;
				$scope.model.CanPrintPurchaseOrder = ug.CanPrintPurchaseOrder;
			}

			$scope.model.GuidDefaultDataOwner = ug.GuidDefaultDataOwner;
			$scope.model.GuidMobileMenuProfile = ug.GuidMobileMenuProfile;
			$scope.model.MobileMenuProfile = ug.MobileMenuProfile;

			$scope.model.AccessType = ug.AccessType;
			setUserAccess($scope.model.AccessType);
			setTwoFactorStatus();
			$scope.model.IsUserAdministrator = ug.IsUserAdministrator;
			$scope.model.IsSystemAdministrator = ug.IsSystemAdministrator;
			$scope.model.CanRunNewPeriod = ug.CanRunNewPeriod;
			$scope.model.CanRunNewEnergyPeriod = ug.CanRunNewEnergyPeriod;
			$scope.model.CanChangeDataOwner = ug.CanChangeDataOwner;
			$scope.model.CanOverridePermissions = ug.CanOverridePermissions;
			$scope.model.CanRunSystemFunction = ug.CanRunSystemFunction;
			$scope.model.IsBookingAdministrator = ug.IsBookingAdministrator;
			$scope.model.IsExternalUser = ug.IsExternalUser;
			$scope.model.CanChangeRequestStatus = ug.CanChangeRequestStatus;
			$scope.model.CanSeeWebMainMenu = ug.CanSeeWebMainMenu;
			$scope.model.SqlCustomerFilter = ug.SqlCustomerFilter;

			$scope.model.GuidUserGroup = ug.Guid;
			$scope.model.UserGroup = ug;
		};

		$scope.onPersonSelect = function (person) {
			if (!person) {
				$scope.model.GuidPerson = '';
				return;
			}

			if (person.Email) {
				$scope.model.Email = person.Email;
			}

			if (person.TelephoneWork) {
				$scope.model.PhoneNumber = person.TelephoneWork;
			}

			if (!$scope.model.CellPhone && person.Cellphone) {
				$scope.model.CellPhone = person.Cellphone;
			}

			$scope.model.RealName = person.FirstName + ' ' + person.LastName;
			$scope.model.GuidPerson = person.Guid;
		};

		$scope.updateUser = function (destination) {
			if (!$scope.model.IsPasswordPermanent && !$scope.model.PasswordLastDayDate) {
				repository.growl(translationService.translate('user-label-passwordExpirationDate') + ' er obligatorisk', 'danger');
				return;
			}

			$scope.model.AccessType = GetUserAccess();
			if (!$scope.update) {
				$scope.model.GuidDefaultDataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;
			}
			var success = function (result) {
				repository.growl($scope.update ? translationService.translate('update-user-success', 'Brukeren har blitt oppdatert.') : translationService.translate('create-user-success', 'Brukeren har blitt registrert.'), 'success');
				var guid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				var message = '';
				if (error.indexOf('invalid_license_defined_users') >= 0) {
					message = 'Antall brukere er flere enn lisensert. Fortsett vil sende  melding om lisensendring til Plania';
				}
				if (error.indexOf('invalid_license_simple_users') >= 0) {
					message = 'Antall brukere er flere enn lisensert. Fortsett vil sende  melding om lisensendring til Plania';
				}

				if (message !== '') {
					swal({
						title: "Bekreft utvidelse av lisens",
						text: message,
						type: "warning",
						showCancelButton: true,
						confirmButtonText: "Ja, utvid lisens",
						cancelButtonText: "Avbryt",
						closeOnConfirm: true,
						closeOnCancel: true
					}, function (isConfirm) {
						window.onkeydown = null;
						window.onfocus = null;
						if (isConfirm) {
							$scope.model.ConfirmIncreaseInLicense = true;
							$scope.updateUser(destination);
						} else {
							repository.growl('Endringene ble avbrutt!', 'danger');
						}
					});
				} else {
					repository.growl(error, 'danger');
				}
			};

			if ($scope.model.PasswordLastDayDate)
				$scope.model.PasswordLastDayDate = new Date($scope.model.PasswordLastDayDate).toISOString();

			if ($scope.update) {
				repository.updateSingleDictionary(repository.apiData.user.url, $scope.model).then(success).catch(error);
			} else {
				repository.createSingleDictionary(repository.apiData.user.url, $scope.model).then(success).catch(error);
			}
		};


		$scope.addDataOwner = function () {
			$modal.open({
				templateUrl: 'app/user/views/editUserDataOwner.html',
				controller: 'CreateUserDataOwnerController',
				resolve: {
					params: function () {
						return {
							guidUser: $scope.model.Guid,
							isFirst: $scope.dataOwnerTable.data.length < 1
						};
					}
				}
			}).result.then(function (userProfile) {
				if (userProfile && userProfile.IsDefaultDataOwner) {
					$scope.model.GuidDefaultDataOwner = userProfile.GuidLinkedDataOwner;
				}
				$scope.dataOwnerTable.reload();
			});
		};

		$scope.updateDataOwner = function (dataOwner) {
			if ($scope.restrictEdit || $scope.model.GuidUserGroup) return;

			$modal.open({
				templateUrl: 'app/user/views/editUserDataOwner.html',
				controller: 'EditUserDataOwnerController',
				resolve: {
					params: function () {
						return {
							guidUserProfile: dataOwner.Guid
						};
					}
				}
			}).result.then(function (userProfile) {
				if (userProfile && userProfile.IsDefaultDataOwner) {
					$scope.model.GuidDefaultDataOwner = userProfile.GuidLinkedDataOwner;
				}
				$scope.dataOwnerTable.reload().then();
			});
		};

		$scope.addEquipment = function () {
			$modal.open({
				templateUrl: 'app/user/views/createUserEquipment.html',
				controller: 'CreateUserEquipmentController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							guidUser: $scope.model.Guid
						};
					}
				}
			}).result.then(function () {
				$scope.userEquipmentTable.reload();
			});
		};

		$scope.removeEquipment = function (equipment) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-user-equipment-message', 'Utstyr vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-user-equipment-button-confirm', 'Ja, fjern utstyret!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.userEquipment.url, equipment.Guid)
					.then(function (result) {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-user-equipment-success', 'Utstyret ble fjernet!'), result, "success");
						repository.growl(translationService.translate('delete-user-equipment-success-message', 'Utstyret ble slettet fra systemet'), 'success');
						$scope.userEquipmentTable.reload();
					});
			});
			$scope.$on($scope.events.newSelection, function () {
				updateAccess();
			});
		};

		$scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
			if (toState.name !== 'user.edit' && $scope.update && !$scope.isDeletingUser) {
				if ($scope.dataOwnerTable.total() === 0) {
					event.preventDefault();
					swal(
						translationService.translate('user-edit-no-dataowner-title', 'Ingen klienter valgt'),
						translationService.translate('user-edit-no-dataowner', 'Klient og brukerprofil må velges dersom brukeren skal kunne benyttes'),
						'error');
				}
				if (_.filter($scope.dataOwnerTable.data, { IsDefaultDataOwner: true }).length === 0) {
					event.preventDefault();
					swal(
						translationService.translate('user-edit-no-default-dataowner-title', 'Ingen Standard klient valgt'),
						translationService.translate('user-edit-no-default-dataowner', 'Det må velges en standard klient dersom brukeren skal kunne benyttes'),
						'error');
				}
				if (_.filter($scope.dataOwnerTable.data, function (profile) { return profile.GuidEntityPermissionProfile && profile.IsDefaultDataOwner; }).length === 0) {
					event.preventDefault();
					swal(
						translationService.translate('user-edit-no-entityPermission-title', 'Ingen Profil valgt'),
						translationService.translate('user-edit-no-entityPermission', 'Standard klient må ha en profil valgt'),
						'error');
				}
			}
		});

		$scope.uploadImage = function (images) {
			if (images.length < 1) return;
			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/imageUploadModal.html',
				controller: 'ImageUploadModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							image: images[0],
							targetSize: 300,
							areaType: 'circle'
						};
					}
				}
			});

			modalInstance.result.then(function (croppedImage) {
				var files = [];
				handleFileUpload(croppedImage);
				//Read Base64 string and add to file object
				function handleFileUpload(file) {
					var reader = new FileReader();
					reader.onload = function (e) {
						var newFile = {
							ImageSrc: e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length),
							Name: file.name
						};
						files.push(newFile);
						onLoadEndHandler();
					};
					reader.readAsDataURL(file);
				}

				function onLoadEndHandler(image) {
					repository.createMultiple(repository.apiData.image.url, files).then(function (imageResult) {
						var newGuidImage = imageResult[0].Guid;
						var changeUserImageDto = {
							GuidImage: newGuidImage,
							GuidUser: $scope.model.Guid
						};

						repository.createSingle(repository.apiData.user.urlChangeImage, changeUserImageDto).then(function (result) {

							$scope.model.GuidImage = newGuidImage;
							$rootScope.userInfo.guidImage = newGuidImage;
							$localStorage.userData.guidImage = newGuidImage;
							repository.growl('Bilde oppdatert', 'success');
						}, function (error) {
							repository.growl(error, 'danger');
						});
					}, function (error) {
						repository.growl(error, 'danger');
					});
				}
			});
		};

		$scope.changePassword = function () {
			$modal.open({
				templateUrl: 'app/common/modal/views/changePasswordModal.html',
				controller: 'ChangePasswordModalController',
				size: 'sm',
				resolve: {
					params: function () {
						return {
							user: $scope.model
						};
					}
				}
			}).result.then(function (newPassword) {
				if (newPassword) {
					$scope.model.Password = newPassword;
					$scope.updateUser('user.edit');
				}
			});
		};
	}
})();
