(function () {
    angular.module('Plania').factory('DocumentService', ['config', 'Repository', '$window', '$q', documentService]);

    function documentService(config, repository, $window, $q) {
        var service = {};

        //Takes a document, not a documentXEntity, so if you have documentXEntity, just insert documentXEntity.Document
        service.openDocument = function (document) {
            var deferred = $q.defer();
            if (document.FilePath) {
                if (document.FilePath.indexOf("http") === 0) {
                    $window.open(document.FilePath);
                    return $.Deferred().resolve().promise();
                }
            }
            repository.createSingle(repository.apiData.documentData.url, { GuidDocument: document.Guid }).then(function (result) {
                var dataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;
                $window.open(config.baseUrlApi + repository.apiData.documentData.url + result.Data.GuidDocumentResponse + '?dataOwner=' + dataOwner + '&token=' + result.Data.Token, '_blank');
                return deferred.resolve(result);
            }, function (error) {
                return deferred.reject(error);
            });
            return deferred.promise;
        };
        return service;
    }
})();