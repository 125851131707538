(function () {
	angular.module('Plania').factory('AutoCompleteService', ['genericRepository', '$q', 'CommonService', autoCompleteService]);

	function autoCompleteService(genericRepository, $q, commonService) {
		var service = {};

		service.getModel = function (guid, id, description, object) {
			return {
				guid: guid,
				id: id,
				description: description,
				object: object
			};
		};

		service.getAutoComplete = function (url, searchParam, page, pageSize, sorting, resultCallback, columns) {
			var deferred = $q.defer();
			if (searchParam) {
				if (searchParam.dropdown) {
					searchParam.dropdown = searchParam.dropdown.replace(/&/g, "%26");
					searchParam.dropdown = searchParam.dropdown.replace(/#/g, '');
				}
			} else {
				searchParam = {};
			}
			if (!searchParam.GuidBuilding) {
				if (commonService.getFilterData().selectedBuilding.Guid) searchParam.GuidBuilding = commonService.getFilterData().selectedBuilding.Guid;
			}

			if (commonService.getFilterData().selectedSelection.Guid) searchParam.GuidSelection = commonService.getFilterData().selectedSelection.Guid;
			if (commonService.getFilterData().selectedDataOwner.Guid) searchParam.GuidDataOwner = commonService.getFilterData().selectedDataOwner.Guid;

			if (!columns) {
				columns = ['Id', 'Description'];
			}

			url += '?page=' + page +
				'&pageSize=' + pageSize +
				'&filter=' + JSON.stringify(searchParam) +
				'&sorting=' + JSON.stringify(sorting) +
				'&dataOwner=' + commonService.getFilterData().selectedDataOwner.Guid +
				'&columns=' + JSON.stringify(columns);

			genericRepository.get(url, {})
				.then(function (apiResponse) {
					var result = apiResponse.data;
					if (apiResponse.status === 200) {
						var dropdown = [];
						var list = result.Data.List ? result.Data.List : result.Data;
						list.forEach(function (r) {
							dropdown.push(resultCallback(r));
						});
						deferred.resolve({ dropdown: dropdown, totalCount: result.Data.TotalCount });
					} else {
						deferred.reject(result.message);
					}
				}, function (error) {
					if (error.status === 403)
						deferred.resolve({ dropdown: [], totalCount: 0 });
					else
						deferred.reject(error.data);
				});
			return deferred.promise;
		};

		service.estates = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('Estate/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.areas = function (searchParam, page, pageSize, sorting, columns/*=null*/) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			if (!columns)
				columns = ['Id', 'Description', 'RentalPricePerMonth', 'Building.Id', 'Building.Description', 'Building.Estate.Id', 'Building.Estate.Description', 'Building.Estate.Guid'];
			return service.getAutoComplete('area/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		service.article = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			var columns = ['Id', 'Description', 'Unit', 'UnitPrice', 'Service.Id', 'Service.Description'];

			if (!searchParam)
				searchParam = {};

			if (!searchParam.PropertyFilter) {
				searchParam.PropertyFilter = [];
			}

			searchParam.PropertyFilter.push({ Property: "DisabledFromDate", Operator: ">", Value: new Date().toISOString() });
			searchParam.PropertyFilter.push({ Property: "DisabledFromDate", Operator: "=", Value: null, Operand: "OR" });

			return service.getAutoComplete('article/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		service.buildings = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			var columns = ['Id', 'Description', 'Estate.Id', 'Estate.Description', 'Address', 'CostCenter.Id', 'CostCenter.Description'];

			if (!searchParam)
				searchParam = {};

			if (!searchParam.PropertyFilter) {
				searchParam.PropertyFilter = [];
			}

			searchParam.PropertyFilter.push({ Property: 'IsTemplate', Operator:'=',Value:'0' });

			return service.getAutoComplete('building/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		service.deliveryTerm = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('deliveryTerm/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id);
			});
		};

		service.contactPerson = function (searchParam, page, pageSize, sorting) {
			var sort = { FirstName: 'asc' };
			var columns = ['FirstName', 'LastName'];
			if (sorting && sorting.length !== 0)
				sort.FirstName = sorting.id;
			return service.getAutoComplete('contactPerson/', searchParam, page, pageSize, sort, function (r) {
				var name = '';
				if (r.FirstName) {
					name = r.FirstName;
				}
				if (r.LastName) {
					name += r.FirstName ? " " + r.LastName : r.LastName;
				}

				return service.getModel(r.Guid, name, null, r);
			}, columns);
		};

		service.equipments = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			var columns = ['Id', 'Description', 'ConditionContextDescription', 'IsMaintainable', 'CanHaveDowntime', 'Area.Id', 'Area.Description', 'Building.Id', 'Building.Description', 'Building.Estate.Id', 'Building.Estate.Description', 'Building.Estate.Guid'];
			return service.getAutoComplete('equipment/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		service.equipmentOperatingHourType = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			var columns = ['Id'];
			return service.getAutoComplete('equipmentOperatingHourType/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, null, r);
			}, columns);
		};

		service.equipmentCategories = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('EquipmentCategory/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Description, null, r);
			});
		};

		service.persons = function (searchParam, page, pageSize, sorting) {
			var sort = { FirstName: 'asc' };
			var columns = ['FirstName', 'LastName', 'ResourceGroup.Id', 'ResourceGroup.Description', 'ResourceGroup.CostPrHour', 'ResourceGroup.Guid', 'Email', 'TelephoneWork', 'CellPhone', 'Department.Guid', 'Department.Id', 'Department.Description'];
			if (sorting && sorting.length !== 0)
				sort.FirstName = sorting.id;
			return service.getAutoComplete('person/', searchParam, page, pageSize, sort, function (r) {
				var name = '';
				if (r.FirstName) {
					name = r.FirstName;
				}
				if (r.LastName) {
					name += r.FirstName ? " " + r.LastName : r.LastName;
				}

				return service.getModel(r.Guid, name, null, r);
			}, columns);
		};

		service.resourceResponsibles = function (searchParam, page, pageSize, sorting) {
			var sort = { FirstName: 'asc' };
			var columns = ['FirstName', 'LastName'];
			if (sorting && sorting.length !== 0)
				sort.FirstName = sorting.id;
			return service.getAutoComplete('person/', searchParam, page, pageSize, sort, function (r) {
				var name = '';
				if (r.FirstName) {
					name = r.FirstName;
				}
				if (r.LastName) {
					name += r.FirstName ? " " + r.LastName : r.LastName;
				}

				return service.getModel(r.Guid, name, null, r);
			}, columns);
		};

		service.suppliers = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };

			var columns = ['Id', 'Description', 'Telephone', 'Email'];
			return service.getAutoComplete('supplier/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		service.accounting = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('accountingDimension/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description);
			});
		};

		service.resourceGroups = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };

			var columns = ['Id', 'Description', 'CostPrHour'];

			return service.getAutoComplete('ResourceGroup/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		service.hourType = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };

			var columns = ['Id', 'Description', 'Price', 'PriceType'];

			return service.getAutoComplete('HourType/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		service.cause = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('Cause/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id);
			});
		};

		service.checklist = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('Checklist/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.cleaningQuality = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('CleaningQuality/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.department = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('Department/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description);
			});
		};

		service.documentCategory = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('DocumentCategory/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Description, null, r);
			});
		};

		service.documentType = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('DocumentType/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id);
			});
		};

		service.priority = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			var columns = ["Description", "HasDeadline", "DeadlinePeriodNumber", "DeadlinePeriodUnit"];
			return service.getAutoComplete('Priority/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Description, null, r);
			}, columns);
		};

		service.account = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('Account/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description);
			});
		};

		service.buildingCategory = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('BuildingCategory/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Description, null, r);
			}, []);
		};

		service.paymentTerm = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('PaymentTerm/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id);
			});
		};

		service.invoicingTerm = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('InvoicingTerm/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id);
			});
		};

		service.contractType = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			var columns = ['Id'];
			return service.getAutoComplete('ContractType/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id);
			}, columns);
		};

		service.contractCategory = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			var columns = ['Description'];
			return service.getAutoComplete('ContractCategory/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Description, null, r);
			}, columns);
		};

		service.purchaseOrderForm = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('PurchaseOrderForm/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Description, r.GuidReport ? 'Tilgjengelig i web' : 'Ikke tilgjengelig i web', r);
			});
		};

		service.paymentOrderForm = function (searchParam, page, pageSize, sorting) {
			var sort = { id: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.id = sorting.id;
			return service.getAutoComplete('PaymentOrderForm/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Id, null, r);
			});
		};

		service.costCenter = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('CostCenter/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description);
			});
		};

		service.postal = function (searchParam, page, pageSize, sorting) {
			if (sorting && sorting.id) {
				sorting.zipCode = sorting.id;
				delete sorting.id;
			}
			var columns = ['ZipCode', 'PostalArea', 'County', 'MunicipalityName', 'CountryCode'];
			return service.getAutoComplete('PostalCode/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.ZipCode, r.PostalArea + " (" + r.MunicipalityName + ")", r);
			}, columns);
		};

		service.supplierLineOfBusiness = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('SupplierLineOfBusiness/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id);
			});
		};

		service.areaCategory = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('AreaCategory/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Description, null, r);
			});
		};

		service.areaType = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('areaType/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.activityCategory = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('ActivityCategory/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Description, null, r);
			});
		};

		service.organization = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { description: 'asc' };
			return service.getAutoComplete('Organization/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description);
			});
		};

		service.PersonRole = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('PersonRole/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id);
			});
		};

		service.deviationType = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('deviationType/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.EstateCategory = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };

			if (!searchParam.PropertyFilter) {
				searchParam.PropertyFilter = [];
			}

			if (_.filter(searchParam.PropertyFilter, { Property: 'IsAbstract' }).length === 0) {
				searchParam.PropertyFilter.push({ Property: 'IsAbstract', Operator: '=', Value: 0 });
			}

			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('estateCategory/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Description, null, r);
			});
		};

		service.dataOwner = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('dataOwner/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.entityPermissionProfile = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('entitypermissionprofile/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.mobileMenuProfile = function (searchParam, page, pageSize, sorting) {
			var sort = { id: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('mobileMenuProfile/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.buildingSelection = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('buildingSelection/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Description, null, r);
			});
		};

		service.userGroup = function (searchParam, page, pageSize, sorting) {
			var sort = { RealName: 'asc' };
			var columns = ['RealName', 'IsStartPageForced', 'CanPrintPurchaseOrder', 'Procuration',
				'Person.FirstName', 'Person.LastName', 'ResourceGroup.Id', 'Department.Id', 'Department.Description', 'Account.Id', 'Account.Description', 'Supplier.Description', 'DefaultBuildingSelection.Description', 'Customer.Id', 'Customer.Description',
				'AccessType', 'IsUserAdministrator', 'IsSystemAdministrator', 'CanRunNewPeriod', 'CanRunNewEnergyPeriod', 'CanChangeDataOwner', 'CanOverridePermissions', 'CanRunSystemFunction', 'IsBookingAdministrator', 'IsExternalUser', 'CanChangeRequestStatus', 'CanSeeWebMainMenu',
				'SqlCustomerFilter', 'GuidMobileMenuProfile', 'MobileMenuProfile.Id', 'MobileMenuProfile.Description'];
			if (sorting && sorting.length !== 0)
				sort.RealName = sorting.id;
			return service.getAutoComplete('user/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.RealName, null, r);
			}, columns);
		};

		service.customer = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('Customer/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.customerCategory = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('customerCategory/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.customerLineOfBusiness = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('customerLineOfBusiness/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.customerGroup = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('customerGroup/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.component = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };

			var columns = ['Id', 'Description', 'Unit'];
			return service.getAutoComplete('component/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		service.componentCategory = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('componentCategory/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Description, null, r);
			});
		};

		service.constructionType = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('ConstructionType/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id);
			});
		};

		service.cleaningTask = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('CleaningTask/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, null, r);
			});
		};

		service.project = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'desc' };

			return service.getAutoComplete('project/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.projectCategory = function (searchParam, page, pageSize, sorting) {
			var sort = { description: 'asc' };
			if (sorting && sorting.length !== 0)
				sort.description = sorting.id;
			return service.getAutoComplete('ProjectCategory/', searchParam, page, pageSize, sort, function (r) {
				return service.getModel(r.Guid, r.Description, null, r);
			}, []);
		};

		service.projectType = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };

			return service.getAutoComplete('projectType/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, null, r);
			});
		};

		service.drawing = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('drawing/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};



		service.activityGroup = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('activityGroup/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, null, r);
			});
		};

		service.region = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('region/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, null, r);
			});
		};

		service.referenceData = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { INDEX_POSITION: 'asc' };
			var columns = ['Id', 'Description', 'IndexPosition', 'ExplanatoryText01', 'ExplanatoryText02', 'ReferenceType.Id', 'ReferenceType.Description', 'ReferenceType.ExplanatoryText'];
			return service.getAutoComplete('referenceData/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		service.referenceType = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('referenceType/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.entityAttribute = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			return service.getAutoComplete('entityAttribute/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, null, r);
			});
		};

		service.service = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };

			if (!searchParam)
				searchParam = {};
			if (!searchParam.PropertyFilter) {
				searchParam.PropertyFilter = [];
			}
			searchParam.PropertyFilter.unshift({ Property: "DisabledFromDate", Operator: "=", Value: null, Operand: "OR" });
			searchParam.PropertyFilter.unshift({ Property: "DisabledFromDate", Operator: ">", Value: new Date().toISOString() });


			return service.getAutoComplete('service/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.sparePart = function (searchParam, page, pageSize, sorting) {
			sorting = { 'ComponentXSupplier.PartNumber': 'desc' };

			var columns = ['ComponentXSupplier.PartNumber', 'Component.Id', 'Component.Description'];
			return service.getAutoComplete('sparePart/', searchParam, page, pageSize, sorting, function (r) {
				var component = r.Component.Id + ' - ' + r.Component.Description;
				return service.getModel(r.Guid, r.ComponentXSupplier ? r.ComponentXSupplier.PartNumber : null, component, r);
			}, columns);
		};

		service.controlListLogItem = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			var columns = ['Id'];
			return service.getAutoComplete('controlListLogItem/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, null, r);
			}, columns);
		};

		service.purchaseOrder = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			var columns = [
				'Id',
				'Description',
				'Building.Guid',
				'Building.Id',
				'Building.Description',
				'Supplier.Guid',
				'Supplier.Id',
				'Supplier.Description',
				'Equipment.Guid',
				'Equipment.Id',
				'Equipment.Description',
				'Area.Guid',
				'Area.Id',
				'Area.Description'
			];
			searchParam.IncludeWorkOrder = true;
			return service.getAutoComplete('purchaseOrder/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		service.report = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { Name: 'asc' };
			var columns = [
				'Id',
				'Name',
				'Description'
			];
			return service.getAutoComplete('report/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Name, r);
			}, columns);
		};

		service.purchaseOrderItem = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			//fields used for setting autocomplete values on cost
			var columns = [
				'WorkOrder.Guid',
				'WorkOrder.Id',
				'WorkOrder.Description',
				'WorkOrder.GuidArea',
				'Id',
				'Description',
				'UnitPrice',
				'OrderedQuantity',
				'BudgetDate',
				'Account.Guid',
				'Account.Id',
				'Account.Description',
				'Accounting0.Guid',
				'Accounting0.Id',
				'Accounting0.Description',
				'Accounting1.Guid',
				'Accounting1.Id',
				'Accounting1.Description',
				'Accounting2.Guid',
				'Accounting2.Id',
				'Accounting2.Description',
				'Accounting3.Guid',
				'Accounting3.Id',
				'Accounting3.Description',
				'Accounting4.Guid',
				'Accounting4.Id',
				'Accounting4.Description',
				'Building.Guid',
				'Building.Id',
				'Building.Description',
				'Supplier.Guid',
				'Supplier.Id',
				'Supplier.Description',
				'Equipment.Guid',
				'Equipment.Id',
				'Equipment.Description',
				'PurchaseOrder.Guid',
				'PurchaseOrder.Id',
				'PurchaseOrder.Description',
				'CostCenter.Guid',
				'CostCenter.Id',
				'CostCenter.Description',
				'Department.Guid',
				'Department.Id',
				'Department.Description'
			];
			return service.getAutoComplete('purchaseOrderItem/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		service.consumable = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'asc' };
			//fields used for setting autocomplete values on cost
			var columns = [
				'Id',
				'Unit',
				'UnitPrice',
				'Accounting0.Guid',
				'Accounting0.Id',
				'Accounting0.Description',
				'Accounting1.Guid',
				'Accounting1.Id',
				'Accounting1.Description',
				'Accounting2.Guid',
				'Accounting2.Id',
				'Accounting2.Description',
				'Accounting3.Guid',
				'Accounting3.Id',
				'Accounting3.Description',
				'Accounting4.Guid',
				'Accounting4.Id',
				'Accounting4.Description'
			];
			return service.getAutoComplete('consumable/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		service.periodicTask = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'desc' };
			return service.getAutoComplete('periodicTask/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.workOrderTemplate = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'desc' };

			if (!searchParam) searchParam = {};
			if (!searchParam.PropertyFilter) searchParam.PropertyFilter = [];

			searchParam.PropertyFilter.push({ Property: 'TemplateType', Operator: '=', Value: 2 });

			return service.getAutoComplete('periodicTask/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			});
		};

		service.workOrder = function (searchParam, page, pageSize, sorting) {
			if (!sorting || sorting.length === 0)
				sorting = { ID: 'desc' };
			var columns = [
				'Id',
				'Description',
				'Building.Guid',
				'Building.Id',
				'Building.Description',
				'Building.CostCenter.Guid',
				'Building.CostCenter.Id',
				'Building.CostCenter.Description',
				'Supplier.Guid',
				'Supplier.Id',
				'Supplier.Description',
				'Equipment.Guid',
				'Equipment.Id',
				'Equipment.Description',
				'Equipment.CostCenter.Guid',
				'Equipment.CostCenter.Id',
				'Equipment.CostCenter.Description',
				'Area.Guid',
				'Area.Id',
				'Area.Description',
				'Area.CostCenter.Guid',
				'Area.CostCenter.Id',
				'Area.CostCenter.Description'
			];
			searchParam.ActivityStatus = 'Active';
			return service.getAutoComplete('workOrder/', searchParam, page, pageSize, sorting, function (r) {
				return service.getModel(r.Guid, r.Id, r.Description, r);
			}, columns);
		};

		return service;
	}
})();
