(function () {
	angular.module('Plania').controller('EntityLinkController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ColumnFormatService', '$modal', controller]);

	function controller($scope, repository, $stateParams, translationService, columnFormatService, $modal) {
		$scope.isUpdate = !!$stateParams.guid;
		$scope.datePickers = {};
		$scope.activateAutocomplete = false;

		$scope.open = function ($event, calendar) {
			$event.preventDefault();
			$event.stopPropagation();

			$scope.datePickers[calendar] = !$scope.datePickers[calendar];
		};

		$scope.linkEntityTypes = [
			{ key: repository.apiData.estate.prefix, value: translationService.translate('web-enum-prefix-estate') },
			{ key: repository.apiData.building.prefix, value: translationService.translate('web-enum-prefix-building') },
			{ key: repository.apiData.equipment.prefix, value: translationService.translate('web-enum-prefix-equipment') },
			{ key: repository.apiData.area.prefix, value: translationService.translate('web-enum-prefix-area') },
			{ key: repository.apiData.contract.prefix, value: translationService.translate('web-enum-prefix-contract') }
		];

		$scope.actionEntityTypes = [
			{ key: repository.apiData.contract.prefix, value: translationService.translate('web-enum-prefix-contract') }
		];

		$scope.entityLinkType = 'Link';
		$scope.httpHeaders = [];
		$scope.actionRule = {};
		$scope.columns = [];
		$scope.actionRuleHelpers = {};
		$scope.modelDto = {};

		$scope.onEntityLinkTypeChanged = function (newEntityLinkType, oldEntityLinkType) {
			if (newEntityLinkType === oldEntityLinkType) return;
			if ($scope.httpHeaders.length === 0 && $scope.columns.length === 0 && Object.keys($scope.actionRule).length === 0 && !$scope.model.Url) return;

			swal({
				title: translationService.translate('web-entityLink-onEntityLinkTypeChanged-swalTitle', 'Er du sikker?'),
				text: translationService.translate('web-entityLink-onEntityLinkTypeChanged-swalMessage', 'Endring av linktype vil nullstille noen felter'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-entityLink-onEntityLinkTypeChanged-confirm', 'Ja, endre linktype'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt')
			}, function (confirm) {
				window.onkeydown = null;
				window.onfocus = null;
				if (confirm) {
					$scope.httpHeaders = [];
					$scope.actionRule = {};
					$scope.columns = [];
					$scope.model.Url = "";
					$scope.$apply();
				} else {
					$scope.model.Type = oldEntityLinkType;
					$scope.$apply();
				}
			});
		};

		$scope.onEntityTypeChanged = function (newEntityType, oldEntityType) {
			if (newEntityType === oldEntityType) return;
			if ($scope.columns.length === 0 && !$scope.model.Url) return;

			swal({
				title: translationService.translate('web-entityLink-onEntityTypeChanged-swalTitle', 'Er du sikker?'),
				text: translationService.translate('web-entityLink-onEntityTypeChanged-swalMessage', 'Endring av objekttype vil nullstille noen felter'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-entityLink-onEntityTypeChanged-confirm', 'Ja, endre objekttype'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt')
			}, function (confirm) {
				window.onkeydown = null;
				window.onfocus = null;
				if (confirm) {
					$scope.columns = [];
					$scope.model.Url = "";
					$scope.$apply();
				} else {
					$scope.model.EntityType = oldEntityType;
					$scope.$apply();
				}
			});
		};

		$scope.onCreateWorkOrderOnDeliveredCheckboxChanged = function (state) {
			if (state) {
				$scope.actionRule.CreateWorkOrderOnDelivered = {
					GuidWorkOrderTemplate: undefined
				};
			} else {
				$scope.modelDto.workOrderTemplate = null;
				delete $scope.actionRule.CreateWorkOrderOnDelivered;
			}
		};

		var updateAccess = function () {
			// TODO rewrite with a generic updateAccess handler, just passing in prefix and $scope.model
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.EntityLink, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.EntityLink, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.EntityLink);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.EntityLink);
			}
		};

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.entityLink.url, $stateParams.guid).then(
				function(response) {
					$scope.model = response.Data;
					if ($scope.model.Type === 'Undefined') $scope.model.Type = 'Link';
					if ($scope.model.HttpHeaders) $scope.httpHeaders = toArray(JSON.parse(($scope.model.HttpHeaders)));
					if ($scope.model.ActionRule) $scope.actionRule = JSON.parse($scope.model.ActionRule);
					if ($scope.model.Filter) $scope.columns = JSON.parse($scope.model.Filter);

					$scope.actionRuleHelpers.createWorkOrderOnDelivered = !!$scope.actionRule && !!$scope.actionRule.CreateWorkOrderOnDelivered;

					updateAccess();

					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function(error) {
					repository.growl(error, 'danger');
				}
			);
		} else {
			$scope.model = {
				UseUrlEncoding: true,
				Type: 'Link',
			};
			updateAccess();
		}

		function toDictionary(arr) {
			var rv = {};
			for (var i = 0; i < arr.length; ++i)
				rv[arr[i].key] = arr[i].value;
			return rv;
		}
		function toArray(obj) {
			var arr = [];
			for (var key in obj){
				arr.push({'key': key, 'value': obj[key]});
			}
			return arr;
		}

		$scope.update = function (destination) {
			if ($scope.httpHeaders && $scope.httpHeaders.length > 0) $scope.model.HttpHeaders = angular.toJson(toDictionary($scope.httpHeaders));
			else $scope.model.HttpHeaders = undefined;

			if ($scope.actionRule && Object.keys($scope.actionRule).length > 0) $scope.model.ActionRule = angular.toJson($scope.actionRule);
			else $scope.model.ActionRule = undefined;

			if ($scope.columns && $scope.columns.length > 0) $scope.model.Filter = angular.toJson($scope.columns);
			else $scope.model.Filter = undefined;

			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-entityLink-success', 'Navigasjonslenken har blitt oppdatert.') : translationService.translate('create-entityLink-success', 'Navigasjonslenken har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.entityLink.url, $scope.model).then(success, repository.showError);
			} else {
				repository.createSingleDictionary(repository.apiData.entityLink.url, $scope.model).then(success, repository.showError);
			}

		};

		$scope.delete = function () {
			// TODO rewrite with a generic delete handler, just passing in prefix and get default messages and confirmation
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-entityLink-message', "Navigasjonslenken vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-entityLink-button-confirm', 'Ja, fjern navigasjonslenken'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.entityLink.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-entityLink-success', 'Navigasjonslenken ble fjernet!'), result, "success");
						$scope.goBack('entityLink.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.addNewFormatColumn = function () {
			columnFormatService.chooseColumn($scope.model, "Url", $scope.model.EntityType);
		};

		$scope.addNewColumn = function () {
			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/addNewListColumnModal.html',
				controller: 'AddNewListColumnController',
				resolve: {
					params: function () {
						return {
							prefix: $scope.model.EntityType,
							showAdvancedMode: true
						};
					}
				}
			});

			modalInstance.result.then(function (selectedColumns) {
				selectedColumns.forEach(function (column) {
					column.Filter = [{ Operator: '=', Property: column.Property }];
					$scope.columns.push(column);
					if (column.PropertyType === 'date') {
						$scope.datePickers[column.$$hashKey] = false;
					}
				});
			});
		};

		$scope.$watch('model.EntityType', function (newValue, oldValue) {
			if (!newValue || !oldValue || newValue === oldValue) return;
			// If changing entityType and the Url has entity specific variables -> clear the URL
			if ($scope.model.Url && $scope.model.Url.indexOf('[') !== -1) {
				$scope.model.Url = "";
			}
		});
	}
})();
