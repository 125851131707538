(function () {
	angular.module('Plania').controller('WebMenuController', ['$scope', 'Repository', '$stateParams', 'states', 'ngTableParams', '$modal', 'TranslationService', 'DwgThemeService', 'MenuService', listController]);

	function listController($scope, repository, $stateParams, states, ngTableParams, $modal, translationService, dwgThemeService, menuService) {
		$scope.model = { Guid: $stateParams.guid };
		$scope.update = $scope.navigation.current.name === 'webMenu.edit';
		$scope.operators = ['=', '<>', '>', '<', '>=', '<=', 'in', 'contains', 'startsWith'];
		$scope.operands = [{ key: 'og', value: 'and' }, { key: 'eller', value: 'or' }];

		var urls = [];
		var listViews = [];
		var planiaStandardId = 'Plania Standard';

		var updateWebListViews = function () {
			$scope.showWebListView =
				$scope.ddSelectSelected &&
				$scope.ddSelectSelected.params &&
				$scope.ddSelectSelected.params.entityData &&
				$scope.ddSelectSelected.params.entityData.availableForListView;

			if ($scope.showWebListView) {
				var contextId = $scope.ddSelectSelected.params.entityData.contextId || 'List-' + $scope.ddSelectSelected.params.entityData.prefix;
				$scope.webListViews = _.filter(listViews, function (row) {
					return row.Prefix === $scope.ddSelectSelected.params.entityData.prefix && row.ContextId === contextId;
				});

				$scope.webListViews.unshift({ Id: planiaStandardId });
				$scope.webListViews.unshift({ Guid: '00000000-0000-0000-0000-000000000000', Id: translationService.translate('web-webmenu-weblistview-userDefault', 'Bruk aktiv bruker sin listevisning') });

				if ($scope.filters.selectedWebListView)
					$scope.selectedWebListView = _.find($scope.webListViews, function (row) { return row.Guid === $scope.filters.selectedWebListView.Guid; });

				if (!$scope.selectedWebListView || !$scope.selectedWebListView.Guid) {
					if ($scope.selectedWebListView && $scope.selectedWebListView.Id === planiaStandardId) {
						$scope.selectedWebListView = _.find($scope.webListViews, function(row) { return row.Id === planiaStandardId; });
					} else {
						$scope.selectedWebListView = $scope.webListViews[0];
					}
				}
			}
		};

		//Todo when weblistview is converted to new architecture we should ask for specific columns instead of returning everything
		repository.GetPaginated(repository.apiData.webListView.url, 0, 10000, {}, { Prefix: -1, PropertyFilter: [{ Property: 'IsPublicView', Operator: '=', Value: '1' }] }).then(function (result) {
			listViews = _.map(result.List, function (r) { return { Guid: r.Guid, Id: r.Id, Prefix: r.EntityName, ContextId: r.ContextId }; });
			updateWebListViews();
		});

		states.list.forEach(function (row) {
			if (row.availableWebMenu)
				urls.push(row);
		});
		urls = _.sortBy(urls, 'availableWebMenu');

		$scope.showWebListView = false;
		$scope.webListViews = [];
		$scope.selectedWebListView = {};
		$scope.ddSelectOptions = urls;
		$scope.dropdowns = {};
		$scope.selectedDropdown = {};
		$scope.prefillColumns = [];

		$scope.keywords = repository.filterKeyWords;
		$scope.prefillKeywords = repository.prefillKeyWords;

		var updateAccess = function () {
			if ($scope.update) {
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.WebMenu, false);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.WebMenu, false);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.WebMenu);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.WebMenu);
			}
		};

		updateAccess();

		var getDashboardNames = function (key) {
			repository.GetPaginated(repository.apiData.webDashboard.url, 0, 1000, {}, {})
				.then(function (result) {
					$scope.dropdowns[key] = [];
					result.List.forEach(function (row) {
						$scope.dropdowns[key].push({ name: row.Caption, key: key, value: row.Guid });
					});
					$scope.selectedDropdown[key] = $scope.dropdowns[key][0];

				});
		};

		var getReportNames = function (key) {
			var filter = {
				PropertyFilter: [
					{ Property: 'ReportType', Operator: '<>', Value: '4' },
				]
			};
			var columns = [
				'Id',
				'Name'
			];
			repository.GetPaginated(repository.apiData.report.url, 0, -1, {}, filter, null, JSON.stringify(columns))
				.then(function (result) {
					$scope.dropdowns[key] = [];
					result.List.forEach(function (row) {
						$scope.dropdowns[key].push({ name: row.Name || row.Id, key: key, value: row.Id });
					});
					$scope.selectedDropdown[key] = $scope.dropdowns[key][0];

				});
		};

		$scope.filterDropdownChange = function (selected) {
			$scope.filters[selected.key] = selected.name;
		};

		var getDrawingLoggedInDropdown = function () {

			if (!$scope.filters.GetLoggedInCleanerDrawing) {
				$scope.filters.GetLoggedInCleanerDrawing = 'false';
			}

			$scope.dropdowns.GetLoggedInCleanerDrawing = [];
			$scope.dropdowns.GetLoggedInCleanerDrawing.push({ name: 'true', key: 'GetLoggedInCleanerDrawing', value: 'true' });
			$scope.dropdowns.GetLoggedInCleanerDrawing.push({ name: 'false', key: 'GetLoggedInCleanerDrawing', value: 'false' });
			console.log($scope.filters.GetLoggedInCleanerDrawing);
			if ($scope.filters.GetLoggedInCleanerDrawing === 'true')
				$scope.selectedDropdown.GetLoggedInCleanerDrawing = $scope.dropdowns.GetLoggedInCleanerDrawing[0];
			else
				$scope.selectedDropdown.GetLoggedInCleanerDrawing = $scope.dropdowns.GetLoggedInCleanerDrawing[1];

		};

		var getCleaningLoggedInDropdown = function () {
			$scope.dropdowns.GetLoggedInCleanerCleaning = [];
			$scope.dropdowns.GetLoggedInCleanerCleaning.push({ name: 'true', key: 'GetLoggedInCleanerCleaning', value: 'true' });
			$scope.dropdowns.GetLoggedInCleanerCleaning.push({ name: 'false', key: 'GetLoggedInCleanerCleaning', value: 'false' });

			console.log($scope.filters.GetLoggedInCleanerCleaning);

			if ($scope.filters.GetLoggedInCleanerCleaning === 'true')
				$scope.selectedDropdown.GetLoggedInCleanerCleaning = $scope.dropdowns.GetLoggedInCleanerCleaning[0];
			else
				$scope.selectedDropdown.GetLoggedInCleanerCleaning = $scope.dropdowns.GetLoggedInCleanerCleaning[1];
		};

		var getAvailableDwgThemes = function () {
			var availableThemes = _.map(dwgThemeService.getAvailableThemes(), function (o) {
				return { name: o.label, key: 'selectedTheme', value: o.value };
			});
			$scope.dropdowns.selectedTheme = availableThemes;
		};

		var cleanupDropdown = function () {
			$scope.dropdowns = {};
		};


		$scope.$watch('ddSelectSelected', function (newValue, oldValue) {
			if (newValue === oldValue && $scope.prefillColumns.length > 0 || !newValue) return;

			cleanupDropdown();

			if (newValue.name === 'report.view') {
				getReportNames(newValue.filters[0].key);
			}
			if (newValue.name === 'dashboard.view') {
				getDashboardNames(newValue.filters[0].key);
			}
			if (newValue.name === 'dwg.list') {
				getDrawingLoggedInDropdown();
				getAvailableDwgThemes();
			}
			if (newValue.name === 'cleaning.list') {
				getCleaningLoggedInDropdown();
			}

			if (newValue.params && newValue.params.entityData) {
				var locale = translationService.getLocale();
				repository.getWithUrlParameter(repository.apiData.webColumns.url, 'domainModel=' + newValue.params.entityData.prefix + '&locale=' + locale).then(function (result) {
					$scope.prefillColumns = result;

					if (newValue.params.entityData.prefix === 'Document') {
						repository.getWithUrlParameter(repository.apiData.webColumns.url, 'domainModel=DocumentXEntity&locale=' + locale).then(function (result) {
							result.forEach(function (item) {
								$scope.prefillColumns.push(item);
							});
						});
					}
				});

				updateWebListViews();

			} else {
				$scope.showWebListView = false;
				$scope.prefillColumns = [];
				$scope.filters.Prefill = [];
			}
		});

		$scope.filters = {};
		$scope.filters.PropertyFilter = [];
		$scope.filters.Prefill = [];

		$scope.webprofileColumns = [
			{ Position: 1, Title: translationService.translate('web-webprofile-id'), Property: 'WebProfile.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-supplier-description'), Property: 'WebProfile.Description', PropertyType: 'string' },
		];

		if ($scope.update) {
			repository.getSingle(repository.apiData.webMenu.url, $stateParams.guid).then(
				function (response) {
					$scope.model = response.Data;
					updateAccess();
					$scope.filters = JSON.parse($scope.model.Reference) || {};

					if (!$scope.filters.PropertyFilter) {
						$scope.filters.PropertyFilter = [];
					}

					if (!$scope.filters.Prefill) {
						$scope.filters.Prefill = [];
					}

					var url = _.filter(urls, { name: response.Data.Context });
					if (url && url[0]) {
						$scope.ddSelectSelected = url[0];
						if (url[0].name === 'report.view') {
							getReportNames(url[0].filters[0].key);
						}
						if (url[0].name === 'dashboard.view') {
							getDashboardNames(url[0].filters[0].key);
						}
						if (url[0].name === 'dwg.list') {
							getDrawingLoggedInDropdown();
							getAvailableDwgThemes();
						}
						if (url[0].name === 'cleaning.list') {
							getCleaningLoggedInDropdown();
						}

						updateWebListViews();
					}

				});

			$scope.profileTable = new ngTableParams({
				page: 1,
				count: 25,
				sorting: { IndexPosition: 'asc' },
				filter: { PropertyFilter: [{ Property: 'GuidWebMenu', Operator: '=', Value: $scope.model.Guid }] }
			},
				{
					total: 0,
					filterDelay: 10,
					counts: [],
					getData: function ($defer, params) {

						var columns = [];
						$scope.webprofileColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						repository.GetPaginated(repository.apiData.webProfileWebMenu.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns))
							.then(function (result) {
								$scope.profileTable.settings().total = result.TotalCount;
								$scope.profileTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
								function (error) {
									repository.growl(error, 'danger');
								});
					}
				});

		} else {
			$scope.ddSelectSelected = urls[0];
			updateWebListViews();

			if ($stateParams.guidEntityToCopy) {
				repository.getSingle(repository.apiData.webMenu.url, $stateParams.guidEntityToCopy).then(function (response) {
					delete response.Data.Guid;
					$scope.model = response.Data;
					$scope.model.Name += '-N';

					$scope.filters = JSON.parse($scope.model.Reference) || {};

					if (!$scope.filters.PropertyFilter) {
						$scope.filters.PropertyFilter = [];
					}

					if (!$scope.filters.Prefill) {
						$scope.filters.Prefill = [];
					}

					var url = _.filter(urls, { name: response.Data.Context });
					if (url && url[0]) {
						$scope.ddSelectSelected = url[0];
						if (url[0].name === 'report.view') {
							getReportNames(url[0].filters[0].key);
						}
						if (url[0].name === 'dashboard.view') {
							getDashboardNames(url[0].filters[0].key);
						}
						if (url[0].name === 'dwg.list') {
							getDrawingLoggedInDropdown();
							getAvailableDwgThemes();
						}
						if (url[0].name === 'cleaning.list') {
							getCleaningLoggedInDropdown();
						}
					}
				});
			}
		}

		$scope.updateMenu = function (destination) {

			$scope.filters.PropertyFilter = _.filter($scope.filters.PropertyFilter, function (item) {
				return item.Property !== '';
			});

			$scope.filters.Prefill.forEach(function (prefill) {
				delete prefill.data;
			});

			if ($scope.selectedWebListView) {
				$scope.filters.selectedWebListView = $scope.selectedWebListView;
			}

			$scope.model.Reference = angular.toJson($scope.filters);

			$scope.model.Context = $scope.ddSelectSelected.name;

			var success = function (result) {
				repository.growl($scope.update ? 'Meny har blitt oppdatert' : 'Meny har blitt opprettet', 'success');
				var guid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				menuService.getShortcuts();
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				repository.growl(error, 'danger');
			};

			if ($scope.update)
				repository.updateSingleDictionary(repository.apiData.webMenu.url, $scope.model).then(success).catch(error);
			else
				repository.createSingleDictionary(repository.apiData.webMenu.url, $scope.model).then(success).catch(error);

		};

		$scope.deleteWebMenu = function (destination) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-webmenu-message', 'Webmenyen og referanser vil bli slettet fra plania!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-webmenu-button-confirm', 'Ja, fjern webmenyen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.webMenu.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-webmenu-success', 'Webmenyen ble fjernet!'), result, "success");
						$scope.goBack(destination, { menuGuid: $scope.navigation.params.menuGuid });
					});
			});

		};

		$scope.addProfile = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Legg til web profil',
							url: repository.apiData.webProfile.url,
							columns: [
								{ Title: translationService.translate('web-webProfile-id'), Property: 'Id', PropertyType: 'string' },
								{ Title: translationService.translate('web-webProfile-description'), Property: 'Description', PropertyType: 'string' },
							],
							useObjects: false
						};
					}
				}
			}).result.then(function (guids) {
				var model = [];
				var index = $scope.profileTable.data.length;
				guids.forEach(function (guid) {
					model.push({ GuidWebMenu: $scope.model.Guid, GuidWebProfile: guid, IndexPosition: index++ });
				});

				repository.createMultipleWithGuid(repository.apiData.webProfileWebMenu.url, $scope.model.Guid, JSON.stringify(model)).then(function (result) {
					$scope.profileTable.reload();
					menuService.getShortcuts();
				});
			},
				function () {
				});
		};

		$scope.removeProfile = function (webProfileWebMenu) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-webmenu-webprofile-message', 'Menyprofilen vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-webmenu-webprofile-button-confirm', 'Ja, fjern menyprofilen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.webProfileWebMenu.url, webProfileWebMenu.Guid)
					.then(function (result) {

						swal(translationService.translate('web-swal-webmenu-webprofile-success', 'Menyprofilen ble fjernet!'), result, "success");
						repository.growl('menyProfilen har blitt fjernet', 'success');
						$scope.profileTable.reload();
						menuService.getShortcuts();
					});
			});
		};

		$scope.changedPrefillProperty = function (prefill) {
			prefill.Value = null;
			var prefillColumn = _.find($scope.prefillColumns, function (o) { return o.Property === prefill.Property; });
			prefill.PropertyType = prefillColumn.PropertyType;
			prefill.IsExpandable = prefillColumn.IsExpandable;
			if (prefill.PropertyType === 'bool') {
				prefill.Value = true;
			}
		};

		$scope.changedAutoCompleteValue = function (value) {
			if (!value)
				value = {};
		};

		$scope.getTypeOf = function (value) {
			if (value === null) {
				value = {};
			}

			return typeof (value);
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "activitycategory":
					if ($scope.model.Context.startsWith('request.'))
						return { PropertyFilter: [{ Property: 'UseInRequest', Operator: '=', Value: 'true' }, { Property: 'IsAbstract', Operator: '=', Value: '0' }] };
					else if ($scope.model.Context.startsWith('workOrder.'))
						return { PropertyFilter: [{ Property: 'UseInWorkOrder', Operator: '=', Value: 'true' }, { Property: 'IsAbstract', Operator: '=', Value: '0' }] };
					return filter;
			}
			return filter;
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
