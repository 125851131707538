(function () {

	/*
		Plania Entity Navigation
		Directive used to insert a hyperlink to plania entity based on if the user has read rights or not.
		Inserts normal text if user does not have read access to entity.
		*/
	angular.module('Plania').directive('plEntityNavigation', ['Repository',
		function controller(repository) {
			return {
				restrict: 'E',
				scope: {
					entity: '=entity',
					guid: '=guid',
					model: '=model',
					context: '=context'
				},
				templateUrl: 'app/common/directives/views/entityNavigation.html',
				link: function (scope, element, attrs) {
					var apiData = _.find(repository.apiData, function (o) { return o.prefix === scope.entity && (scope.context ? o.contextId === scope.context : true); });

					scope.navigationState = apiData.navigationState;
					scope.canNavigate = false;

					scope.$watch('guid', function (newValue, oldValue) {
						if (newValue)
							scope.canNavigate = scope.$parent.$root.hasReadAccess(scope.entity) && repository.enableWebNavigation;
						else
							scope.model = null;
					});
				}
			};
		}
	]);
})();

