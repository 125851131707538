(function () {

	var app = angular.module('Plania');

	app.directive('projectDocuments', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				reloadAlarmLogTable: '=reloadAlarmLogTable',
				viewMode: '=viewMode',
				update: '=update',
				restrictEdit: '=restrictEdit',
				isModelLoading: '=isModelLoading'
			},
			controller: ['$scope', '$rootScope', 'Repository', 'TranslationService','DocumentService', '$modal', 'ListService', 'ngTableParams', controller],
			templateUrl: 'app/project/views/projectDocumentsDirective.html'
		};
	});

	function controller($scope, $rootScope, repository, translationService, documentService, $modal, listService, ngTableParams) {
		$scope.rootScope = $rootScope;
		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.projectDocumentsColumns = [
			{ Position: 1, Title: translationService.translate('web-document-id'), Property: 'Document.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-document-description'), Property: 'Document.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-document-filename'), Property: 'Document.FilePath', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-document-documentType'), Property: 'Document.DocumentType.Id', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-document-documentCategory'), Property: 'Document.DocumentCategory.Description', PropertyType: 'string' },
		];

		$scope.showProjectDocuments = true;
		$scope.showWorkOrderDocuments = true;
		$scope.showPurchaseOrderDocuments = true;
		$scope.showDeviationDocuments = true;
		$scope.showConditionDocuments = true;
        $scope.documentSearch = {};
		$scope.projectDocumentsTable = new ngTableParams({
			page: 1,
			count: 20,
			sorting: { 'Document.Description': 'asc' },
			filter: { GuidProject:  $scope.model.Guid }
		},
			{
				total: 0,
				filterDelay: 10,
				counts: [10, 20, 50],
				getData: function ($defer, params) {
					var cols = [];
					$scope.projectDocumentsColumns.forEach(function (col) {
						cols.push(col.Property);
					});
					var filter = $scope.projectDocumentsTable.filter();
					filter.ChildDocumentsOnProject = [];

					if ($scope.showProjectDocuments)
						filter.ChildDocumentsOnProject.push(repository.commonService.prefix.Project);
					if ($scope.showWorkOrderDocuments)
						filter.ChildDocumentsOnProject.push(repository.commonService.prefix.WorkOrder);
					if ($scope.showPurchaseOrderDocuments)
						filter.ChildDocumentsOnProject.push(repository.commonService.prefix.PurchaseOrder);
					if ($scope.showDeviationDocuments)
						filter.ChildDocumentsOnProject.push(repository.commonService.prefix.Deviation);
					if ($scope.showConditionDocuments)
						filter.ChildDocumentsOnProject.push(repository.commonService.prefix.Condition);


					repository.GetPaginated(repository.apiData.documentXEntity.url,
						params.page() - 1,
						params.count(),
						params.sorting(),
						params.filter(),
						'',
						JSON.stringify(cols))
						.then(function (result) {
							$scope.projectDocumentsTable.settings().total = result.TotalCount;
							$scope.projectDocumentsTable.settings().filterDelay = 500;
                            $defer.resolve(result.List);
						}
						);
				}
			});


		var setFiltername = function() {
			var activeFilters = [];
			if ($scope.showProjectDocuments)
				activeFilters.push(translationService.translate('web-enum-prefix-project', 'Prosjekt'));
			if ($scope.showWorkOrderDocuments)
				activeFilters.push(translationService.translate('web-enum-prefix-workorder', 'Arbeidsordre'));
			if ($scope.showPurchaseOrderDocuments)
				activeFilters.push(translationService.translate('web-enum-prefix-purchaseorder', 'Bestilling'));
			if ($scope.showDeviationDocuments)
				activeFilters.push(translationService.translate('web-enum-prefix-deviation', 'Avvik'));
			if ($scope.showConditionDocuments)
				activeFilters.push(translationService.translate('web-enum-prefix-condition', 'Tilstand/Tiltak'));

			$scope.selectedFilterName = activeFilters.join(', ');
		};

		$scope.SetChildOptions = function (option) {
			switch (option) {
				case 'Project':
					$scope.showProjectDocuments = !$scope.showProjectDocuments;
					break;
				case 'WorkOrder':
					$scope.showWorkOrderDocuments = !$scope.showWorkOrderDocuments;
					break;
				case 'PurchaseOrder':
					$scope.showPurchaseOrderDocuments = !$scope.showPurchaseOrderDocuments;
					break;
				case 'Deviation':
					$scope.showDeviationDocuments = !$scope.showDeviationDocuments;
					break;
				case 'Condition':
					$scope.showConditionDocuments = !$scope.showConditionDocuments;
					break;
			}
			setFiltername();
			$scope.projectDocumentsTable.reload();
		};
		setFiltername();
		var resolveParams = function () {
			return {
				guidProject: $scope.model.Guid
			};
		};

		$scope.addNewDocument = function () {
			$modal.open({
				templateUrl: 'app/document/views/createDocumentModal.html',
				controller: 'CreateDocumentXEntityController',
				size: 'lg',
				resolve: {
					params: resolveParams
				}
			}).result.then(function () {
				$scope.projectDocumentsTable.reload();

			}, function (msg) {

			});
		};

		$scope.addExistingDocument = function () {

			$modal.open({
				templateUrl: 'app/document/views/addExistingDocument.html',
				controller: 'AddExistingDocumentController',
				resolve: {
					params: resolveParams
				}
			}).result.then(function () {
				$scope.projectDocumentsTable.reload();

			}, function (msg) {
				//cancel

			});
		};

		$scope.removeDocument = function (document) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-document-message', "Dokumentet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-document-button-confirm', 'Ja, fjern dokumentet'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.documentXEntity.url, document.Guid).then(function (result) {
					swal(translationService.translate('web-swal-document-success', 'Dokumentet ble fjernet!'), result, "success");
					repository.growl('Dokumentet har blitt fjernet', 'success');
					$scope.projectDocumentsTable.reload();
				});
			});
		};

        $scope.canDeleteFromProject = function (document) {
			if($scope.model.ClosedDate)
				return false;
			var checkOtherDO = document.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
			if (document.GuidProject)
				return $rootScope.hasDeleteAccess('DocumentXEntity', 'Project', checkOtherDO);
			else if (document.GuidWorkOrder)
				return $rootScope.hasDeleteAccess('DocumentXEntity', 'WorkOrder', checkOtherDO);
			else if (document.GuidPurchaseOrder)
				return $rootScope.hasDeleteAccess('DocumentXEntity', 'PurchaseOrder', checkOtherDO);
			else if (document.GuidDeviation)
				return $rootScope.hasDeleteAccess('DocumentXEntity', 'Deviation', checkOtherDO);
			else if (document.GuidCondition)
				return $rootScope.hasDeleteAccess('DocumentXEntity', 'Condition', checkOtherDO);

			return false;
		};

        $scope.canEditFromProject = function (document) {
            var checkOtherDO = document.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
            if (document.GuidProject)
                return $rootScope.hasEditAccess('DocumentXEntity', 'Project', checkOtherDO);
            else if (document.GuidWorkOrder)
                return $rootScope.hasEditAccess('DocumentXEntity', 'WorkOrder', checkOtherDO);
            else if (document.GuidPurchaseOrder)
                return $rootScope.hasEditAccess('DocumentXEntity', 'PurchaseOrder', checkOtherDO);
            else if (document.GuidDeviation)
                return $rootScope.hasEditAccess('DocumentXEntity', 'Deviation', checkOtherDO);
            else if (document.GuidCondition)
                return $rootScope.hasEditAccess('DocumentXEntity', 'Condition', checkOtherDO);

            return false;
        };

		$scope.editDocument = function (document) {
			$modal.open({
				templateUrl: 'app/document/views/editDocumentMetadataModal.html',
				size: 'lg',
				controller: 'EditDocumentController',
				resolve: {
					$stateParams: function () { return { guid: document.GuidDocument, isModal: true }; } //feil guid ???
				}
			}).result.then(function () {
				$scope.projectDocumentsTable.reload();
				$scope.isCollapsed = false;
			}, function (reloadTable) {
				//cancel
				if (reloadTable) {
					$scope.projectDocumentsTable.reload();
				}
				$scope.isCollapsed = false;
			});
		};

		$scope.openDocument = function(document){
			documentService.openDocument(document.Document).then(function(result){
				$scope.isCollapsed = false;
			});
		};

		var getMenuLink = function (url, icon, text) {
			return {
				html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" ><i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ' + text + '</a>',
				isHref: true
			};
		};

        $scope.menuOptions = function (projectDocument) {
			var options = [];

			if (!projectDocument.GuidProject && repository.enableWebNavigation) {
				if (projectDocument.GuidWorkOrder) {
					options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: projectDocument.GuidWorkOrder }), 'zmdi-case', translationService.translate('web-project-open-workorder', 'Åpne arbeidsordre')));
				}
				else if (projectDocument.GuidPurchaseOrder) {
					options.push(getMenuLink($rootScope.navigation.href('purchaseOrder.edit', { guid: projectDocument.GuidPurchaseOrder }), 'zmdi-shopping-cart', translationService.translate('web-project-open-purchaseorder', 'Åpne bestilling')));
				}
				else if (projectDocument.GuidDeviation) {
					options.push(getMenuLink($rootScope.navigation.href('deviation.edit', { guid: projectDocument.GuidDeviation }), 'zmdi-alert-octagon', translationService.translate('web-project-open-deviation', 'Åpne avvik')));
				}
				else if (projectDocument.GuidCondition) {
					options.push(getMenuLink($rootScope.navigation.href('condition.edit', { guid: projectDocument.GuidCondition }), 'zmdi-plaster', translationService.translate('web-project-open-condition', 'Åpne tiltak')));
                }
            }

            options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href="" ng-click="openDocument(projectDocument)"><i class="zmdi zmdi-file-text zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-list-document-show', 'Vis dokument') + '</a>',
                compile: true
            });

            if ($scope.canEditFromProject(projectDocument)) {
                options.push({
                    html: '<a class="dropdown-item" tabindex= "-1" href="" ng-click="editDocument(projectDocument)"><i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-button-edit', 'Rediger') + '</a>',
                    compile: true
                });
            }

            if ($scope.canDeleteFromProject(projectDocument)) {
                options.push({
                    html: '<a class="dropdown-item" tabindex= "-1" href="" ng-click="removeDocument(projectDocument)"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-button-delete', 'Slett') + '</a>',
                    compile: true
                });
            }

            return options;
        };

	}
})();
