(function () {
	angular.module('Plania').controller('ControlListRequestConfirmationController', ['$scope', 'Repository', 'params', 'EnumService', '$modalInstance', 'TranslationService', '$q', 'RegisteredFieldService', '$timeout', controller]);

	function controller($scope, repository, params, enumService, modalInstance, translationService, $q, registeredFieldService, $timeout) {
		$scope.requests = params.requests;
		$scope.inWorkOrder = params.inWorkOrder;
		$scope.reload = false;
		$scope.viewMode = false;
		$scope.activateAutocomplete = true;
		$scope.isEdit = false;
		$scope.selectedRequestDto = {};
		$scope.selectedRequest = null;
		$scope.parentEntity = 'WorkOrder';
		$scope.isPreview = params.isPreview;
		var userData = repository.authService.getUserData();

		$scope.mandatoryFieldRules = [];

		var registeredFieldsPromise = registeredFieldService.getRegisteredField(repository.commonService.prefix.Request);
		registeredFieldsPromise.then(function (result) {
			$scope.mandatoryFieldRules = _.filter(result, { Mandatory: true });
		});

		$scope.hasMissingProperties = function (request) {
			if (!$scope.mandatoryFieldRules) return false;

			return  _.some($scope.mandatoryFieldRules, function (fieldRule) {
				return fieldRule.FieldName.toUpperCase() !== "ID" && !request[fieldRule.DomainFieldName];
			});
		};

		$scope.isMandatoryOrPrefill = function(domainFieldName){
			if (!domainFieldName) return false;

			if (_.some($scope.requests, function (request){
				return request[domainFieldName];
			})) return true;

			return _.some($scope.mandatoryFieldRules, function (rule){
				return rule.DomainFieldName === domainFieldName;
			});
		};

		$scope.requests.forEach(function (request) {
			registeredFieldsPromise.then(function () {
				if (request.GuidEquipment && !request.GuidBuilding) {
					var equipmentColumns = ['Building.Id', 'Building.Description'];
					repository.getSingle(repository.apiData.equipment.url, request.GuidEquipment, JSON.stringify(equipmentColumns)).then(function (response) {
						var equipment = response.Data;

						request.GuidBuilding = equipment.GuidBuilding;
						request.Building = equipment.Building.Caption;
					}, function (error) {

					});
				}
				request.EmailAddress = userData.email;
				request.Telephone = userData.phone;
				request.ContactPerson = userData.guidPerson ? userData.personFirstName + ' ' + userData.personLastName : userData.realName;

				if (userData.guidDepartment) {
					request.GuidDepartment = userData.guidDepartment;
					request.Department = {
						Guid: userData.guidDepartment,
						Name: userData.userDepartment,
						Caption: userData.userDepartment
					};
					$scope.department = request.Department.Caption;
				}
			});
		});

		$scope.removeRequest = function (index) {
			$scope.requests.splice(index, 1);
			if ($scope.requests.length > 0) $scope.selectRequest($scope.requests[0]);
			else if ($scope.requests.length < 1) $scope.selectedRequest = null;
		};

		$scope.autoCompleteFilter = function (filterName) {
			switch (filterName) {
				case "equipment":
					return { GuidBuilding: $scope.selectedRequest.GuidBuilding, IsApparatus: false, PropertyFilter: [{ Property: 'IsTemplate', Operator: '=', Value: false }] };
				case "area":
					return { GuidBuilding: $scope.selectedRequest.GuidBuilding, GuidDrawing: $scope.selectedRequest.GuidDrawing };
				case "person":
					return {};
				case "responsiblePerson":
					return { GuidBuildingResponsible: $scope.selectedRequest.GuidBuilding };
				case "priority":
					return { PropertyFilter: [{ Property: 'EntityType', Operator: '<>', Value: 'Condition' }] };
				case "cause":
					return {};
				case "resourceGroup":
					return { PropertyFilter: [{ Property: 'DoesMaintenanceTasks', Operator: '=', Value: 'true' }] };
			}
		};

		$scope.selectRequest = function (request) {
			$scope.activateAutocomplete = false;

			request.hasBeenViewed = true;

			$scope.selectedRequest = request;

			if (!$scope.selectedRequest.GuidBuilding) $scope.selectedRequest.Building = null;
			if (!$scope.selectedRequest.GuidEquipment) $scope.selectedRequest.Equipment = null;
			if (!$scope.selectedRequest.GuidArea) $scope.selectedRequest.Area = null;
			if (!$scope.selectedRequest.GuidResponsiblePerson) $scope.selectedRequest.ResponsiblePerson = null;

			if ($scope.selectedRequest.GuidBuilding && $scope.selectedRequest.Building && !$scope.selectedRequest.Building.Caption)
				$scope.selectedRequest.Building.Caption = $scope.selectedRequest.Building.Id + ' - ' + $scope.selectedRequest.Estate.Description;
			if ($scope.selectedRequest.GuidEquipment && $scope.selectedRequest.Equipment && !$scope.selectedRequest.Equipment.Caption)
				$scope.selectedRequest.Equipment.Caption = $scope.selectedRequest.Equipment.Id + ' - ' + $scope.selectedRequest.Equipment.Description;
			if ($scope.selectedRequest.GuidArea && $scope.selectedRequest.Area && !$scope.selectedRequest.Area.Caption)
				$scope.selectedRequest.Area.Caption = $scope.selectedRequest.Area.Id + ' - ' + $scope.selectedRequest.Area.Description;

			// TODO - Fix Person1 to ResponsiblePerson i 8.18
			if ($scope.selectedRequest.GuidResponsiblePerson && $scope.selectedRequest.Person1) {
				$scope.selectedRequest.ResponsiblePerson = {
					Guid: $scope.selectedRequest.GuidResponsiblePerson,
					Caption: $scope.selectedRequest.Person1.Caption
				};
			}

			$timeout(function () { $scope.activateAutocomplete = true; }, 350);
		};

		$scope.selectNextRequest = function () {
			var currentRequestIndex = _.findIndex($scope.requests, function (request) { return request === $scope.selectedRequest; });

			if (currentRequestIndex + 1 === $scope.requests.length) {
				$scope.selectRequest($scope.requests[0]);
			} else {
				$scope.selectRequest($scope.requests[currentRequestIndex + 1]);
			}
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.selectedRequest.GuidBuilding = "";
				return;
			}

			if ($scope.selectedRequest.GuidBuilding !== building.Guid) {
				$scope.selectedRequest.GuidEquipment = "";
				$scope.selectedRequest.Equipment = null;
				$scope.selectedRequest.GuidArea = "";
				$scope.selectedRequest.Area = null;
			}

			$scope.selectedRequest.GuidBuilding = building.Guid;
		};

		$scope.onEquipmentSelect = function (equipment) {
			if (!equipment) {
				$scope.selectedRequest.GuidEquipment = "";
				return;
			}
			$scope.selectedRequest.GuidEquipment = equipment.Guid;

			if (!$scope.selectedRequest.GuidBuilding || $scope.selectedRequest.GuidBuilding === "") {
				$scope.selectedRequest.GuidBuilding = equipment.GuidBuilding;
				$scope.selectedRequest.Building = equipment.Building;
			}

			if (equipment.GuidArea) {
				$scope.selectedRequest.GuidArea = equipment.GuidArea;
				$scope.selectedRequest.Area = equipment.Area;
			}
		};

		$scope.onAreaSelect = function (area) {
			if (!area) {
				$scope.selectedRequest.GuidArea = "";
				$scope.selectedRequest.Area = null;
				return;
			}

			$scope.selectedRequest.GuidArea = area.Guid;

			if (!$scope.selectedRequest.GuidBuilding || $scope.selectedRequest.GuidBuilding === "") {
				$scope.selectedRequest.GuidBuilding = area.GuidBuilding;
				$scope.selectedRequest.Building = area.Building;
			}
		};

		$scope.onPrioritySelect = function (priority) {
			if (priority) {
				$scope.selectedRequest.GuidPriority = priority.Guid;
				if (priority.HasDeadline) {
					$scope.selectedRequest.DueDate = enumService.getTimeUnitDate(new Date(), priority.DeadlinePeriodUnit, priority.DeadlinePeriodNumber);
					$scope.restrictDueDate = true;
				} else {
					$scope.restrictDueDate = false;
				}
			}
		};

		$scope.onResponsiblePersonSelect = function (person) {
			if (!person) {
				$scope.selectedRequest.GuidResponsiblePerson = "";
				$scope.selectedRequest.ResponsiblePerson = null;
				return;
			}

			$scope.selectedRequest.GuidResponsiblePerson = person.Guid;
		};

		$scope.createRequests = function (destination) {
			if ($scope.requests.length > 0 && !$scope.isPreview) {
				var requests = [];

				$scope.requests.forEach(function (request) {
					if (!request.Guid)
						requests.push(JSON.stringify(getRequestInfo(request)));
				});

				repository.createMultiple(repository.apiData.request.url, requests, 'returnAll=true').then(function (response) {
					var hasError = false;

					// Assumption -> Returned list is same order as the local list
					for (var i = response.length - 1; i >= 0; i--) {
						if (response[i].IsSuccess) {
							$scope.requests.splice(i, 1);
						}
						else {
							hasError = true;
							repository.growl(response[i].Message, 'danger');
						}
					}
					if (!hasError) {
						repository.growl(translationService.translate('web-request-create-success', 'Meldingene ble opprettet'), 'success');
						modalInstance.close({ destination: destination, guid: params.workOrder ? params.workOrder.Guid : null });
					}
				});
			} else {
				modalInstance.close({ destination: destination, guid: params.workOrder ? params.workOrder.Guid : null });
			}
		};

		var getRequestInfo = function (request) {
			var postItem = angular.copy(request);
			var postImages = [];

			if (request.Images && request.Images.length > 0) {
				request.Images.forEach(function (image) {
					var postImage = angular.copy(image);
					delete postImage.Guid;
					delete postImage.GuidControlListItemAnswer;
					delete postImage.ControlListItemAnswer;
					delete postImage.GuidControlListXEntity;
					delete postImage.ControlListXEntity;
					postImages.push(postImage);
				});
			}

			postItem.ImageXEntity = postImages;

			postItem.RegisteredDate = new Date().toISOString();
			delete postItem.Building;
			delete postItem.Equipment;
			delete postItem.Area;
			delete postItem.Images;
			delete postItem.IsMandatory;

			return postItem;
		};

		$scope.cancel = function () {
			modalInstance.dismiss('cancel');
		};
	}
})();
