(function () {
	var app = angular.module('Plania');
	app.directive('buildingInfoDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate',
				reloadOperationalMessages: '=reloadOperationalMessages',
				reloadDocumentTable: '=reloadDocumentTable',
				activateAutocomplete: '=activateAutocomplete',
				currentTab: '=currentTab',
				formReference: '=',
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', '$modal', '$rootScope', 'ListService', '$timeout', 'RegisteredFieldService', controller],
			templateUrl: 'app/building/views/buildingInfo.html',
		};
	});

	function controller($scope, ngTableParams, repository, translationService, $modal, $rootScope, listService, $timeout, registeredFieldService) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.imageApiUrl = $rootScope.imageApiUrl;
		$scope.userInfo = $rootScope.userInfo;
		$scope.customization = $rootScope.customization;

		$scope.isDynamicPropertiesCollapsed = false;
		$scope.isDocumentsCollapsed = true;
		$scope.viewMode = $scope.isUpdate;
		$scope.hasPageLoaded = $scope.currentTab === 'info';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'info' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
					}
				}, 250);
			});
		}

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		$scope.getPropertyValue = function (building, column) {
			return listService.GetPropertyValue(building, column);
		};

		$scope.onPostCodeSelect = function (postalInfo) {
			if (!postalInfo) {
				$scope.model.GuidPost = null;
				$scope.model.PostalArea = '';
				$scope.model.County = '';
				$scope.model.Municipality = '';
			} else {
				$scope.model.PostalCode = postalInfo.ZipCode;
				$scope.model.GuidPost = postalInfo.Guid;
				$scope.model.PostalArea = postalInfo.PostalArea;
				$scope.model.County = postalInfo.County;
				$scope.model.Municipality = postalInfo.MunicipalityName;
				$scope.postalInfo = postalInfo;
			}
		};

		$scope.onBuildingCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidBuildingCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidBuildingCategory = category.Guid;
		};

		$scope.onFileSelect = function (images) {
			if (!images || images.length === 0) return;

			var files = [];
			handleFileUpload(images[0]);
			//Read Base64 string and add to file object
			function handleFileUpload(file) {
				var reader = new FileReader();
				reader.onload = function (e) {
					var newFile = {
						ImageSrc: e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length),
						Name: file.name,
						GuidBuilding: $scope.model.Guid
					};
					files.push(newFile);
					onLoadEndHandler();
				};
				reader.readAsDataURL(file);
			}

			function onLoadEndHandler(image) {
				if ($scope.model.GuidBuildingImage) {
					repository.deleteSingle(repository.apiData.image.url, $scope.model.GuidBuildingImage).then(function (result) {
						console.log(result);
					});
				}

				repository.createMultiple(repository.apiData.image.url, files).then(function (result) {
					$scope.model.GuidBuildingImage = result[0].Guid;

				}, function (error) {
					repository.growl(error);
				});
			}
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "person":
					filter.PropertyFilter = [{ Property: 'IsOperationsManager', Operator: "=", Value: true }];
					return filter;
				case "referenceData":
					filter.PropertyFilter = [
						{ Property: "DisabledFromDate", Operator: ">", Value: new Date().toISOString() },
						{ Property: "DisabledFromDate", Operator: "=", Value: null, Operand: "OR" },
						{ Property: 'ReferenceType.Context', Operator: "=", Value: 1 },
					];
					return filter;
			}
		};

		$scope.supplierInfo = new function () {
			var me = this;

			this.showSupplierLineOfBusiness = false;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-supplier-id'), Property: 'Supplier.Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-supplier-description'), Property: 'Supplier.Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-supplier-supplierLineOfBusiness', 'Bransje'), Property: 'Supplier.SupplierLineOfBusiness.Id', PropertyType: 'string'},
				{ Position: 4, Title: translationService.translate('web-supplier-address1'), Property: 'Supplier.Address1', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-supplier-postalcode'), Property: 'Supplier.PostalCode', PropertyType: 'string' },
				{ Position: 6, Title: translationService.translate('web-supplier-postalarea'), Property: 'Supplier.PostalArea', PropertyType: 'string' }
			];

			this.initRegisteredFields = function () {
				registeredFieldService.getFieldRule(repository.commonService.prefix.Supplier, 'GuidSupplierLineOfBusiness').then(function (result) {
					me.showSupplierLineOfBusiness = !result.Hidden && !($scope.userInfo.isExternalUser && result.ExternallyHidden);
				});
			};

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams(
					{
						page: 1,
						count: 10,
						sorting: { 'Supplier.Id': 'asc' },
						filter: { PropertyFilter: [{ Property: "GuidBuilding", Operator: "=", value: $scope.model.Guid }] }
					},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						paginationMaxBlocks: 6,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							columns.push('Supplier.Guid');
							columns.push('Supplier.GuidSupplierLineOfBusiness');

							repository.GetPaginated(repository.apiData.buildingSupplier.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
								me.table.settings().total = result.TotalCount;
								me.table.settings().filterDelay = 500;
								$defer.resolve(result.List);
							}, function (error) {
								repository.growl(error, 'danger');
							});
						}
					});
			};

			this.add = function () {
				$modal.open({
					templateUrl: 'app/common/views/multipleSelectModal.html',
					controller: 'MultipleSelectModalController',
					resolve: {
						modalParams: function () {
							return {
								title: 'Velg leverandører',
								columns: [
									{ Title: translationService.translate('web-supplier-id'), Property: 'Id' },
									{ Title: translationService.translate('web-supplier-description'), Property: 'Description' }
								],
								useObjects: false,
								url: repository.apiData.supplier.url,
								sorting: { Id: 'asc' },
								filter: {},
								alreadySavedGuids: function () {
									var selectedGuids = [];
									me.table.data.forEach(function (entity) {
										selectedGuids.push(entity.GuidSupplier);
									});
									return selectedGuids;
								}()
							};
						}
					}
				}).result.then(function (selectedEntities) {
					var buildingXSupplier = [];
					selectedEntities.forEach(function (entity) {
						buildingXSupplier.push({ GuidBuilding: $scope.model.Guid, GuidSupplier: entity });
					});
					repository.createMultipleWithGuid(repository.apiData.buildingSupplier.url, $scope.model.Guid, JSON.stringify(buildingXSupplier)).then(function (result) {
						repository.growl('Leverandør(er) har blitt latt til bygg', 'success');
						me.table.reload();
					});
				});
			};

			this.remove = function (supplier) {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-building-supplier-message', "Leverandøren vil bli permanent fjernet!"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-building-supplier-button-confirm', 'Ja, fjern leverandøren'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;

					repository.deleteSingle(repository.apiData.buildingSupplier.url, supplier.Guid).then(function (result) {
						swal(translationService.translate('web-swal-building-supplier-success', 'Leverandøren ble fjernet!'), result, "success");
						repository.growl('Personen har blitt fjernet', 'success');
						me.table.reload();
					});
				});
			};

			this.canEdit = function (workOrderXSparePart) {
				return workOrderXSparePart &&
					$scope.hasEditAccess('WorkOrderXSparePart');
			};

			this.canDelete = function (workOrderXSparePart) {
				return workOrderXSparePart &&
					$scope.hasDeleteAccess('WorkOrderXSparePart');
			};
			this.initTable();
			this.initRegisteredFields();
		}();

		$scope.buildingPersonInfo = new function () {
			var me = this;
			this.isLoading = false;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-person-employeenr'), Property: 'Person.EmployeeNr', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-person-firstname'), Property: 'Person.FirstName', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-person-lastname'), Property: 'Person.LastName', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-person-position'), Property: 'Person.Position', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-resourcegroup-id'), Property: 'Person.ResourceGroup.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidResourceGroup', AutocompleteService: 'resourceGroup' },
				{ Position: 6, Title: translationService.translate('web-person-telephoneWork'), Property: 'Person.TelephoneWork', PropertyType: 'string' },
				{ Position: 7, Title: translationService.translate('web-person-cellphone'), Property: 'Person.Cellphone', PropertyType: 'string' },
				{ Position: 8, Title: translationService.translate('web-person-email'), Property: 'Person.Email', PropertyType: 'string' },
				{ Position: 9, Title: translationService.translate('web-personrole-id'), Property: 'PersonRole.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidPersonRole', AutocompleteService: 'personRole' }
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams(
					{
						page: 1,
						count: 10,
						sorting: { 'Person.FirstName': 'asc' },
						filter: { PropertyFilter: [{ Property: "GuidBuilding", Operator: "=", Value: $scope.model.Guid }] }
					},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});
							columns.push('Person.Guid');
							this.isLoading = true;
							repository.GetPaginated(repository.apiData.buildingPerson.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
								this.isLoading = false;
								me.table.settings().total = result.TotalCount;
								me.table.settings().filterDelay = 500;
								$defer.resolve(result.List);
							}, function (error) {
								this.isLoading = false;
								repository.growl(error, 'danger');
							});
						}
					});
			};

			this.add = function () {
				$modal.open({
					templateUrl: 'app/buildingPerson/views/createBuildingPerson.html',
					controller: 'BuildingPersonController',
					resolve: {
						params: function () {
							return {
								GuidBuilding: $scope.model.Guid,
								selectedEntityGuids: _.map(me.table.data, function (row) {
									return { GuidPerson: row.GuidPerson, GuidBuilding: row.GuidBuilding, GuidPersonRole: row.GuidPersonRole };
								}),
								buildingCaption: $scope.model.Id + ' - ' + $scope.model.Description,
								isModal: true
							};
						}
					}
				}).result.then(function (selectedPersons) {
					me.table.reload();
					$scope.isPersonCollapsed = false;
				}, function () {
					$scope.isPersonCollapsed = false;
				});
			};

			this.remove = function (buildingXPerson) {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-building-person-message', "Personen vil bli permanent fjernet!"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-building-person-button-confirm', 'Ja, fjern personen'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;

					repository.deleteSingle(repository.apiData.buildingPerson.url, buildingXPerson.Guid).then(function (result) {
						swal('Suksess!', translationService.translate('web-swal-building-person-success', 'Personen ble fjernet!'), "success");
						repository.growl('Personen har blitt fjernet', 'success');
						me.table.reload();
					});
				});
			};

			this.canEdit = function (workOrderXSparePart) {
				return workOrderXSparePart &&
					$scope.hasEditAccess('WorkOrderXSparePart');
			};

			this.canDelete = function (workOrderXSparePart) {
				return workOrderXSparePart &&
					$scope.hasDeleteAccess('WorkOrderXSparePart');
			};
			this.initTable();
		}();

		$scope.contractInfo = new function () {
			var me = this;
			this.isLoading = false;

			this.columns = [
				'Id',
				'Description',
				'Customer.Id',
				'Customer.Description',
				'StartDate',
				'EndDate',
				'Status'
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams(
					{
						page: 1,
						count: 10,
						sorting: { 'Id': 'desc' },
						filter: { PropertyFilter: [{ Property: "ContractItems.GuidBuilding", Operator: "=", Value: $scope.model.Guid }, { Property: 'ContractItems.IsCurrentVersion', Operator: '=', Value: 'true' }] }
					},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						paginationMaxBlocks: 6,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col);
							});

							this.isLoading = true;
							repository.GetPaginated(repository.apiData.contract.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
								this.isLoading = false;
								me.table.settings().total = result.TotalCount;
								me.table.settings().filterDelay = 500;
								$defer.resolve(result.List);
							}, function (error) {
								this.isLoading = false;
								repository.growl(error, 'danger');
							});
						}
					});
			};
			this.initTable();
		}();

		$scope.getBuildingTypeColumns = function () {
			return [
				{ Title: translationService.translate('web-buildingType-indexPosition', 'Indeks'), Property: 'IndexPosition' },
				{ Title: translationService.translate('web-buildingType-id', 'Id'), Property: 'Id' },
				{ Title: translationService.translate('web-buildingType-description', 'Beskrivelse'), Property: 'Description' },
			];
		};

		$scope.openBuildingTypeInfoModal = function () {
			if (!$scope.model.GuidBuildingType) return;

			$modal.open({
				templateUrl: 'app/referenceData/views/referenceDataInfoModal.html',
				controller: 'ReferenceDataInfoModalController',
				resolve: {
					modalParams: function () {
						return {
							guid: $scope.model.GuidBuildingType,
							initialModel: $scope.model.BuildingType
						};
					}
				},
				size: 'lg'
			});
		};
	}
})();
