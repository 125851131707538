(function () {
	angular.module('Plania').controller('CreateDocumentController', ['$scope', 'Repository', '$modalInstance', 'TranslationService', controller]);

	function controller($scope, repository, $modalInstance, translationService) {
		var xrefProperty, xrefGuid;

		$scope.isDynamicPropertiesCollapsed = false;
		$scope.model = {};

		$scope.createDocumentXEntity = false;

		var selectedDataOwner = repository.commonService.getFilterData().selectedDataOwner;
		if (selectedDataOwner.GuidDefaultDocumentType) {
			$scope.model.DocumentType = { Guid: selectedDataOwner.GuidDefaultDocumentType, Caption: selectedDataOwner.DefaultDocumentTypeId };
			$scope.model.GuidDocumentType = selectedDataOwner.GuidDefaultDocumentType;
		}

		$scope.files = [];
		$scope.onFileSelect = function ($files) {
			$scope.files = $scope.files.concat($files);
		};


		$scope.checkbox = {
			isRegulatoryCompilanceDocumentation: false,
			isAttestationDocumentation: false,
			isDescriptionDocumentation: false,
			isOperationDocumentation: false,
			isMaintenanceDocumentation: false,
			isProductInfoDocumentation: false,
			isInstructionDocumentation: false,
			isProtocolDocumentation: false,
			isIdentificationDocumentation: false,
			isDrawingDocumentation: false,
			isImageDocumentation: false,
		};

		var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
		if (webMenuParams && webMenuParams.Prefill) {
			var getCategoryPrefill = function (guid) {
				var guidCategory = guid || $scope.model.GuidDocumentCategory;
				if (!guidCategory) return;
				repository.getSingle(repository.apiData.documentCategory.url, guidCategory).then(function (result) {
					$scope.model.DynamicProperty = result.Data.DynamicProperty;
				}, function (error) {
					repository.growl(error, 'danger');
				});
			};

			webMenuParams.Prefill.forEach(function (prefill) {
				if (typeof (prefill.Value) === 'object') {
					switch (prefill.Property) {
						case 'DocumentCategory':
							$scope.model.DocumentCategory = { Guid: prefill.Value.Guid, Description: prefill.Value.Label, Caption: prefill.Value.Label };
							$scope.model.GuidDocumentCategory = prefill.Value.Guid;
							break;
						case 'DocumentType':
							$scope.model.DocumentType = { Guid: prefill.Value.Guid, Caption: prefill.Value.Label };
							$scope.model.GuidDocumentType = prefill.Value.Guid;
							break;
						case 'Building':
						case 'Area':
						case 'Estate':
						case 'Equipment':
						case 'Component':
						case 'Request':
						case 'WorkOrder':
						case 'PeriodicTask':
						case 'Person':
						case 'CleaningTask':
						case 'Supplier':
                        case 'Cost':
                        case 'PaymentOrder':
							xrefProperty = prefill.Property;
							xrefGuid = prefill.Value.Guid;
							break;
					}
				} else if (prefill.Value === '@CurrentBuilding') {
					var building = repository.commonService.getFilterData().selectedBuilding;
					if (building.Guid) {
						xrefProperty = prefill.Property;
						xrefGuid = building.Guid;
					}
				} else if (prefill.Value === '@GuidCurrentProperty') {
					var estate = repository.commonService.getFilterData().selectedEstate;
					if (estate.Guid) {
						xrefProperty = prefill.Property;
						xrefGuid = estate.Guid;
					}
				} else {
					$scope.model[prefill.Property] = prefill.Value;
				}
			});
			getCategoryPrefill();
		}

		var getCheckbox = function () {
			var checkbox = 0;
			if ($scope.checkbox.isRegulatoryCompilanceDocumentation)
				checkbox = checkbox | 1 << 0;
			if ($scope.checkbox.isAttestationDocumentation)
				checkbox = checkbox | 1 << 1;
			if ($scope.checkbox.isDescriptionDocumentation)
				checkbox = checkbox | 1 << 2;
			if ($scope.checkbox.isOperationDocumentation)
				checkbox = checkbox | 1 << 3;
			if ($scope.checkbox.isMaintenanceDocumentation)
				checkbox = checkbox | 1 << 4;
			if ($scope.checkbox.isProductInfoDocumentation)
				checkbox = checkbox | 1 << 5;
			if ($scope.checkbox.isInstructionDocumentation)
				checkbox = checkbox | 1 << 6;
			if ($scope.checkbox.isProtocolDocumentation)
				checkbox = checkbox | 1 << 7;
			if ($scope.checkbox.isIdentificationDocumentation)
				checkbox = checkbox | 1 << 8;
			if ($scope.checkbox.isDrawingDocumentation)
				checkbox = checkbox | 1 << 9;
			if ($scope.checkbox.isImageDocumentation)
				checkbox = checkbox | 1 << 10;

			return checkbox;
		};

		$scope.dragOverClass = function ($event) {
			var items = $event.dataTransfer.items;
			var hasFile = false;
			if (items) {
				for (var i = 0; i < items.length; i++) {
					if (items[i].kind === 'file') {
						hasFile = true;
						break;
					}
				}
			} else {
				hasFile = true;
			}
			return hasFile ? "drop-box-dragover" : "";
		};

		repository.getSingle(repository.apiData.webClientSettings.url, "").then(function (result) {
			$scope.maxFileLength = result.MaxRequestLength;
		});

		var getFilesToUpload = function (allFiles) {
			var files = _.reject(allFiles, function (file) {
				// If retrying upload, remove the files already uploaded
				return file.progressType === 'success';
			});

			files.forEach(function (file) {
				// Generate unique id for files used for upload progress update
				file.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 16);
				file.count = 0;
				file.progressType = null;
			});
			return files;
		};


		$scope.filesizeToLarge = false;

		$scope.removeFile = function (index) {
			$scope.files.splice(index, 1);
		};
		var savedFiles = [];
		$scope.uploadDocument = function (destination) {
			if ($scope.isUploading) return;
			if ($scope.files.length < 1 && !$scope.model.IsExternalLink) {
				swal(translationService.translate('web-document-error-noFiles', 'Ingen filer valgt'), translationService.translate('web-document-error-nofilesdescription', 'Du må velge minst en eller flere filer for å laste opp'), 'info');
				return;
			}

			var filesToUpload = getFilesToUpload($scope.files);
			$scope.model.DocumentationContext = getCheckbox();

			if ($scope.model.IsExternalLink && $scope.model.FilePath.indexOf('http') !== 0) {
				$scope.model.FilePath = 'https://' + $scope.model.FilePath;
			}
			$scope.isUploading = true;
			$scope.model.prefix = repository.apiData.document.prefix;
			repository.createSingleWithBinary(repository.apiData.document.urlChunk, $scope.model, filesToUpload).then(function (result) {
				repository.commonService.setLastRegisterGuid(destination, result);
				repository.growl('Dokumentet har blitt lagret', 'info');
				$scope.alreadyUploaded = true;
				savedFiles = _.map(result.savedFiles, 'guid');
				if ($scope.model.IsExternalLink) {
					$modalInstance.close(savedFiles);
				}

				if (xrefGuid && xrefProperty && savedFiles.length > 0) {
					savedFiles.forEach(function (file) {
						var documentXEntity = {
							GuidDocument: file,
							Entity: -1
						};

						documentXEntity['Guid' + xrefProperty] = xrefGuid;
						repository.createMultiple(repository.apiData.documentXEntity.url, [JSON.stringify(documentXEntity)]).then(function (result) {
							$scope.isUploading = false;
						}, function (error) {
							repository.growl(error, 'danger');
							$scope.isUploading = false;
						});
					});
				} else {
					$scope.isUploading = false;
				}

			}, function (error) {
				repository.growl(error[0].error, 'danger');
				$scope.isUploading = false;
				error.forEach(function (error) {
					var identifiedFile = _.find($scope.files, function (f) { return f.id === error.id; });
					if (identifiedFile) {
						identifiedFile.progressType = 'danger';
					}
				});
			}, function (file) { //UPDATE
				var identifiedFile = _.find($scope.files, function (f) { return f.id === file.id; });
				if (identifiedFile) {
					identifiedFile.total = file.total;
					identifiedFile.count = file.count;
					identifiedFile.progressType = file.count >= file.total ? 'success' : 'info';
				}
			});
		};


		$scope.onDocumentCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidDocumentCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidDocumentCategory = category.Guid;
		};


		$scope.ok = function () {
			$modalInstance.close(savedFiles);
		};

		var getUploadedFiles = function () {
			return _.filter($scope.files,
				function (file) {
					return file.progressType === 'success';
				});
		};

		$scope.cancel = function () {
			//var files = getUploadedFiles();
			//if (files && files.length > 0) {
			// $scope.ok(files);
			//}
			$modalInstance.dismiss('canceled');
		};
	}
})();
