(function () {
	angular.module('Plania').controller('RequestController', ['$scope', 'Repository', '$stateParams', 'authService', '$modal', '$filter', 'TranslationService', 'EnumService', '$localStorage', 'Constants', controller]);

	function controller($scope, repository, $stateParams, authService, $modal, $filter, translationService, enumService, $localStorage, constants) {
		$scope.update = $scope.navigation.current.name === 'request.edit';
		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = false;
		$scope.isDocumentsCollapsed = true;
		$scope.reloadDocumentTable = false;
		$scope.reloadAlarmLogTable = false;
		$scope.isWorkOrderCollapsed = true;
		$scope.isDynamicPropertiesCollapsed = false;
        $scope.reload = false;
        $scope.entityCommentSearch = { searchString: '', focus: false };

		$scope.viewMode = $scope.update ? true : false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		$scope.counts = {
			documentTotalCount: 0
		};

		$scope.documents = [];
		$scope.alarmLogs = [];

		$scope.validCharacters = '^(?!.*<[a-zA-ZæøåÆØÅ]+).*';

		var updateAccess = function () {
			if ($scope.update) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;

				$scope.restrictEdit = !authService.hasEditAccess(repository.commonService.prefix.Request, checkOtherDo) || (!$scope.userInfo.isSystemAdministrator && $scope.model.IsTemplate);
				$scope.restrictDelete = !authService.hasDeleteAccess(repository.commonService.prefix.Request, checkOtherDo) || (!$scope.userInfo.isSystemAdministrator && $scope.model.IsTemplate);
			} else {
				$scope.restrictEdit = !authService.hasCreateAccess(repository.commonService.prefix.Request) || (!$scope.userInfo.isSystemAdministrator && $scope.model.IsTemplate);
			}
		};
		updateAccess();

		var requestColumns = [
			'Building.Id', 'Building.Description',
			'Building.Estate.Guid', 'Building.Estate.Id', 'Building.Estate.Description',
			'Equipment.Id', 'Equipment.Description',
			'Drawing.Id', 'Drawing.Description',
			'Area.Id', 'Area.Description',
			'Department.Id', 'Department.Description',
			'ResourceGroup.Id', 'ResourceGroup.Description',
			'Cause.Id', 'Priority.Description',
			'ActivityCategory.Description', 'ActivityCategory.UseInWorkOrder', 'Customer.Id', 'Customer.Description',
			'Person.FirstName', 'Person.LastName', 'Person1.FirstName', 'Person1.LastName',
			'Priority.HasDeadline'
		];

		var workOrderColumns = [
			'Building.Id', 'Building.Description',
			'Person.FirstName', 'Person.LastName',
		];

		var setProperties = function () {
			if ($scope.model.Building) $scope.building = $scope.getEntityCaption($scope.model.Building);
			if ($scope.model.Drawing) $scope.drawing = $scope.getEntityCaption($scope.model.Drawing);
			if ($scope.model.Equipment) $scope.equipment = $scope.getEntityCaption($scope.model.Equipment);
			if ($scope.model.Area) $scope.area = $scope.getEntityCaption($scope.model.Area);
			if ($scope.model.Department) $scope.department = $scope.getEntityCaption($scope.model.Department);
			if ($scope.model.ResourceGroup) $scope.resourceGroup = $scope.getEntityCaption($scope.model.ResourceGroup);
			if ($scope.model.Cause) $scope.cause = $scope.model.Cause.Id;
			if ($scope.model.Priority) $scope.priority = $scope.model.Priority.Description;
			if ($scope.model.Customer) $scope.customer = $scope.getEntityCaption($scope.model.Customer);

			if ($scope.model.Person) {
				var personFirstName = $scope.model.Person.FirstName ?
					$scope.model.Person.FirstName + " " : '';
				$scope.person = personFirstName + ($scope.model.Person.LastName || '');
			}

			if ($scope.model.Person1) {
				var responsiblePersonFirstName = $scope.model.Person1.FirstName ?
					$scope.model.Person1.FirstName + " " : '';
				$scope.responsiblePerson = responsiblePersonFirstName + ($scope.model.Person1.LastName || '');
			}
		};

		var getWorkOrder = function () {
			$scope.enableWebNavigation = repository.enableWebNavigation;
			repository.getSingle(repository.apiData.workOrder.url, $scope.model.GuidWorkOrder, JSON.stringify(workOrderColumns)).then(function (result) {
				$scope.model.WorkOrder = result.Data;
				setWorkOrderProperties();
			});
		};

		var setWorkOrderProperties = function () {
			if ($scope.model.WorkOrder.StartDate)
				$scope.model.WorkOrderStartDate = $filter('date')(new Date($scope.model.WorkOrder.StartDate), 'dd.mm.yyyy');
			if ($scope.model.WorkOrder.DueDate)
				$scope.model.WorkOrderDueDate = $filter('date')(new Date($scope.model.WorkOrder.DueDate), 'dd.mm.yyyy');
			if ($scope.model.WorkOrder.EndDate)
				$scope.model.WorkOrderEndDate = $filter('date')(new Date($scope.model.WorkOrder.EndDate), 'dd.mm.yyyy');
			if ($scope.model.WorkOrder.Person)
				$scope.model.WorkOrderResponsible = $scope.model.WorkOrder.Person.FirstName + ' ' + $scope.model.WorkOrder.Person.LastName;
		};

		var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);

		if ($scope.update) {
			repository.getMainModel(repository.apiData.request.url, $stateParams.guid, JSON.stringify(requestColumns)).then(
				function (response) {
					var responseData = response.Data;
					$scope.model = responseData;
					updateAccess();
					setProperties();

					if ($scope.model.GuidWorkOrder && $scope.hasReadAccess('WorkOrder'))
						getWorkOrder();

					$scope.reloadDocumentTable = true;
					$scope.reload = true;
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);

					if (responseData.Priority)
						$scope.restrictDueDate = responseData.Priority.HasDeadline ? true : false;

				},
				function (error) {
					repository.growl(error, 'danger');
				});
		} else {
			var getCategoryPrefill = function (guid) {
				var guidCategory = guid || $scope.model.GuidActivityCategory;
				if (!guidCategory) return;
				repository.getSingle(repository.apiData.activityCategory.url, guidCategory).then(function (result) {
					$scope.model.DynamicProperty = result.Data.DynamicProperty;
				}, function (error) {
					repository.growl(error, 'danger');
				});
			};

			var userData = repository.authService.getUserData();

			if (userData.guidDepartment) {
				$scope.model.GuidDepartment = userData.guidDepartment;
				$scope.model.Department = { Guid: userData.guidDepartment, Name: userData.userDepartment, Caption: userData.userDepartment };
				$scope.department = $scope.model.Department.Caption;
			}

			if (repository.commonService.getFilterData().selectedBuilding.Guid) {
				$scope.model.GuidBuilding = repository.commonService.getFilterData().selectedBuilding.Guid;
				$scope.building = repository.commonService.getFilterData().selectedBuilding.Name;
			}

			if ($stateParams.showPersistantData) {
				var persistentData = repository.persistedData.getPersistedData('request.create');

				if (persistentData) {
					$scope.model = persistentData.model || {};
					delete $scope.model.Guid;
					delete $scope.model.Status;
					delete $scope.model.Id;
					delete $scope.model.GuidRequest;

					$scope.model.GuidWorkOrder = persistentData.guidWorkOrder;
					$scope.building = persistentData.building;
					$scope.area = persistentData.area;
					$scope.equipment = persistentData.equipment;
					$scope.resourceGroup = persistentData.resourceGroup;
					$scope.responsiblePerson = persistentData.responsiblePerson;
					$scope.department = persistentData.department;
					$scope.activityCategory = persistentData.activityCategory;
					$scope.cause = persistentData.cause;
					$scope.priority = persistentData.priority;
				}
			}

			if (userData.guidPerson) {
				$scope.model.ContactPerson = userData.personFirstName + ' ' + userData.personLastName;
			} else {
				$scope.model.ContactPerson = userData.realName;
			}

			$scope.model.EmailAddress = userData.email;
			$scope.model.Telephone = userData.phone;
			$scope.model.RegisteredDate = new Date();
			$scope.model.Status = constants.requestStatus.new;

			$scope.activateAutocomplete = true;

			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object' && prefill.Value !== null) {
						switch (prefill.Property) {
							case 'Building':
								$scope.building = prefill.Value.Label;
								$scope.model.GuidBuilding = prefill.Value.Guid;
								break;
							case 'Equipment':
								$scope.equipment = prefill.Value.Label;
								$scope.model.GuidEquipment = prefill.Value.Guid;
								break;
							case 'Drawing':
								$scope.drawing = prefill.Value.Label;
								$scope.model.GuidDrawing = prefill.Value.Guid;
								break;
							case 'Area':
								$scope.area = prefill.Value.Label;
								$scope.model.GuidArea = prefill.Value.Guid;
								break;
							case 'ResourceGroup':
								$scope.resourceGroup = prefill.Value.Label;
								$scope.model.GuidResourceGroup = prefill.Value.Guid;
								break;
							case 'Priority':
								$scope.priority = prefill.Value.Label;
								$scope.model.GuidPriority = prefill.Value.Guid;
								break;
							case 'ActivityCategory':
								$scope.model.ActivityCategory = { Description: prefill.Value.Label, Guid: prefill.Value.Guid };
								$scope.model.GuidActivityCategory = prefill.Value.Guid;
								break;
							case 'Customer':
								$scope.customer = prefill.Value.Label;
								$scope.model.GuidCause = prefill.Value.Guid;
								break;
							case 'Cause':
								$scope.cause = prefill.Value.Label;
								$scope.model.GuidCause = prefill.Value.Guid;
								break;
							case 'Person1':
								$scope.responsiblePerson = prefill.Value.Label;
								$scope.model.GuidResponsiblePerson = prefill.Value.Guid;
								break;
							case 'Person':
								$scope.person = prefill.Value.Label;
								$scope.model.GuidPerson = prefill.Value.Guid;
								break;
							case 'Department':
								$scope.department = prefill.Value.Label;
								$scope.model.GuidDepartment = prefill.Value.Guid;
								break;
						}
					} else if (prefill.Value === '@CurrentBuilding') {
						var building = repository.commonService.getFilterData().selectedBuilding;
						if (building.Guid) {
							$scope.building = building.Name;
							$scope.model.GuidBuilding = building.Guid;
						}
					} else if (prefill.Value === '@LoggedInPerson') {
						$scope.responsiblePerson = repository.authService.getUserData().personFirstName + ' ' + repository.authService.getUserData().personLastName;
						$scope.model.GuidResponsiblePerson = repository.authService.getUserData().guidPerson;
					} else if (prefill.Value === '@UserCustomer') {
						$scope.model.GuidCustomer = repository.authService.getUserData().guidCustomer;
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}

			getCategoryPrefill();
		}

		$scope.createRequest = function (destination) {
			if ($scope.model.RegisteredDate)
				$scope.model.RegisteredDate = new Date($scope.model.RegisteredDate).toISOString();
			if ($scope.model.StartDate)
				$scope.model.StartDate = new Date($scope.model.StartDate).toISOString();
			if ($scope.model.DueDate)
				$scope.model.DueDate = new Date($scope.model.DueDate).toISOString();

			var success = function (result) {
				repository.growl($scope.update ? 'Meldingen ble lagret' : 'Meldingen ble opprettet', 'success');
				var guid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.update) {
				repository.updateSingleDictionary(repository.apiData.request.url, $scope.model).then(success).catch(error);
			} else {
				repository.createSingleDictionary(repository.apiData.request.url, $scope.model).then(success).catch(error);
			}
		};

		$scope.onActivityCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidActivityCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidActivityCategory = category.Guid;
		};

		$scope.calendars = {
			registeredDate: false,
			dueDate: false
		};

		$scope.open = function ($event, calendar) {
			$scope.calendars[calendar] = !$scope.calendars[calendar];
			$event.preventDefault();
			$event.stopPropagation();
		};

		$scope.sendEmail = function () {
			var attachments = $scope.documents.concat($scope.model.Images);

			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/emailModal.html',
				controller: 'EmailModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							entity: 'Request',
							columns: requestColumns,
							model: $scope.model,
							attachments: attachments,
							getAllDocuments: $scope.counts.documentTotalCount > 10
						};
					}
				}
			}).result.then(function () {
				$scope.reloadAlarmLogTable = true;
			});
		};

		$scope.onPrioritySelect = function (priority) {
			if (priority) {
				$scope.model.GuidPriority = priority.Guid;
				if (priority.HasDeadline) {
					$scope.model.DueDate = enumService.getTimeUnitDate(new Date(), priority.DeadlinePeriodUnit, priority.DeadlinePeriodNumber);
					$scope.restrictDueDate = true;
				} else {
					$scope.restrictDueDate = false;
				}
			} else {
				$scope.model.GuidPriority = null;
				$scope.restrictDueDate = false;
			}
		};

		$scope.removeRequest = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-request-message', "Meldingen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-request-button-confirm', 'Ja, fjern meldingen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.request.url, $scope.model.Guid)
					.then(function () {
						swal.close();
						$scope.goBack('request.list', { menuGuid: $scope.navigation.params.menuGuid });
						repository.growl(translationService.translate('web-request-growl-deleted', 'Meldingen har blitt fjernet'), 'success');
					});
			});
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}

			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidEquipment = "";
				$scope.equipment = "";
				$scope.model.GuidArea = "";
				$scope.area = "";
				$scope.model.GuidCustomer = "";
				$scope.customer = "";
			}

			$scope.model.GuidBuilding = building.Guid;
		};

		$scope.onEquipmentSelect = function (equipment) {

			if (!equipment) {
				$scope.model.GuidEquipment = "";
				return;
			}
			$scope.model.GuidEquipment = equipment.Guid;

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = equipment.GuidBuilding;
				$scope.building = equipment.Building.Caption;
			}

			if (equipment.GuidArea) {
				$scope.model.GuidArea = equipment.GuidArea;
				$scope.area = equipment.Area.Caption;
			}
		};

		$scope.onAreaSelect = function (area) {
			if (!area) {
				$scope.model.GuidArea = "";
				$scope.area = '';
				return;
			}

			$scope.model.GuidArea = area.Guid;

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = area.GuidBuilding;
				$scope.building = area.Building.Id + " - " + area.Building.Description;
			}
		};

		$scope.autoCompleteFilter = function (filterName) {
			
			switch (filterName) {
				case "equipment":
					return { GuidBuilding: $scope.model.GuidBuilding, IsMaintainable: 1, PropertyFilter: [{ Property: 'IsTemplate', Operator: '=', Value: false }] };
				case "area":
					return { GuidBuilding: $scope.model.GuidBuilding, GuidDrawing: $scope.model.GuidDrawing };
				case "drawing":
					return { GuidBuilding: $scope.model.GuidBuilding };
				case "person":
					return {};
				case "responsiblePerson":
					return { GuidBuildingResponsible: $scope.model.GuidBuilding };
				case "department":
					return {};
				case "priority":
					return { PropertyFilter: [{ Property: 'EntityType', Operator: '<>', Value: 'Condition' }] };
				case "cause":
					return {};
				case "resourceGroup":
					return { PropertyFilter: [{ Property: 'DoesMaintenanceTasks', Operator: '=', Value: 'true' }] };
				case "activityCategory":
					return { PropertyFilter: [{ Property: 'UseInRequest', Operator: '=', Value: 'true' }, { Property: 'IsAbstract', Operator: '=', Value: '0' }] };
					
					
			}
		};

		$scope.accessToCreateWorkOrder = repository.authService.hasCreateAccess(repository.commonService.prefix.WorkOrder);
		$scope.accessToReadWorkOrder = repository.authService.hasReadAccess(repository.commonService.prefix.WorkOrder);

		$scope.createWorkOrder = function () {
			var reference = $scope.model.ContactPerson;
			if ($scope.model.Telephone) reference = reference + ' - ' + $scope.model.Telephone;

			var defaultText = $scope.model.ExplanatoryText;
			if ($scope.model.Location) defaultText = $scope.model.Location + ' - ' + defaultText;

			var workorder = {
				model: angular.copy($scope.model),
				guidEstate: $scope.model.Building.Estate ? $scope.model.Building.Estate.Guid : null,
				estate: $scope.model.Building.Estate ? $scope.getEntityCaption($scope.model.Building.Estate) : null,
				guidBuilding: $scope.model.GuidBuilding,
				building: $scope.building,
				guidArea: $scope.model.GuidArea,
				area: $scope.area,
				guidEquipment: $scope.model.GuidEquipment,
				equipment: $scope.equipment,
				guidRequest: $scope.model.Guid,
				resourceGroup: $scope.resourceGroup,
				responsiblePerson: $scope.responsiblePerson,
				department: $scope.department,
				cause: $scope.model.Cause ? $scope.model.Cause.Id : null,
				priority: $scope.priority,
				defaultDescription: $scope.model.Description,
				defaultText: defaultText,
				defaultReference: reference,

			};

			if ($scope.model.ActivityCategory && $scope.model.ActivityCategory.UseInWorkOrder === true) {

				for (var countCombo = 1; countCombo <= 20; countCombo++) {
					var fieldCombo = 'Combo' + String(countCombo).padStart(2, '0');
					if ($scope.model[fieldCombo]) workorder[fieldCombo] = $scope.model[fieldCombo];
				}
				for (var countDate = 1; countDate <= 30; countDate++) {
					var fieldDate = 'Date' + String(countDate).padStart(2, '0');
					if ($scope.model[fieldDate]) workorder[fieldDate] = $scope.model[fieldDate];
				}

				for (var countText = 1; countText <= 20; countText++) {
					var fieldText = 'Text' + String(countText).padStart(2, '0');
					if ($scope.model[fieldText]) workorder[fieldText] = $scope.model[fieldText];
				}
				for (var countNumber = 1; countNumber <= 10; countNumber++) {
					var fieldNumber = 'Number' + String(countNumber).padStart(2, '0');
					if ($scope.model[fieldNumber]) workorder[fieldNumber] = $scope.model[fieldNumber];
				}
			}
			else {
				workorder.model.GuidActivityCategory = "";
			}

			repository.persistedData.setPersistedData('workOrder.create', workorder);
			$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
		};

		$scope.addExistingWorkOrder = function () {
			$modal.open({
				templateUrl: 'app/common/views/singleSelectModal.html',
				controller: 'SingleSelectModalController',
				size: 'lg',
				resolve: {
					modalParams: function () {
						var propertyFilter = [{ Property: 'GuidRequest', Operator: '=', Value: '' },
						{ Property: 'stage', Operator: '<>', Value: '2' }];
						if ($scope.model.GuidBuilding)
							propertyFilter.push({ Property: 'GuidBuilding', Operator: '=', Value: $scope.model.GuidBuilding, allowToggle: true, filterValue: $scope.building, isDisabled: false });
						if ($scope.model.GuidEquipment)
							propertyFilter.push({ Property: 'GuidEquipment', Operator: '=', Value: $scope.model.GuidEquipment, allowToggle: true, filterValue: $scope.equipment, isDisabled: false });
						if ($scope.model.GuidArea)
							propertyFilter.push({ Property: 'GuidArea', Operator: '=', Value: $scope.model.GuidArea, allowToggle: true, filterValue: $scope.area, isDisabled: false });
						return {
							title: translationService.translate('web-request-addExistingWorkOrder', 'Legg til eksisterende arbeidsordre'),
							url: repository.apiData.workOrder.url,
							sorting: {},
							filter: {
								PropertyFilter: propertyFilter
							},
							columns: [
								{ title: translationService.translate('web-workorder-id'), property: 'Id' },
								{ title: translationService.translate('web-workorder-type'), property: 'Type' },
								{ title: translationService.translate('web-building-id'), property: 'Building.Id' },
								{
									title: translationService.translate('web-building-description'),
									property: 'Building.Description'
								},
								{
									title: translationService.translate('web-equipment-description'),
									property: 'Equipment.Description'
								},
								{
									title: translationService.translate('web-workorder-description'),
									property: 'Description'
								},
								{
									title: translationService.translate('web-workorder-startdate'),
									property: 'StartDate',
									PropertyType: 'date'
								},
								{
									title: translationService.translate('web-workorder-duedate'),
									property: 'DueDate',
									PropertyType: 'date'
								},
								{
									title: translationService.translate('web-workorder-enddate'),
									property: 'EndDate',
									PropertyType: 'date'
								},
							]
						};
					}
				}
			})
				.result.then(function (item) {
					$scope.model.GuidWorkOrder = item.Guid;
					$scope.createRequest('request.edit');
				},
					function (msg) {
					});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
